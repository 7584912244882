import Home from 'screens/Home';
import IncomingSignalListScreen from 'screens/IncomingSignal/IncomingSignalListScreen';
import LandingPage from 'screens/public/LandingPage';
import SignalCreateScreen from 'screens/Signal/SignalCreateScreen';
import SignalEditScreen from 'screens/Signal/SignalEditScreen';
import SignalListScreen from 'screens/Signal/SignalListScreen';
import SignalViewScreen from 'screens/Signal/SignalViewScreen';
import SignalGroupCreateScreen from 'screens/SignalGroup/SignalGroupCreateScreen';
import SignalGroupEditScreen from 'screens/SignalGroup/SignalGroupEditScreen';
import SignalGroupInstanceViewScreen from 'screens/SignalGroup/SignalGroupInstanceViewScreen';
import SignalGroupListScreen from 'screens/SignalGroup/SignalGroupListScreen';
import SignalGroupViewScreen from 'screens/SignalGroup/SignalGroupViewScreen';
import SignIn from 'screens/SignIn';
import SignUp from 'screens/SignUp';

export const publicRoutes = [
  { path: '/', component: LandingPage },
  { path: '/signIn', component: SignIn },
  { path: '/signUp', component: SignUp },
];

export const privateRoutes = [
  { path: '/dashboard', component: Home },
  { path: '/signals', component: SignalListScreen },
  { path: '/signals/create', component: SignalCreateScreen },
  { path: '/signals/:id', component: SignalViewScreen },
  { path: '/signals/:id/edit', component: SignalEditScreen, title: 'SH Edit' },
  { path: '/signalGroups', component: SignalGroupListScreen },
  { path: '/signalGroups/create', component: SignalGroupCreateScreen },
  { path: '/signalGroups/:id', component: SignalGroupViewScreen },
  { path: '/signalGroups/:id/edit', component: SignalGroupEditScreen },
  {
    path: '/signalGroups/:id/instances/:instanceId',
    component: SignalGroupInstanceViewScreen,
  },
  { path: '/incomingSignals', component: IncomingSignalListScreen },
];
