const {
  NODE_ENV,
  REACT_APP_ENV,
  REACT_APP_GRAPHQL_SERVER_URL,
  REACT_APP_GRAPHQL_SERVER_URL_WS,
  REACT_APP_WEBHOOK_URL,
} = process.env;

if (!REACT_APP_GRAPHQL_SERVER_URL) {
  throw new Error('REACT_APP_GRAPHQL_SERVER_URL missing');
}

if (!REACT_APP_GRAPHQL_SERVER_URL_WS) {
  throw new Error('REACT_APP_GRAPHQL_SERVER_URL_WS missing');
}

if (!REACT_APP_WEBHOOK_URL) {
  throw new Error('REACT_APP_WEBHOOK_URL missing');
}

const IS_NODE_PRODUCTION = NODE_ENV === 'production';

export const ENV = REACT_APP_ENV;
export const IS_PRODUCTION = IS_NODE_PRODUCTION && ENV === 'production';
export const IS_STAGING = IS_NODE_PRODUCTION && ENV === 'staging';
export const IS_TESTING = IS_NODE_PRODUCTION && ENV === 'testing';
export const IS_LOCAL = ENV === 'local';

export const GRAPHQL_SERVER_URL = REACT_APP_GRAPHQL_SERVER_URL;
export const GRAPHQL_SERVER_URL_WS = REACT_APP_GRAPHQL_SERVER_URL_WS;
export const WEBHOOK_URL = REACT_APP_WEBHOOK_URL;
