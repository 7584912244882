import React, { FunctionComponent } from 'react';
import CircleLoading from 'components/CircleLoading';
import { Grid } from '@material-ui/core';

const ModalLoading: FunctionComponent = () => (
  <Grid
    container
    justify="center"
    alignItems="center"
    alignContent="center"
    style={{ paddingBottom: 25 }}
  >
    <CircleLoading />
  </Grid>
);

export default ModalLoading;
