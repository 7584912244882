import { FieldMetaState } from 'react-final-form';

export interface ShowErrorProps {
  meta: FieldMetaState<any>;
}

export const showError = ({
  meta: { submitError, dirtySinceLastSubmit, error, touched, modified },
}: ShowErrorProps) =>
  !!(
    ((submitError && !dirtySinceLastSubmit) || error) &&
    (touched || modified)
  );
