import { makeStyles } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { ElementType, FunctionComponent, ReactNode } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: 'rgba(255,255,255,.7)',
    fontSize: '18px',
    minWidth: '48px',
  },
  listButton: {
    // backgroundColor: theme.palette.background.paper,
    backgroundClip: 'border-box',
    borderRadius: '0px',
    padding: theme.spacing(0.3, 1),
    margin: theme.spacing(0.5, 0),
    '&.Mui-selected': {
      color: 'rgba(255,255,255,0.35)',
      // backgroundColor: 'rgba(255,255,255,0.35)',
    },
  },
  listButtonText: {
    color: 'rgba(255,255,255,0.6)',
    fontSize: '14px',
    fontWeight: 500,
  },
  listButtonTextSelected: {
    color: 'rgba(255,255,255,1.0)',
  },
  badgeIcon: {
    justifyContent: 'flex-end',
    paddingRight: '12px',
  },
}));

interface ListLinkProps {
  to: string;
  icon?: ElementType;
  text: string;
  exact?: boolean;
  badge?: string | number;
}

export const ListLink = ({
  to,
  icon: IconComponent,
  text,
  exact = false,
  badge,
  ...props
}: ListLinkProps & LinkProps) => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const selected = exact ? pathname === to : pathname.includes(to);
  const textClass = clsx([
    classes.listButtonText,
    selected && classes.listButtonTextSelected,
  ]);
  return (
    <ListItem
      button
      component={Link}
      selected={selected}
      to={to}
      {...props}
      className={classes.listButton}
    >
      <ListItemIcon>
        {IconComponent && <IconComponent className={classes.icon} />}
      </ListItemIcon>
      <ListItemText primary={text} className={textClass} />
      {badge !== undefined && (
        <ListItemIcon className={classes.badgeIcon}>
          <Badge badgeContent={badge} color="error" />
        </ListItemIcon>
      )}
    </ListItem>
  );
};

interface CollapsedListProps {
  text: string;
  icon: React.ElementType;
  children: ReactNode;
}

export const CollapsedList: React.FunctionComponent<CollapsedListProps> = ({
  text,
  icon: IconComponent,
  children,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick} className={classes.listButton}>
        <ListItemIcon>
          <IconComponent className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          primary={text}
          classes={{
            primary: classes.listButtonText,
          }}
        />
        {open ? (
          <ExpandLess className={classes.icon} />
        ) : (
          <ExpandMore className={classes.icon} />
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};

export const MainListItems: FunctionComponent = () => (
  <div>
    <ListLink text="Dashboard" to="/dashboard" icon={DashboardIcon} exact />
    <ListLink text="Signals" to="/signals" icon={ShowChartIcon} />
    <ListLink text="Signal Groups" to="/signalGroups" icon={GroupWorkIcon} />
    <ListLink
      text="Incoming Signals"
      to="/incomingSignals"
      icon={BubbleChartIcon}
    />
  </div>
);
