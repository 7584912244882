import { Drawer, List, makeStyles } from '@material-ui/core';
import BrandLogoDark from 'assets/brand-icon-nameWhite-transparent.png';
import React from 'react';
import { MainListItems } from './drawerItems';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1.2rem',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: '#101b21',
    boxShadow: '1px 0 #0f1314',
    border: '0',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const AppDrawer = () => {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <img src={BrandLogoDark} alt="SignalHooks" style={{ width: '180px' }} />
      </div>
      <List>
        <MainListItems />
      </List>
    </Drawer>
  );
};

export default AppDrawer;
