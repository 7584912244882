import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Button from 'components/Button';
import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import { useLoginByUsernameAndPassword } from 'hooks/auth';
import { makeValidate, TextField } from 'mui-rff';
import React, { FunctionComponent } from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: '#45455c',
    flexGrow: 1,
    minHeight: '100vh',
  },
  container: {
    minHeight: '100vh',
  },
  textBox: {
    marginBottom: theme.spacing(1),
  },
  buttonSignIn: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#FF307C',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
  },
  signup: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 2),
    textTransform: 'capitalize',
  },
}));

interface Values {
  username: string;
  password: string;
}

const schema = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

const validate = makeValidate(schema);

export const formFields: any[] = [
  <TextField
    required
    variant="outlined"
    label="Username"
    id="email"
    type="text"
    name="username"
    autoComplete="username"
  />,
  <TextField
    required
    variant="outlined"
    label="Password"
    id="password"
    type="password"
    name="password"
    autoComplete="current-password"
  />,
];

const SignIn: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loginByUsernameAndPassword } = useLoginByUsernameAndPassword();

  const onSubmit = async ({ username, password }: Values) => {
    const snackId = startSnackbar('Logging in');
    try {
      const { error } = await loginByUsernameAndPassword(username, password);
      if (error) {
        stopSnackbar(`Log in failed`, snackId);
        return { password: 'Invalid login' };
      }
      history.push('/dashboard');
      stopSnackbar(`Log in successful`, snackId);
    } catch (e) {
      stopSnackbar(`Log in failed`, snackId);
    }
    return {};
  };

  const goSignUp = () => history.push('/signUp');

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            className={classes.form}
            render={(props) => (
              <form className={classes.form}>
                <Grid container spacing={2}>
                  {formFields.map((item, idx) => (
                    <Grid item xs={12} key={idx}>
                      {item}
                    </Grid>
                  ))}
                </Grid>
                <Button
                  text="Sign In"
                  type="submit"
                  fullWidth
                  className={classes.submit}
                  onClick={props.handleSubmit}
                  disabled={
                    props.submitting ||
                    props.pristine ||
                    props.hasValidationErrors ||
                    (props.hasSubmitErrors && !props.modifiedSinceLastSubmit)
                  }
                />
                <Button
                  variant="text"
                  text="Don't have an account? Sign up"
                  fullWidth
                  className={classes.signup}
                  onClick={goSignUp}
                />
              </form>
            )}
          />
        </div>
      </Container>
    </div>
  );
};

export default SignIn;
