import { ApolloError } from '@apollo/client';
import { Box } from '@material-ui/core';
import Skeleton, { SkeletonProps } from '@material-ui/lab/Skeleton';
import React, { FunctionComponent } from 'react';
import Footer, { Props as FooterProps } from './Footer';
import Header, { Props as HeaderProps } from './Header';
import { useStyles } from './styles';

type Props = {
  loadingProps: { loading: boolean } & SkeletonProps;
  error?: ApolloError;
  header: HeaderProps;
  footer?: FooterProps;
};
const ViewScreen: FunctionComponent<Props> = ({
  loadingProps,
  error,
  children,
  header,
  footer,
}) => {
  const classes = useStyles();
  const { loading, ...restLoadingProps } = loadingProps;
  return (
    <Box className={classes.root}>
      <Header {...header} loading={loading} />
      {loading && <Skeleton {...restLoadingProps} />}
      {!loading && error && <p>Error</p>}
      {!loading && !error ? children : null}
      <Footer {...footer} loading={loading} />
    </Box>
  );
};

export default ViewScreen;
