import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useInitialAuth } from 'hooks/auth';
import { privateRoutes, publicRoutes } from './routes';
import PrivateRoute from './PrivateRoute';

export default () => {
  useInitialAuth();
  return (
    <Switch>
      {publicRoutes.map(({ path, component: Component }) => (
        <Route key={path} exact path={path}>
          <Component />
        </Route>
      ))}
      {privateRoutes.map(({ path, component: Component }) => (
        <PrivateRoute key={path} exact path={path}>
          <Component />
        </PrivateRoute>
      ))}
      {/* Catch all or 404 */}
      <Route path="*">
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      </Route>
    </Switch>
  );
};
