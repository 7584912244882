import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import LightTooltip from 'components/LightTooltip';
import React, { FunctionComponent, ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  listContainer: {
    padding: theme.spacing(0.5, 0), // '4px 0px'
  },
  listLabel: {
    color: '#757575',
    minWidth: 180,
    flexWrap: 'wrap',
  },
  listItem: {
    color: '#212121',
    flexWrap: 'wrap',
  },
  listDescription: ({ stacked }: { stacked?: boolean } = {}) => ({
    maxWidth: stacked ? undefined : 240,
  }),
  tooltip: {
    fontSize: '1rem',
  },
}));

export const Description: FunctionComponent<
  Pick<Props, 'description' | 'tooltip' | 'stacked'>
> = ({ description, tooltip, stacked }) => {
  const classes = useStyles({ stacked });

  if (tooltip) {
    return (
      <LightTooltip title={tooltip} arrow>
        <Typography
          variant="body2"
          component="div"
          className={classes.listLabel}
        >
          {description}{' '}
          <InfoIcon
            className={classes.tooltip}
            style={{ verticalAlign: 'text-bottom' }}
          />
        </Typography>
      </LightTooltip>
    );
  }
  return (
    <Typography variant="body2" component="div">
      <Box className={classes.listLabel}>{description}</Box>
    </Typography>
  );
};

type Props = {
  description?: string;
  descriptionComponent?: ReactElement;
  value: any;
  stacked?: boolean;
  tooltip?: string;
};

const LineItems: FunctionComponent<Props> = ({
  description,
  descriptionComponent,
  value,
  tooltip,
  stacked = false,
}) => {
  const classes = useStyles({ stacked });

  return (
    <Grid container className={classes.listContainer}>
      <Grid
        item
        xs={12}
        sm={stacked ? undefined : 6}
        className={classes.listDescription}
      >
        {descriptionComponent && descriptionComponent}
        {description && (
          <Description
            description={description}
            stacked={stacked}
            tooltip={tooltip}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={stacked ? undefined : 6}>
        <Typography variant="body2" component="div">
          <Box className={classes.listItem}>{value}</Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LineItems;
