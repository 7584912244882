import AppInitializing from 'components/App/AppInitializing';
import AppWrapper from 'components/App/AppWrapper';
import { useAuth } from 'hooks/auth';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isAuthorized, isInitialized } = useAuth();
  return (
    <Route
      {...rest}
      render={() => {
        if (!isInitialized) {
          return <AppInitializing />;
        }
        if (isAuthorized && isInitialized) {
          return <AppWrapper>{children}</AppWrapper>;
        }

        return (
          <Redirect
            to={{
              pathname: '/signIn',
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
