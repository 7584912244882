import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line
export const useStyles = makeStyles((theme) => ({
  root: {},
  titleContainer: {
    paddingRight: theme.spacing(1),
  },
  title: {
    fontWeight: 500,
  },
  headerContainer: {
    // paddingBottom: theme.spacing(3),
  },
  topHeaderContainer: {
    padding: '1rem 1rem 0',
    marginBottom: theme.spacing(1),
  },
  middleHeaderContainer: {
    marginBottom: theme.spacing(1),
  },
  bottomHeaderContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  headerBottomDividerContainer: {},
  leftButton: {
    marginRight: theme.spacing(1),
  },
  rightButton: {
    marginLeft: theme.spacing(1),
  },
  headerBottomSubtitleContainer: ({
    hasButtons,
  }: { hasButtons?: boolean } = {}) => ({
    paddingBottom: hasButtons ? theme.spacing(1) : 0,
  }),
}));
