import { Chip, makeStyles } from '@material-ui/core';
import Icon from '@material-ui/icons/HourglassEmpty';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  status: {
    backgroundColor: '#ECEFF1',
    border: '1px solid rgba(69,90,100,0.5)',
    borderRadius: '4px',
    color: '#455A64',
    fontWeight: 500,
  },
  icon: {
    color: '#455A64',
  },
}));

type Props = {
  text?: string;
  icon?: boolean;
  renderNullIfEmpty?: boolean;
};
const NeutralChip: FunctionComponent<Props> = ({
  text,
  renderNullIfEmpty = true,
  icon = true,
}) => {
  const classes = useStyles();
  return !text && renderNullIfEmpty ? null : (
    <Chip
      size="small"
      label={text}
      className={classes.status}
      icon={icon ? <Icon className={classes.icon} /> : undefined}
    />
  );
};

export default NeutralChip;
