import { CooldownAfterTriggerInterval } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';

type Props = {
  stopAfterTrigger?: boolean;
  cooldownAmount?: number;
  cooldownInterval?: CooldownAfterTriggerInterval;
};
const PostTriggerOption: FunctionComponent<Props> = ({
  stopAfterTrigger,
  cooldownAmount,
  cooldownInterval,
}) => {
  let text = '-';
  if (cooldownAmount && cooldownInterval) {
    text = `Start cooldown timer for ${cooldownAmount} ${cooldownInterval.toLowerCase()}s`;
  }
  if (stopAfterTrigger) {
    text = 'Stop signal group';
  }
  return <div>{text}</div>;
};

export default PostTriggerOption;
