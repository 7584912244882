import { makeStyles } from '@material-ui/core';
import StyledTableSortLabel from 'components/ListScreen/StyledTableSortLabel';
import TableEmptyComponent from 'components/TableEmptyComponent';
import Table, { Props as TableProps } from 'material-ui-apollo-table';
import React from 'react';

const useStlyes = makeStyles((theme) => ({
  bodyCell: {
    fontSize: '0.8rem',
  },
  headerCell: {
    color: 'rgba(0,0,0,0.87)',
    backgroundColor: 'rgba(0,0,0,0.06)',
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'uppercase',
    fontSize: 12,
  },
}));

export default ({ columns, ...tableProps }: TableProps) => {
  const classes = useStlyes();
  const adjustedColumns = columns.map((column) => ({
    ...column,
    getColumnClasses: () => [classes.bodyCell],
    disableFilterIcons: true,
  }));
  return (
    <Table
      paperProps={{ variant: 'elevation', elevation: 0 }}
      tableProps={{ size: 'small' }}
      displayEmptyRows={false}
      getCellClasses={() => [classes.headerCell]}
      disableHeaderLoader
      tableSortLabelComponent={StyledTableSortLabel}
      renderEmptyComponent={() => (
        <TableEmptyComponent colSpan={adjustedColumns.length} />
      )}
      columns={adjustedColumns}
      rowsPerPage={50}
      rowsPerPageOptions={[
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: '75', value: 75 },
        { label: '100', value: 100 },
        { label: '300', value: 300 },
      ]}
      {...tableProps}
    />
  );
};
