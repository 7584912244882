import React from 'react';
import {
  Box,
  Typography,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  empty: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
}));

type Props = {
  colSpan: number;
  text?: string;
};
export default ({ colSpan, text = 'No data' }: Props) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.empty} align="center" colSpan={colSpan}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
        >
          <Typography variant="body2">{text}</Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};
