import {
  Box,
  Button as MUIButton,
  ButtonProps,
  makeStyles,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import CircleLoading from './CircleLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#ffffff',
    textTransform: 'capitalize',
    // padding: theme.spacing(0.4, 1.2),
    fontWeight: 'normal',
    // boxShadow:
    //   'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 1px 3px 0px',
    // border: '0 none transparent',
    // '&:hover': {
    //   boxShadow:
    //     'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 1px 3px 0px',
    // },
  },
  wrapper: {
    position: 'relative',
  },
  loading: {
    marginLeft: theme.spacing(1),
  },
}));

export type Props = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
} & ButtonProps;
const Button: FunctionComponent<Props> = ({
  text,
  onClick,
  disabled = false,
  loading = false,
  startIcon,
  endIcon,
  size,
  ...buttonProps
}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <MUIButton
        variant="outlined"
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
        disabled={disabled || loading}
        disableElevation
        className={classes.root}
        {...buttonProps}
      >
        {text}
        {loading && (
          <Box className={classes.loading}>
            <CircleLoading size={16} />
          </Box>
        )}
      </MUIButton>
    </div>
  );
};

export default Button;
