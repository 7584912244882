import React, { FunctionComponent } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

type Props = {
  field: string;
  becomes: (value: any) => boolean;
  set: string;
  to: any;
};
const WhenFieldChanges: FunctionComponent<Props> = ({
  field,
  becomes,
  set,
  to,
}) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange } },
    ) => (
      <FormSpy subscription={{}}>
        {() => (
          <OnChange name={field}>
            {(value: any) => {
              if (becomes(value)) {
                onChange(to);
              }
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
);

export default WhenFieldChanges;
