import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BrandSwitch from 'components/BrandSwitch';
import { useStartSignal, useStopSignal } from 'hooks/signal';
import React, { FunctionComponent, useCallback, useState } from 'react';

const useStyles = makeStyles(() => ({
  dialog: {
    width: '100%',
  },
  dialogSwitchContent: {
    background: 'rgb(247, 250, 252)',
    borderRadius: '4px',
    padding: '1rem 1.2rem 0.8rem',
    marginTop: '1rem',
  },
}));

type Props = {
  signalId: string;
  visible: boolean;
  isActive?: boolean;
  handleClose: () => void;
};
const SignalStartStopModal: FunctionComponent<Props> = ({
  signalId,
  visible,
  handleClose,
  isActive,
}) => {
  const [toggled, setToggled] = useState(false);
  const { startSignal } = useStartSignal();
  const { stopSignal } = useStopSignal();
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToggled(event.target.checked);
  };

  const handleOk = useCallback(() => {
    handleClose();
    if (isActive) {
      stopSignal({ id: signalId, stopRelatedSignals: toggled });
    } else {
      startSignal({ id: signalId, startRelatedSignals: toggled });
    }
  }, [isActive, signalId, toggled]);

  return (
    <Dialog
      open={visible}
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <DialogTitle id="form-dialog-title">
        {isActive ? 'Stop Signal' : 'Start Signal'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isActive ? 'Stop' : 'Start'}
          &nbsp;listening to incoming webhook triggers.
        </DialogContentText>
        <Box display="flex" className={classes.dialogSwitchContent}>
          <DialogContentText>
            {isActive ? 'Pause' : 'Start'}
            &nbsp;listening for signal triggers in all associated signal groups.
            This may affect how quickly signal groups trigger.
          </DialogContentText>
          <BrandSwitch checked={toggled} onChange={handleChange} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleOk} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignalStartStopModal;
