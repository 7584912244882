import { Chip, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  status: {
    backgroundColor: '#fffbeb',
    border: '1px solid rgba(217,119,6,0.5)',
    borderRadius: '4px',
    color: '#d97706',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
}));

type Props = {
  text: string;
  renderNullIfEmpty?: boolean;
};
const WarningChip: FunctionComponent<Props> = ({
  text,
  renderNullIfEmpty = true,
}) => {
  const classes = useStyles();
  return !text && renderNullIfEmpty ? null : (
    <Chip size="small" label={text} className={classes.status} />
  );
};

export default WarningChip;
