import DateUtil from '@date-io/moment';

const dateFn = new DateUtil();

export const formatDateTime = (
  datetime: string | null | undefined,
  format = 'ddd, MMM DD, YYYY [at] h:mm:ss A',
) => (datetime ? dateFn.moment(datetime).format(format) : '');

export const formatDate = (
  datetime: string | null | undefined,
  format = 'MMM DD, YYYY',
) => (datetime ? dateFn.moment(datetime).format(format) : '');

export const formatTime = (
  datetime: string | null | undefined,
  format = 'h:mm:ss A',
) => (datetime ? dateFn.moment(datetime).format(format) : '');

export const displayElapsedTime = (seconds: number | null | undefined) => {
  if (!seconds) {
    return '';
  }
  return dateFn.moment.duration(seconds, 'seconds').humanize();
};
