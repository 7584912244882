import { Box, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    // boxShadow: 'rgb(217 220 221 / 32%) 0px 8px 16px 6px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    // padding: theme.spacing(2, 1.6),
    marginBottom: theme.spacing(3),
  },
  disablePadding: {
    padding: 0,
  },
  disableMargin: {
    margin: 0,
  },
}));

type Props = {
  children: ReactNode;
  disablePadding?: boolean;
  disableMargin?: boolean;
};
export default ({ children, disablePadding, disableMargin }: Props) => {
  const styles = useStyles();
  const classes = clsx([
    styles.root,
    disablePadding && styles.disablePadding,
    disableMargin && styles.disableMargin,
  ]);
  return <Box className={classes}>{children}</Box>;
};
