import MenuItem from '@material-ui/core/MenuItem';
import { Select as MUISelectField, SelectProps } from 'mui-rff';
import React, { FunctionComponent } from 'react';

interface Item {
  value: any;
  label: any;
}

type Props = {
  items: Item[];
} & SelectProps;

const SelectField: FunctionComponent<Props> = ({ items, ...props }) => (
  <MUISelectField {...props}>
    {items.map(({ value, label }, i) => (
      <MenuItem key={i} value={value}>
        {label}
      </MenuItem>
    ))}
  </MUISelectField>
);

export default SelectField;
