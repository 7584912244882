import { Chip, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  status: {
    backgroundColor: '#ECEFF1',
    border: '1px solid rgba(69,90,100,0.5)',
    borderRadius: '4px',
    color: '#455A64',
    fontWeight: 500,
  },
  icon: {
    color: '#455A64',
  },
}));

type Props = {
  label?: string;
  icon?: boolean;
};
const ToggleChip: FunctionComponent<Props> = ({ label = 'trigger toggle' }) => {
  const classes = useStyles();

  return (
    <Chip
      size="small"
      label={label}
      className={classes.status}
      // icon={icon ? <Icon className={classes.icon} /> : undefined}
    />
  );
};

export default ToggleChip;
