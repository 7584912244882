import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import Button from 'components/Button';
import ListScreen from 'components/ListScreen';
import SignalActionPill from 'components/SignalActionPill';
import SignalStatusPill from 'components/SignalStatusPill';
import {
  AllSignalsDocument,
  AllSignalsEntryFragment,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDateTime } from 'utils/time';

type Row = {
  row: AllSignalsEntryFragment;
};

const TableActions = ({ createOnClick }: { createOnClick: () => void }) => (
  <Button text="New Signal" startIcon={<AddIcon />} onClick={createOnClick} />
);

const SignalListScreen: FunctionComponent = () => {
  const history = useHistory();

  return (
    <ListScreen
      title="Signals"
      query={AllSignalsDocument}
      headerExtra={
        <TableActions createOnClick={() => history.push('/signals/create')} />
      }
      onRowClick={(row: Row['row']) => history.push(`/signals/${row.id}`)}
      columns={[
        {
          label: 'Name',
          name: 'name',
          disableFilter: true,
        },
        {
          label: 'Groups',
          width: 100,
          type: 'integer',
          name:
            'signalGroupsBySignalGroupItemSignalIdAndSignalGroupId.totalCount',
          disableFilter: true,
        },
        {
          label: 'Status',
          width: 114,
          type: 'boolean',
          name: 'isActive',
          disableFilter: true,
          render: ({ value }: any) => <SignalStatusPill status={value} />,
        },
        {
          label: 'Last Alert',
          width: 250,
          name: 'lastHistory.createdAt',
          disableFilter: true,
          render: ({ value }: any) => (
            <Box display="flex" alignItems="center">
              <Box style={{ marginRight: '0.5rem' }}>
                {formatDateTime(value)}
              </Box>
            </Box>
          ),
        },
        {
          label: 'Alert State',
          width: 150,
          name: 'lastHistory.createdAt',
          disableFilter: true,
          render: ({ row }: any) => (
            <Box display="flex" alignItems="center">
              <Box>
                <SignalActionPill action={row?.lastHistory?.action} />
              </Box>
            </Box>
          ),
        },
        {
          label: 'Created',
          width: 250,
          name: 'createdAt',
          disableFilter: true,
          render: ({ value }: any) => formatDateTime(value),
        },
      ]}
    />
  );
};

export default SignalListScreen;
