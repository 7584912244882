import {
  Box,
  Breadcrumbs,
  CardContent,
  Grid,
  Paper,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Card from 'components/Card';
import LineItems from 'components/LineItems';
import Link from 'components/Link';
import ViewScreen from 'components/ViewScreen';
import { useSignalGroupInstanceByIdQuery } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { displayElapsedTime, formatDateTime } from 'utils/time';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: '1.4rem',
  },
}));

const SignalGroupInstanceViewScreen: FunctionComponent = () => {
  const classes = useStyles();
  const { id, instanceId } = useParams<{ id: string; instanceId: string }>();
  const { data, loading, error } = useSignalGroupInstanceByIdQuery({
    variables: {
      id: instanceId,
    },
  });

  const instance = data?.signalGroupInstanceById;
  const signalGroupName = instance?.signalGroupBySignalGroupId?.name;
  const startAt = instance?.startAt;
  const endAt = instance?.endAt;
  const elapsed = instance?.elapsed;
  const finalSignalStates =
    instance?.signalGroupInstanceHistoriesByInstanceId?.nodes ?? [];

  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '1rem' }}>
        <Link to="/signalGroups">Signal Groups</Link>
        <Link to={`/signalGroups/${id}`}>{signalGroupName}</Link>
        <Typography color="inherit">Instances</Typography>
        <Typography color="textPrimary">{instanceId}</Typography>
      </Breadcrumbs>
      <Card>
        <ViewScreen
          loadingProps={{
            loading,
            variant: 'rect',
            height: 150,
          }}
          error={error}
          header={{
            leftProps: {
              title: 'Instance',
            },
          }}
        >
          <CardContent>
            <LineItems description="Start" value={formatDateTime(startAt)} />
            <LineItems description="End" value={formatDateTime(endAt)} />
            <LineItems
              description="Elapsed"
              value={displayElapsedTime(elapsed)}
            />
          </CardContent>
        </ViewScreen>
      </Card>
      <Typography variant="h5" gutterBottom style={{ marginBottom: '1.4rem' }}>
        Signals Triggered
      </Typography>
      <Grid container spacing={3}>
        {finalSignalStates.map((s) => (
          <Grid item xs={12} sm={12} md={6}>
            <Paper className={classes.paper} variant="outlined">
              <Typography variant="caption">
                {formatDateTime(s.createdAt)}
              </Typography>
              <Typography variant="h6">
                {s.extra?.signal_name ?? 'emptyName'}
              </Typography>
              <Typography variant="body2">{s.extra?.message}</Typography>
              <br />
              <Typography variant="body1">
                {s.extra?.data?.ticker ?? 'noTicker'},&nbsp;
                {s.extra?.data?.interval ?? 'noInterval'}
              </Typography>
              <Typography variant="caption">
                {s.extra?.data?.exchange ?? 'noExchange'}
              </Typography>
              <br />
              <br />
              <Grid container style={{ marginBottom: '1rem' }}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="overline">Open</Typography>
                  <Typography variant="body2">
                    {s.extra?.data?.open ?? 'noOpen'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="overline">Close</Typography>
                  <Typography variant="body2">
                    {s.extra?.data?.close ?? 'noClose'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '1rem' }}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="overline">Low</Typography>
                  <Typography variant="body2">
                    {s.extra?.data?.low ?? 'noLow'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="overline">High</Typography>
                  <Typography variant="body2">
                    {s.extra?.data?.high ?? 'noHigh'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Typography variant="overline">Volume</Typography>
                  <Typography variant="body2">
                    {s.extra?.data?.volume ?? 'noVolume'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="overline">Bar Time</Typography>
                  <Typography variant="body2">
                    {s.extra?.data?.time
                      ? formatDateTime(s.extra?.data?.time)
                      : 'noBarTime'}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {/* <Card>
        <ViewScreen
          loadingProps={{
            loading,
            variant: 'rect',
            height: 150,
          }}
          error={error}
          header={{
            leftProps: {
              title: 'Signals',
            },
          }}
        >
          <Box>
            {finalSignalStates.map((s) => (
              <CardContent key={s.id}>
                <Typography variant="caption">
                  {formatDateTime(s.createdAt)}
                </Typography>
                <Typography variant="h6">{s.extra?.signal_name}</Typography>
                <Typography variant="body2">{s.extra?.message}</Typography>
                <br />
                <Typography variant="body1">
                  {s.extra?.data?.ticker ?? 'null'},&nbsp;
                  {s.extra?.data?.interval ?? 'null'}
                </Typography>
                <Typography variant="caption">
                  {s.extra?.data?.exchange ?? 'null'}
                </Typography>
                <br />
                <br />
                <Box display="flex" style={{ marginBottom: '1rem' }}>
                  <Box style={{ minWidth: '8rem' }}>
                    <Typography variant="overline">Open</Typography>
                    <Typography variant="body2">
                      {s.extra?.data?.open ?? 'null'}
                    </Typography>
                  </Box>
                  <Box style={{ minWidth: '8rem' }}>
                    <Typography variant="overline">Close</Typography>
                    <Typography variant="body2">
                      {s.extra?.data?.close ?? 'null'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="overline">Bar Time</Typography>
                    <Typography variant="body2">
                      {s.extra?.data?.time
                        ? formatDateTime(s.extra?.data?.time)
                        : 'null'}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box style={{ minWidth: '8rem' }}>
                    <Typography variant="overline">Low</Typography>
                    <Typography variant="body2">
                      {s.extra?.data?.low ?? 'null'}
                    </Typography>
                  </Box>
                  <Box style={{ minWidth: '8rem' }}>
                    <Typography variant="overline">High</Typography>
                    <Typography variant="body2">
                      {s.extra?.data?.high ?? 'null'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="overline">Volume</Typography>
                    <Typography variant="body2">
                      {s.extra?.data?.volume ?? 'null'}
                    </Typography>
                  </Box>
                </Box>
                <br />
              </CardContent>
            ))}
          </Box>
        </ViewScreen>
      </Card> */}
    </Box>
  );
};

export default SignalGroupInstanceViewScreen;
