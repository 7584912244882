import FailChip from 'components/Chips/FailChip';
import SuccessChip from 'components/Chips/SuccessChip';
import WarningChip from 'components/Chips/WarningChip';
import { SignalGroupDisplayStatus } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';

type Props = {
  status: null | undefined | SignalGroupDisplayStatus;
};
const SignalGroupStatusPill: FunctionComponent<Props> = ({ status }) => {
  if (status === SignalGroupDisplayStatus.Active) {
    return <SuccessChip label="Active" />;
  }
  if (status === SignalGroupDisplayStatus.Stopped) {
    return <FailChip label="Stopped" />;
  }
  if (status === SignalGroupDisplayStatus.OnCooldown) {
    return <WarningChip text="On cooldown" />;
  }
  return null;
};

export default SignalGroupStatusPill;
