export const logError = (e: Error) => {
  // TODO if in DEV then console, or else send to sentry
  // eslint-disable-next-line
  console.error(e);
};

export const logInfo = (i: any) => {
  // eslint-disable-next-line
  console.log(i);
};
