import { Chip, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  status: {
    backgroundColor: '#E8F5E9',
    border: '1px solid rgba(27,94,32,0.5)',
    borderRadius: '4px',
    color: '#1B5E20',
    fontWeight: 500,
  },
  icon: {
    color: '#1B5E20',
  },
}));

type Props = {
  label?: string;
  icon?: boolean;
};
const ToggleOnChip: FunctionComponent<Props> = ({ label = 'trigger on' }) => {
  const classes = useStyles();

  return (
    <Chip
      size="small"
      label={label}
      className={classes.status}
      // icon={icon ? <Icon className={classes.icon} /> : undefined}
    />
  );
};

export default ToggleOnChip;
