import SignalForm from 'components/SignalForm';
import { useUpdateSignal } from 'hooks/signal';
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

const SignalEditScreen: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const { updateSignal } = useUpdateSignal();

  return (
    <SignalForm
      signalId={id}
      type="update"
      onSubmit={(data) =>
        updateSignal({
          id,
          signalPatch: {
            name: data.name,
          },
        })
      }
    />
  );
};

export default SignalEditScreen;
