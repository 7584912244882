import { Box, CardContent, Grid, Typography } from '@material-ui/core';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import Button from 'components/Button';
import Card from 'components/Card';
import SignalActionPill from 'components/SignalActionPill';
import SkeletonWrapper from 'components/SkeletonWrapper';
import { SimpleTable } from 'components/Table';
import { useDashboardAnalyticsQuery } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDateTime } from 'utils/time';

const recentSignalColumns = [
  {
    label: 'Signal',
    name: 'signalBySignalId.name',
    render: ({ row }: any) => row?.signalBySignalId?.name,
  },
  {
    label: 'Message',
    name: 'message',
  },
  {
    label: 'Action',
    name: 'action',
    width: 50,
    render: ({ value }: any) => <SignalActionPill action={value} />,
  },
  {
    label: 'Time',
    name: 'createdAt',
    width: 250,
    render: ({ value }: any) => formatDateTime(value),
  },
];

const Home: FunctionComponent = () => {
  const history = useHistory();
  const { data, loading, refetch } = useDashboardAnalyticsQuery({
    pollInterval: 5000,
  });

  const signals = data?.activeSignalAnalytics;
  const signalGroups = data?.activeSignalGroupAnalytics;
  const recentSignals = data?.mostRecentSignals?.nodes ?? [];

  return (
    <Box display="flex">
      <Grid container spacing={2}>
        <Grid container justify="flex-end">
          <Grid item style={{ paddingRight: 8 }}>
            <Button
              disabled={loading}
              startIcon={<RefreshOutlinedIcon />}
              onClick={() => refetch()}
              text="Refresh"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Card disableMargin>
            <SkeletonWrapper loading={loading} variant="rect" height={110}>
              <CardContent>
                <Typography variant="overline">Active Signals</Typography>
                <Box display="flex" alignItems="flex-end">
                  <Box>
                    <Typography variant="h4">{signals?.activeCount}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">
                      {`/ ${signals?.totalCount}`}
                    </Typography>
                  </Box>
                </Box>
                <br />
                <Typography variant="body2">
                  {`Total Triggered: ${signals?.totalTriggered}`}
                </Typography>
              </CardContent>
            </SkeletonWrapper>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Card disableMargin>
            <SkeletonWrapper loading={loading} variant="rect" height={110}>
              <CardContent>
                <Typography variant="overline">Active Signal Groups</Typography>
                <Box display="flex" alignItems="flex-end">
                  <Box>
                    <Typography variant="h4">
                      {signalGroups?.activeCount}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">
                      {`/ ${signalGroups?.totalCount}`}
                    </Typography>
                  </Box>
                </Box>
                <br />
                <Typography variant="body2">
                  {`Total Triggered: ${signalGroups?.totalTriggered}`}
                </Typography>
              </CardContent>
            </SkeletonWrapper>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Card disableMargin>
            <SkeletonWrapper loading={loading} variant="rect" height={200}>
              <CardContent>
                <Typography variant="overline">
                  10 Latest Incoming Signals
                </Typography>
              </CardContent>
              <SimpleTable
                onRowClick={(row) =>
                  history.push(`/signals/${row.signalBySignalId?.id}`)
                }
                columns={recentSignalColumns}
                rows={recentSignals}
              />
            </SkeletonWrapper>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
