import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Button from 'components/Button';
import TextField from 'components/Forms/TextField';
import { useRegisterUser } from 'hooks/auth';
import { makeValidate } from 'mui-rff';
import React, { FunctionComponent } from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { logError } from 'utils/logging';
import type { Asserts } from 'yup';
import * as Yup from 'yup';
import { FORM_ERROR } from 'final-form';
import Alert from '@material-ui/lab/Alert';

const Schema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  username: Yup.string().required('Required'),
  password: Yup.string().min(8).required('Required'),
});

type DataSchema = Asserts<typeof Schema>;

const validate = makeValidate<DataSchema>(Schema);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#FF307C',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
  },
  signin: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 2),
    textTransform: 'capitalize',
  },
}));

const SignUp: FunctionComponent = () => {
  const history = useHistory();
  const classes = useStyles();
  const { registerUser } = useRegisterUser();

  const onSubmit = async (data: DataSchema) => {
    try {
      const { error } = await registerUser({
        username: data.username,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
      });
      if (error) {
        return { [FORM_ERROR]: 'Signup failed' };
      }
      history.push('/dashboard');
    } catch (e) {
      logError(e);
    }
    return {};
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={(props) => (
            <form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </Grid>
              </Grid>
              {props.submitError && (
                <Alert severity="error">{props.submitError}</Alert>
              )}
              <Button
                text="Sign Up"
                type="submit"
                disabled={
                  props.submitting ||
                  props.pristine ||
                  props.hasValidationErrors ||
                  (props.hasSubmitErrors && !props.modifiedSinceLastSubmit)
                }
                fullWidth
                className={classes.submit}
                onClick={props.handleSubmit}
              />
              <Button
                variant="text"
                text="Already have an account? Sign in"
                fullWidth
                className={classes.signin}
                onClick={() => history.push('/signIn')}
              />
            </form>
          )}
        />
      </div>
    </Container>
  );
};

export default SignUp;
