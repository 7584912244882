import lowerCase from 'lodash.lowercase';
import startCase from 'lodash.startcase';

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const formatEnum = (text: string | number | undefined | null) => {
  if (!text) {
    return '';
  }
  return startCase(lowerCase(String(text)));
};

export const formatPercentage = (value: string | number | undefined | null) => {
  if (!value) {
    return '';
  }
  return `${value}%`;
};
