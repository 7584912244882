import {
  Breadcrumbs,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import Button from 'components/Button';
import Card from 'components/Card';
import CardHorizontalLine from 'components/CardHorizontalLine';
import TextField from 'components/Forms/TextField';
import FormWrapper from 'components/FormWrapper';
import Link from 'components/Link';
import { useSignalByIdLazyQuery } from 'graphql/graphql-types';
import { makeValidate } from 'mui-rff';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const Schema = Yup.object({
  name: Yup.string().required('Required'),
});

type DataSchema = Asserts<typeof Schema>;

const validate = makeValidate<DataSchema>(Schema);

type Props = {
  type: 'create' | 'update';
  signalId?: string;
  onSubmit: (data: DataSchema) => void;
};
const SignalForm: FunctionComponent<Props> = ({ type, signalId, onSubmit }) => {
  const [ready, setReady] = useState(false);
  const [initialValues, setInitialValues] = useState<DataSchema>();
  const [get, { data }] = useSignalByIdLazyQuery();

  useEffect(() => {
    if (type === 'create') {
      setInitialValues({
        name: '',
      });
      setReady(true);
    }
    if (type === 'update' && signalId) {
      get({
        variables: {
          id: signalId,
        },
      });
    }
  }, [type, signalId]);

  useEffect(() => {
    if (data?.signalById) {
      const signal = data.signalById;
      setInitialValues({
        name: signal.name,
      });
      setReady(true);
    }
  }, [data]);

  return (
    <FormWrapper ready={ready}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        render={(props) => (
          <form>
            <Breadcrumbs
              aria-label="breadcrumb"
              style={{ marginBottom: '1rem' }}
            >
              <Link to="/signals">Signals</Link>
              <Typography color="textPrimary">
                {type === 'update' ? props.values.name : 'New Signal'}
              </Typography>
            </Breadcrumbs>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8}>
                <Card>
                  <CardHeader
                    title="Signal Name"
                    subheader="Recommended to use alerts tracked ticker, timeframe (5m, 1h, 1d, etc), and alert used (VWAP, MACD, StochRSI, etc)."
                  />
                  <CardHorizontalLine />
                  <CardContent>
                    <TextField
                      required
                      label="Name"
                      id="name"
                      name="name"
                      size="small"
                      variant="outlined"
                    />
                    <br />
                    <br />
                    <Button
                      text={type === 'create' ? 'Create' : 'Save'}
                      disabled={
                        props.submitting ||
                        props.pristine ||
                        props.hasValidationErrors ||
                        (props.hasSubmitErrors &&
                          !props.modifiedSinceLastSubmit)
                      }
                      onClick={props.handleSubmit}
                      type="submit"
                    />
                    <br />
                    <Typography variant="caption">
                      New signal will create 3 signal switches: signal trigger
                      on, signal trigger off, and signal trigger toggle. Signal
                      will start in waiting mode.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </FormWrapper>
  );
};

export default SignalForm;
