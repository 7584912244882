import { Button } from '@material-ui/core';
import { generalNotification } from 'components/SnackBar';
import React, { FunctionComponent } from 'react';
import { useClipboard } from 'use-clipboard-copy';

type Props = {
  data: any;
};
const CopyTextButton: FunctionComponent<Props> = ({ data, children }) => {
  const clipboard = useClipboard();
  return (
    <Button
      size="small"
      onClick={() => {
        clipboard.copy(data);
        generalNotification('Copied');
      }}
      style={{
        padding: '0 0',
        textTransform: 'inherit',
        overflowWrap: 'anywhere',
        textAlign: 'left',
      }}
    >
      {children}
    </Button>
  );
};

export default CopyTextButton;
