import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  CardContent,
  Typography,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import ConfirmAlert from 'components/Alerts/ConfirmAlert';
import Card from 'components/Card';
import CopyTextButton from 'components/CopyTextButton';
import LineItems, { Description } from 'components/LineItems';
import Link from 'components/Link';
import SignalActionPill from 'components/SignalActionPill';
import SignalStartStopModal from 'components/SignalStartStopModal';
import SignalStatusPill from 'components/SignalStatusPill';
import SignalTriggerCustomMessageModal from 'components/SignalTriggerCustomMessageModal';
import Table, { SimpleTable } from 'components/Table';
import ViewScreen from 'components/ViewScreen';
import {
  SignalHistoryActionType,
  useSignalByIdQuery,
  AllIncomingSignalsDocument,
} from 'graphql/graphql-types';
import { useModal } from 'hooks/modal';
import { useDeleteSignal } from 'hooks/signal';
import isEmpty from 'lodash.isempty';
import React, { FunctionComponent, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { WEBHOOK_URL } from 'utils/env';
import { formatDateTime } from 'utils/time';

const groupColumns = [
  {
    label: 'Name',
    name: 'name',
  },
];

const signalHistoryColumns = [
  {
    label: 'Action',
    name: 'action',
    render: ({ value }: any) => <SignalActionPill action={value} />,
    disableFilter: true,
  },
  {
    label: 'Message',
    name: 'message',
    disableFilter: true,
  },
  {
    label: 'Time',
    name: 'createdAt',
    width: 250,
    render: ({ value }: any) => formatDateTime(value),
    disableFilter: true,
  },
];

const createTriggerPayload = (
  externalId: string,
  action: SignalHistoryActionType,
  template?: any,
) => ({
  id: externalId,
  action: action.toLowerCase(),
  ...(!isEmpty(template) && { ...template }),
});

const SignalViewScreen: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { data, loading, error } = useSignalByIdQuery({
    variables: {
      id,
    },
  });
  const signal = data?.signalById;
  const externalId = signal?.externalId;
  const name = signal?.name ?? '';
  const template = signal?.customTriggerTemplate;
  const runningAt = signal?.runningAt;
  const createdAt = signal?.createdAt;
  const isActive = signal?.isActive;
  const groups =
    signal?.signalGroupsBySignalGroupItemSignalIdAndSignalGroupId?.nodes ?? [];

  const { deleteSignal } = useDeleteSignal(id);
  const {
    visible: visibleDeleteConfirm,
    open: openDeleteConfirm,
    close: closeDeleteConfirm,
  } = useModal();
  const {
    visible: visibleActiveConfirm,
    open: openActiveConfirm,
    close: closeActiveConfirm,
  } = useModal();
  const {
    visible: visibleCustomSignal,
    open: openCustomSignal,
    close: closeCustomSignal,
  } = useModal();

  const instanceHistoryCondition = {
    signalId: { equalTo: id },
  };

  const openEdit = useCallback(() => {
    if (id) {
      history.push(`/signals/${id}/edit`);
    }
  }, [id]);

  const triggerOnPayload = createTriggerPayload(
    externalId,
    SignalHistoryActionType.TurnOn,
    template,
  );

  const triggerOffPayload = createTriggerPayload(
    externalId,
    SignalHistoryActionType.TurnOff,
    template,
  );
  const triggerTogglePayload = createTriggerPayload(
    externalId,
    SignalHistoryActionType.Toggle,
    template,
  );

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '1rem' }}>
        <Link to="/signals">Signals</Link>
        <Typography color="textPrimary">{name}</Typography>
      </Breadcrumbs>
      <Card>
        <ViewScreen
          loadingProps={{
            loading,
            variant: 'rect',
            height: 150,
          }}
          error={error}
          header={{
            leftProps: {
              component: (
                <CopyTextButton data={name}>
                  <Typography color="textPrimary">{name}</Typography>
                </CopyTextButton>
              ),
            },
            rightProps: {
              buttons: [
                {
                  startIcon: isActive ? <StopIcon /> : <PlayArrowIcon />,
                  text: isActive ? 'Stop' : 'Start',
                  onClick: openActiveConfirm,
                },
                {
                  startIcon: <DeleteOutlineIcon />,
                  text: 'Delete',
                  onClick: openDeleteConfirm,
                },
                {
                  startIcon: <EditOutlinedIcon />,
                  text: 'Edit',
                  onClick: openEdit,
                },
              ],
            },
            dividerProps: {
              hide: false,
            },
          }}
        >
          <CardContent>
            <LineItems
              description="Status"
              value={<SignalStatusPill status={isActive} />}
            />
            <LineItems
              description="Runtime"
              value={formatDateTime(runningAt)}
            />
            <LineItems
              description="Created"
              value={formatDateTime(createdAt)}
            />
            <ConfirmAlert
              isOpen={visibleDeleteConfirm}
              title="Confirm Delete Signal"
              description="This action cannot be reverted. Deleting signal may cause associated signal groups to be triggered quicker. It is recommended to remove signal from signal groups first."
              agreeText="Yes"
              disagreeText="Cancel"
              onAgree={() => {
                closeDeleteConfirm();
                deleteSignal();
              }}
              onDiscard={closeDeleteConfirm}
            />
          </CardContent>
        </ViewScreen>
      </Card>
      <Card>
        <ViewScreen
          loadingProps={{
            loading,
            variant: 'rect',
            height: 150,
          }}
          error={error}
          header={{
            leftProps: {
              title: 'Signal Messages',
            },
            rightProps: {
              buttons: [
                {
                  startIcon: <CloudDownloadIcon />,
                  text: 'Customize Signal',
                  onClick: openCustomSignal,
                },
              ],
            },
            dividerProps: {
              hide: false,
            },
          }}
        >
          <CardContent>
            <LineItems
              descriptionComponent={
                <CopyTextButton data={JSON.stringify(triggerOnPayload)}>
                  <Description description="Signal Trigger On" />
                </CopyTextButton>
              }
              value={
                <Accordion style={{ boxShadow: 'none', minHeight: 'initial' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{
                      display: 'inline-flex',
                      marginTop: '0',
                      padding: '0',
                      minHeight: 'initial',
                      maxHeight: '16px',
                    }}
                  >
                    <Typography variant="body2">View</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0.5rem 0' }}>
                    <CopyTextButton data={JSON.stringify(triggerOnPayload)}>
                      <Typography variant="body2">
                        {JSON.stringify(triggerOnPayload, null, 2)}
                      </Typography>
                    </CopyTextButton>
                  </AccordionDetails>
                </Accordion>
              }
            />
            <LineItems
              descriptionComponent={
                <CopyTextButton data={JSON.stringify(triggerOffPayload)}>
                  <Description description="Signal Trigger Off" />
                </CopyTextButton>
              }
              value={
                <Accordion style={{ boxShadow: 'none', minHeight: 'initial' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{
                      display: 'inline-flex',
                      marginTop: '0',
                      padding: '0',
                      minHeight: 'initial',
                      maxHeight: '16px',
                    }}
                  >
                    <Typography variant="body2">View</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0.5rem 0' }}>
                    <CopyTextButton data={JSON.stringify(triggerOffPayload)}>
                      <Typography variant="body2">
                        {JSON.stringify(triggerOffPayload, null, 2)}
                      </Typography>
                    </CopyTextButton>
                  </AccordionDetails>
                </Accordion>
              }
            />
            <LineItems
              descriptionComponent={
                <CopyTextButton data={JSON.stringify(triggerTogglePayload)}>
                  <Description
                    description="Signal Trigger Toggle"
                    tooltip="A signals waiting status will change to ON when the toggle is first triggered."
                  />
                </CopyTextButton>
              }
              value={
                <Accordion style={{ boxShadow: 'none', minHeight: 'initial' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{
                      display: 'inline-flex',
                      marginTop: '0',
                      padding: '0',
                      minHeight: 'initial',
                      maxHeight: '16px',
                    }}
                  >
                    <Typography variant="body2">View</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0.5rem 0' }}>
                    <CopyTextButton data={JSON.stringify(triggerTogglePayload)}>
                      <Typography variant="body2">
                        {JSON.stringify(triggerTogglePayload, null, 2)}
                      </Typography>
                    </CopyTextButton>
                  </AccordionDetails>
                </Accordion>
              }
            />
            <LineItems
              description="Webhook URL"
              value={
                <Box component="div" textOverflow="ellipsis" overflow="hidden">
                  <CopyTextButton data={WEBHOOK_URL}>
                    <Typography variant="body2">{WEBHOOK_URL}</Typography>
                  </CopyTextButton>
                </Box>
              }
            />
          </CardContent>
        </ViewScreen>
      </Card>
      <Card>
        <ViewScreen
          loadingProps={{
            loading,
            variant: 'rect',
            height: 150,
          }}
          error={error}
          header={{
            leftProps: {
              title: 'Associated Signal Groups',
            },
            dividerProps: {
              hide: false,
            },
          }}
        >
          <SimpleTable
            columns={groupColumns}
            rows={groups}
            onRowClick={(row) => history.push(`/signalGroups/${row.id}`)}
          />
        </ViewScreen>
      </Card>
      <Card>
        <ViewScreen
          loadingProps={{
            loading,
            variant: 'rect',
            height: 150,
          }}
          error={error}
          header={{
            leftProps: {
              title: 'Incoming Signal History',
            },
            dividerProps: {
              hide: false,
            },
          }}
        >
          <Table
            columns={signalHistoryColumns}
            condition={instanceHistoryCondition}
            query={AllIncomingSignalsDocument}
          />
        </ViewScreen>
      </Card>
      <SignalStartStopModal
        signalId={id}
        visible={visibleActiveConfirm}
        handleClose={closeActiveConfirm}
        isActive={isActive}
      />
      <SignalTriggerCustomMessageModal
        signalId={id}
        visible={visibleCustomSignal}
        handleClose={closeCustomSignal}
      />
    </div>
  );
};

export default SignalViewScreen;
