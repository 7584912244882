import { Box } from '@material-ui/core';
import React from 'react';
import HeaderBottom, { Props as HeaderBottomProps } from './HeaderBottom';
import HeaderBottomDivider, {
  Props as HeaderBottomDividerProps,
} from './HeaderBottomDivider';
import HeaderCenter, { Props as HeaderCenterProps } from './HeaderCenter';
import HeaderRight, { Props as HeaderRightProps } from './HeaderRight';
import HeaderLeft, { Props as HeaderLeftProps } from './HeaderLeft';
import { useStyles } from './styles';

export type Props = {
  loading?: boolean;
  leftProps: HeaderLeftProps;
  centerProps?: HeaderCenterProps;
  rightProps?: HeaderRightProps;
  bottomProps?: HeaderBottomProps & { visible?: boolean };
  dividerProps?: HeaderBottomDividerProps;
};
export default ({
  loading,
  leftProps,
  centerProps,
  rightProps,
  bottomProps,
  dividerProps,
}: Props) => {
  const classes = useStyles();
  if (loading) {
    return null;
  }
  return (
    <Box className={classes.headerContainer}>
      <Box
        display="flex"
        flexWrap="wrap"
        className={classes.topHeaderContainer}
      >
        <HeaderLeft {...leftProps} />
        <HeaderCenter {...centerProps} />
        <HeaderRight {...rightProps} />
      </Box>
      {bottomProps?.visible && (
        <Box display="flex" className={classes.middleHeaderContainer}>
          <HeaderBottom {...bottomProps} />
        </Box>
      )}
      {!dividerProps?.hide && (
        <Box className={classes.bottomHeaderContainer}>
          <HeaderBottomDivider {...dividerProps} />
        </Box>
      )}
    </Box>
  );
};
