import React, { FunctionComponent } from 'react';
import ModalLoading from './ModalLoading';

type Props = {
  ready: boolean;
};
const FormWrapper: FunctionComponent<Props> = ({ ready, children }) => {
  if (!ready) {
    return <ModalLoading />;
  }
  return <>{children}</>;
};

export default FormWrapper;
