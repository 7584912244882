import {
  ApolloCache,
  NormalizedCacheObject,
  useApolloClient,
} from '@apollo/client';
import { RootCache } from 'config/ApolloClient';
import {
  GetAuthDocument,
  GetAuthQuery,
  LoginByRefreshTokenMutation,
  LoginByUsernameAndPasswordMutation,
  RegisterUserInput,
  useGetAuthQuery,
  useLoginByRefreshTokenMutation,
  useLoginByUsernameAndPasswordMutation,
  useLogoutMutation,
  useRegisterUserMutation,
} from 'graphql/graphql-types';
import { useCallback, useEffect } from 'react';
import { logError } from 'utils/logging';
import { getItem, setItem } from 'utils/storage';

export const updateAuthorizeCache = (
  cache: ApolloCache<
    | LoginByUsernameAndPasswordMutation
    | LoginByRefreshTokenMutation
    | NormalizedCacheObject
  >,
  refreshToken: string | null | undefined,
  accessToken: string | null | undefined,
) => {
  cache.writeQuery<GetAuthQuery>({
    query: GetAuthDocument,
    data: {
      auth: {
        isInitialized: true,
        accessToken,
        refreshToken,
      },
    },
  });
  setItem('auth', { refreshToken });
};

export const useLoginByUsernameAndPassword = () => {
  const [
    loginByEmailAndPasswordMutation,
    data,
  ] = useLoginByUsernameAndPasswordMutation({
    update(cache, result) {
      const { refreshToken, accessToken } =
        result.data?.loginByUsernameAndPassword?.credentials ?? {};
      updateAuthorizeCache(cache, refreshToken, accessToken);
    },
  });

  const loginByUsernameAndPassword = useCallback(
    async (username: string, password: string) => {
      try {
        await loginByEmailAndPasswordMutation({
          variables: { username, password },
        });
        return { error: null };
      } catch (e) {
        logError(e);
        return { error: 'Username or password is incorrect' };
      }
    },
    [],
  );

  return { loginByUsernameAndPassword, data };
};

export const useAuth = () => {
  const { data } = useGetAuthQuery();
  const { accessToken, isInitialized } = data?.auth ?? {};
  return { isInitialized, isAuthorized: !!accessToken };
};

export const useLogout = () => {
  const [logout] = useLogoutMutation({
    update(cache, result) {
      const { success } = result.data?.logout ?? {};
      if (success) {
        updateAuthorizeCache(cache, '', '');
      }
    },
  });
  const client = useApolloClient();
  return useCallback(async () => {
    const { data } = await logout();
    if (!data?.logout?.success) {
      logError(new Error('Failed to log out'));
      return;
    }
    await client.resetStore();
  }, []);
};

export const useInitialAuth = () => {
  const [authorizeByRefreshToken] = useLoginByRefreshTokenMutation({
    update(cache, result) {
      const { refreshToken, accessToken } =
        result.data?.loginByRefreshToken?.credentials ?? {};
      updateAuthorizeCache(cache, refreshToken, accessToken);
    },
  });

  useEffect(() => {
    async function tryAuth() {
      try {
        const credentials = await getItem('auth');
        if (!credentials?.refreshToken) {
          // No refreshToken in storage
          throw Error('Incorrect refreshToken');
        }
        await authorizeByRefreshToken({
          variables: { refreshToken: credentials.refreshToken },
        });
      } catch (e) {
        logError(e);
        if (e.message === 'Incorrect refreshToken') {
          // No refreshToken in storage or refreshToken is stale
          updateAuthorizeCache(RootCache, '', '');
        }
      }
    }
    tryAuth();
  }, []);
};

export const useRegisterUser = () => {
  const [commit] = useRegisterUserMutation();

  const registerUser = useCallback(async (data: RegisterUserInput) => {
    try {
      await commit({
        variables: {
          input: data,
        },
        update(cache, result) {
          const { refreshToken, accessToken } =
            result.data?.registerUser?.credentials ?? {};
          updateAuthorizeCache(cache, refreshToken, accessToken);
        },
      });
      return { error: null };
    } catch (e) {
      logError(e);
      return { error: 'Register failed' };
    }
  }, []);

  return { registerUser };
};
