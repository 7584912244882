import { useState } from 'react';

export const useModal = () => {
  const [visible, setVisible] = useState(false);

  const open = () => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  return { visible, open, close };
};

export const useSelectedValueModal = () => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const open = (value: string) => {
    setSelectedValue(value);
  };

  const close = () => {
    setSelectedValue(null);
  };

  return { selectedValue, open, close };
};
