import { Checkbox, withStyles } from '@material-ui/core';

const BrandCheckbox = withStyles({
  root: {
    color: '#C4C4C4',
    '&$checked': {
      color: '#FF307C',
    },
    '&$checked + $track': {
      backgroundColor: '#C4C4C4',
    },
  },
  checked: {
    color: '#FF307C',
  },
})(Checkbox);

export default BrandCheckbox;
