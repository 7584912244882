import FailChip from 'components/Chips/FailChip';
import SuccessChip from 'components/Chips/SuccessChip';
import React, { FunctionComponent } from 'react';

type Props = {
  status: null | undefined | boolean;
};
const SignalStatusPill: FunctionComponent<Props> = ({ status }) => {
  if (status) {
    return <SuccessChip label="Active" />;
  }
  return <FailChip label="Stopped" />;
};

export default SignalStatusPill;
