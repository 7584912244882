import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import OkIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import CircleLoading from 'components/CircleLoading';

const useStyles = makeStyles((theme) => ({
  loaderSpinner: {
    paddingRight: theme.spacing(1),
    paddingTop: '3px',
    paddingLeft: '4px',
  },
  loaderMessage: {},
}));

interface Props {
  ok?: boolean;
  loading: boolean;
  message: string;
}
export default ({ ok = true, message, loading }: Props) => {
  const classes = useStyles();
  return (
    <Box display="flex">
      <Box className={classes.loaderSpinner}>
        {loading && <CircleLoading size={16} />}
        {!loading && ok && <OkIcon style={{ fontSize: 16 }} />}
        {!loading && !ok && <ErrorIcon style={{ fontSize: 16 }} />}
      </Box>
      <Box flexGrow={1} className={classes.loaderMessage}>
        <Typography>{message}</Typography>
      </Box>
    </Box>
  );
};
