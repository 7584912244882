import { Box, Drawer, Link, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React, { FunctionComponent } from 'react';
import { openInNewTab } from 'utils/general';

const DRAWER_WIDTH = 400;

type Props = {
  visible: boolean;
  onClose: () => void;
};
const ReleaseNotes: FunctionComponent<Props> = ({ visible, onClose }) => (
  <Drawer
    open={visible}
    anchor="right"
    onClose={onClose}
    style={{
      width: DRAWER_WIDTH,
    }}
  >
    <div style={{ width: DRAWER_WIDTH }}>
      <Box style={{ margin: '1rem 1.1rem' }} display="flex">
        <Box style={{ marginRight: '0.75rem' }}>
          <InfoIcon style={{ color: '#757575', fontSize: '20px' }} />
        </Box>
        <Box flex="1">
          <Box style={{ marginBottom: '0.5rem' }}>
            <Typography variant="body2" gutterBottom>
              Added documentation, read more about SignalHooks{' '}
              <Link
                href="https://docs.signalhooks.com"
                underline="always"
                style={{ fontWeight: 500 }}
                onClick={(e: React.MouseEvent<EventTarget>) => {
                  e.preventDefault();
                  openInNewTab('https://docs.signalhooks.com');
                }}
              >
                here
              </Link>
              .
            </Typography>
            <Typography variant="body2" gutterBottom>
              Signal groups now have more controls over individual signal
              triggers (perpetual, cooldown, first toggle state).
            </Typography>
            <Typography variant="body2" gutterBottom>
              Signals can capture additional TradingView message variables,
              which are used to provide additional data for successful signal
              group triggers.
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption">Jul 9, 2021</Typography>
          </Box>
        </Box>
      </Box>
      <Box style={{ margin: '1rem 1.1rem' }} display="flex">
        <Box style={{ marginRight: '0.75rem' }}>
          <InfoIcon style={{ color: '#757575', fontSize: '20px' }} />
        </Box>
        <Box flex="1">
          <Box style={{ marginBottom: '0.5rem' }}>
            <Typography variant="body2" gutterBottom>
              SignalHooks now supports cooldown timers for signal groups
              triggers. You may also select the option to stop signal groups
              after one trigger event.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Signals can track simple crossing alerts via the toggle signal. A
              signals waiting status will change to ON when the toggle is first
              triggered.
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption">Jun 22, 2021</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  </Drawer>
);

export default ReleaseNotes;
