import { Box, makeStyles } from '@material-ui/core';
import Icon from '@material-ui/icons/HourglassEmpty';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  status: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ECEFF1',
    border: '1px solid rgba(69,90,100,0.2)',
    borderRadius: '100px',
    fontWeight: 500,
    height: '2.5rem',
    width: '2.5rem',
    marginRight: '-1rem',
  },
  icon: {
    alignSelf: 'center',
    color: '#455A64',
    fontSize: '1.6rem',
  },
}));

type Props = {
  text?: string;
  icon?: boolean;
  renderNullIfEmpty?: boolean;
};
const NeutralCircle: FunctionComponent<Props> = ({
  text,
  renderNullIfEmpty = true,
  icon = true,
}) => {
  const classes = useStyles();
  return !text && renderNullIfEmpty ? null : (
    <Box className={classes.status}>
      {icon ? <Icon className={classes.icon} fontSize="small" /> : undefined}
    </Box>
  );
};

export default NeutralCircle;
