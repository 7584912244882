import NeutralChip from 'components/Chips/NeutralChip';
import ToggleChip from 'components/Chips/ToggleChip';
import ToggleOffChip from 'components/Chips/ToggleOffChip';
import ToggleOnChip from 'components/Chips/ToggleOnChip';
import { SignalHistoryActionType } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';

type Props = {
  action?: SignalHistoryActionType;
};
const SignalActionPill: FunctionComponent<Props> = ({ action }) => {
  if (!action) {
    return null;
  }
  if (action === SignalHistoryActionType.Toggle) {
    return <ToggleChip />;
  }

  if (action === SignalHistoryActionType.TurnOn) {
    return <ToggleOnChip />;
  }

  if (action === SignalHistoryActionType.TurnOff) {
    return <ToggleOffChip />;
  }

  return <NeutralChip text={String(action)} />;
};

export default SignalActionPill;
