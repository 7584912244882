import MUILink from '@material-ui/core/Link';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
  to: string;
};
const Link: FunctionComponent<Props> = ({ to, children, ...linkProps }) => {
  const history = useHistory();

  const preventDefault = (event: React.SyntheticEvent) => {
    event.preventDefault();
    history.push(to);
  };

  return (
    <MUILink color="inherit" onClick={preventDefault} {...linkProps}>
      {children}
    </MUILink>
  );
};

export default Link;
