import {
  DeleteSignalGroupActionByIdInput,
  SignalGroupActionPatch,
  useDeleteSignalGroupActionByIdMutation,
  useUpdateSignalGroupActionByIdMutation,
} from 'graphql/graphql-types';
import { useCallback } from 'react';
import { logError } from 'utils/logging';

export const useUpdateSignalGroupAction = () => {
  const [commit] = useUpdateSignalGroupActionByIdMutation();

  const updateSignalGroupAction = useCallback(
    (signalGroupActionId: string, data: SignalGroupActionPatch) => {
      const execute = async () => {
        try {
          await commit({
            variables: {
              input: {
                id: signalGroupActionId,
                signalGroupActionPatch: data,
              },
            },
          });
        } catch (e) {
          logError(e);
        }
      };
      execute();
    },
    [],
  );

  return { updateSignalGroupAction };
};

export const useDeleteSignalGroupAction = () => {
  const [commit] = useDeleteSignalGroupActionByIdMutation();

  const deleteSignalGroupAction = useCallback(
    (data: DeleteSignalGroupActionByIdInput) => {
      const execute = async () => {
        try {
          await commit({
            variables: {
              input: data,
            },
            update(cache) {
              const normalizedId = cache.identify({
                id: data.id,
                __typename: 'SignalGroupAction',
              });
              cache.evict({ id: normalizedId });
              cache.gc();
            },
          });
        } catch (e) {
          logError(e);
        }
      };
      execute();
    },
    [],
  );

  return { deleteSignalGroupAction };
};
