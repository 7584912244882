import SignalForm from 'components/SignalForm';
import { useCreateSignal } from 'hooks/signal';
import React, { FunctionComponent } from 'react';

const SignalCreateScreen: FunctionComponent = () => {
  const { createSignal } = useCreateSignal();

  return (
    <SignalForm
      type="create"
      onSubmit={(data) =>
        createSignal({
          signal: {
            name: data.name,
          },
        })
      }
    />
  );
};

export default SignalCreateScreen;
