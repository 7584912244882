import React from 'react';
import { TextField as MUITextField, TextFieldProps } from 'mui-rff';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

type Props = {
  parseType?: 'integer' | 'decimal' | 'percentage' | 'money';
} & TextFieldProps;

type FieldProps = {
  parse?: (value: any, name: string) => any;
};

const parseDecimal = (value: string, maxDecimalLength = 2) => {
  let v = value.replace(/[^0-9.]/g, '');
  if (!v.length) {
    return v;
  }
  if (v[v.length - 1] === '.') {
    const m = v.match(/\./g);
    if (m && m.length > 1) {
      return v.slice(0, v.length - 1);
    }
    return v;
  }
  v = String(parseFloat(v));
  const r = v.split('.');

  if (r.length > 1 && r[1].length > maxDecimalLength) {
    return v.slice(0, v.length - 1);
  }
  return v;
};
const parsePercentage = (value: number) => {
  if (value > 100) {
    return 100;
  }
  if (value < 0) {
    return 0;
  }
  return value;
};

const TextField = ({ parseType, ...props }: Props) => {
  const fieldProps: FieldProps = {};
  const inputProps: any = {};
  if (parseType === 'integer') {
    fieldProps.parse = (value) => Number(value);
    inputProps.type = 'number';
  } else if (parseType === 'decimal' || parseType === 'money') {
    inputProps.type = 'number';
    inputProps.step = '1';
    fieldProps.parse = (value) => parseFloat(parseDecimal(value));
    if (parseType === 'money') {
      inputProps.startAdornment = (
        <InputAdornment position="start">$</InputAdornment>
      );
    }
  } else if (parseType === 'percentage') {
    inputProps.type = 'number';
    inputProps.step = '1';
    inputProps.min = '0';
    inputProps.max = '100';
    fieldProps.parse = (value) =>
      parsePercentage(parseFloat(parseDecimal(value, 3)));
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <Icon>%</Icon>
      </InputAdornment>
    );
  }
  return (
    <MUITextField fieldProps={fieldProps} {...props} InputProps={inputProps} />
  );
};

export default TextField;
