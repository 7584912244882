import { FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import BrandCheckbox from 'components/BrandCheckbox';
import Button from 'components/Button';
import FormWrapper from 'components/FormWrapper';
import { useSignalByIdLazyQuery } from 'graphql/graphql-types';
import { useUpdateSignal } from 'hooks/signal';
import isEmpty from 'lodash.isempty';
import reduce from 'lodash.reduce';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { openInNewTab } from 'utils/general';

const useStyles = makeStyles(() => ({
  dialog: {
    width: '100%',
  },
  dialogSwitchContent: {
    background: 'rgb(247, 250, 252)',
    borderRadius: '4px',
    padding: '1rem 1.2rem 0.8rem',
    marginTop: '1rem',
  },
}));

const messageVariables = [
  { label: 'Custom message', name: 'message' },
  { label: 'Exchange', name: 'exchange' },
  { label: 'Ticker', name: 'ticker' },
  { label: 'Interval', name: 'interval' },
  { label: 'Time', name: 'time' },
  { label: 'Open', name: 'open' },
  { label: 'Close', name: 'close' },
  { label: 'High', name: 'high' },
  { label: 'Low', name: 'low' },
  { label: 'Volume', name: 'volume' },
  { label: 'Timenow', name: 'timenow' },
];

interface StartState {
  [index: string]: boolean;
}
const startState: StartState = {};

messageVariables.forEach((d) => {
  startState[d.name] = false;
});

interface JsonData {
  [index: string]: string;
}

type Props = {
  signalId: string;
  visible: boolean;
  handleClose: () => void;
};
const SignalTriggerCustomMessageModal: FunctionComponent<Props> = ({
  signalId,
  visible,
  handleClose,
}) => {
  const classes = useStyles();
  const [ready, setReady] = useState(false);
  const [selected, setSelected] = useState(startState);
  const { updateSignal } = useUpdateSignal();
  const [get, { data, loading }] = useSignalByIdLazyQuery();

  useEffect(() => {
    if (visible && signalId) {
      get({
        variables: {
          id: signalId,
        },
      });
    }
  }, [visible, signalId]);

  useEffect(() => {
    if (visible && !loading && data) {
      const template = data?.signalById?.customTriggerTemplate;
      setSelected({
        message: !!template?.message,
        exchange: !!template?.data?.exchange,
        ticker: !!template?.data?.ticker,
        close: !!template?.data?.close,
        open: !!template?.data?.open,
        high: !!template?.data?.high,
        low: !!template?.data?.low,
        time: !!template?.data?.time,
        volume: !!template?.data?.volume,
        timenow: !!template?.data?.timenow,
        interval: !!template?.data?.interval,
      });
      setReady(true);
    }
  }, [visible, data, loading]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected((state) => ({
      ...state,
      [event.target.name]: event.target.checked,
    }));
  };

  const selectAll = () =>
    setSelected({
      message: true,
      exchange: true,
      ticker: true,
      close: true,
      open: true,
      high: true,
      low: true,
      time: true,
      volume: true,
      timenow: true,
      interval: true,
    });
  const unselectAll = () => setSelected(startState);

  const handleOk = useCallback(() => {
    const execute = async () => {
      const messageData: JsonData = reduce(
        selected,
        (result, value, key) => {
          const newResult = { ...result };
          if (key !== 'message' && value) {
            // @ts-ignore
            newResult[key] = `{{${key}}}`;
          }
          return newResult;
        },
        {},
      );

      const message = selected.message ?? false;

      const finalData =
        !isEmpty(messageData) || message
          ? {
              ...(message && { message: 'Custom Message' }),
              ...(!isEmpty(messageData) && { data: messageData }),
            }
          : {};

      await updateSignal({
        id: signalId,
        signalPatch: {
          customTriggerTemplate: isEmpty(finalData) ? null : finalData,
        },
      });

      setSelected(startState);
      handleClose();
    };
    if (signalId) {
      execute();
    }
  }, [signalId, selected]);

  const handleTVOpen = (e: React.MouseEvent<EventTarget>) => {
    e.preventDefault();
    openInNewTab(
      'https://www.tradingview.com/support/solutions/43000531021-how-to-use-a-variable-value-in-alert/',
    );
  };

  return (
    <Dialog
      open={visible}
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <DialogTitle id="form-dialog-title" style={{ paddingBottom: '0rem' }}>
        Customize Signal Message
      </DialogTitle>
      <FormWrapper ready={ready}>
        <DialogContent>
          <Box style={{ marginBottom: '1.5rem' }}>
            <Typography variant="body2">
              Capture additional alert data from{' '}
              <Link
                href="https://www.tradingview.com/support/solutions/43000531021-how-to-use-a-variable-value-in-alert/"
                underline="always"
                onClick={handleTVOpen}
              >
                TradingView
              </Link>
            </Typography>
          </Box>
          <Box display="flex" style={{ marginBottom: '1rem' }}>
            <Box style={{ marginRight: '0.5rem' }}>
              <Button text="Select all" onClick={selectAll} />
            </Box>
            <Box>
              <Button text="Unselect all" onClick={unselectAll} />
            </Box>
          </Box>
          <FormGroup>
            {messageVariables.map((messageVariable) => (
              <FormControlLabel
                key={messageVariable.name}
                value={selected[messageVariable.name]}
                control={
                  <BrandCheckbox
                    checked={selected[messageVariable.name]}
                    name={messageVariable.name}
                    onChange={handleChange}
                  />
                }
                label={messageVariable.label}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleClose}>Cancel</MuiButton>
          <MuiButton onClick={handleOk} color="primary">
            Save
          </MuiButton>
        </DialogActions>
      </FormWrapper>
    </Dialog>
  );
};

export default SignalTriggerCustomMessageModal;
