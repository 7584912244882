import {
  ClickAwayListener,
  createStyles,
  Grow,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import HelpIcon from '@material-ui/icons/Help';
import ReleaseNotes from 'components/ReleaseNotes/ReleaseNotes';
import { useCurrentUserQuery } from 'graphql/graphql-types';
import { useLogout } from 'hooks/auth';
import { useModal } from 'hooks/modal';
import React from 'react';
import { openInNewTab } from 'utils/general';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    menuButtonAccount: {
      borderRadius: '4px',
      padding: theme.spacing(1, 1.5),
    },
    title: {
      color: '#424242',
      flexGrow: 1,
      marginRight: theme.spacing(1),
    },
  }),
);

const StyledIconButton = withStyles((theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(1),
    },
  }),
)(IconButton);

export default function MenuListComposition() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const logout = useLogout();
  const { data } = useCurrentUserQuery();
  const user = data?.currentUser;
  const username = user?.username ?? '';
  const {
    visible: visibleReleaseNotes,
    open: openReleaseNotes,
    close: closeReleaseNotes,
  } = useModal();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus?.();
    }

    prevOpen.current = open;
  }, [open]);

  const handleSignOut = () => {
    logout();
    // signOut();
    handleToggle();
  };

  return (
    <div className={classes.root}>
      <div>
        <StyledIconButton
          onClick={(e) => {
            e.stopPropagation();
            openReleaseNotes();
          }}
        >
          <NewReleasesIcon />
        </StyledIconButton>
        <StyledIconButton
          onClick={() => openInNewTab('https://docs.signalhooks.com')}
        >
          <HelpIcon />
        </StyledIconButton>
        <IconButton
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          aria-label="user"
          onClick={handleToggle}
          className={classes.menuButtonAccount}
        >
          <Typography
            component="h1"
            variant="subtitle1"
            noWrap
            className={classes.title}
          >
            {username}
          </Typography>
          <AccountCircle />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleSignOut}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <ReleaseNotes
          visible={visibleReleaseNotes}
          onClose={closeReleaseNotes}
        />
      </div>
    </div>
  );
}
