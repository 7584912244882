import {
  Box,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import TableEmptyComponent from 'components/TableEmptyComponent';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  gridContainer: {
    // borderBottom: '1px solid #e0e0e0',
    // borderTop: '1px solid #e0e0e0',
    maxHeight: '80vh',
    overflow: 'auto',
  },
  gridItemTable: {
    // borderRight: '1px solid #e0e0e0',
    flex: '1 1 60%',
    overflow: 'auto',
  },
  tableContainer: {
    height: '50vh',
    width: '100%',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      fontSize: 12,
      textTransform: 'uppercase',
    },
  }),
)(TableCell);

type Column = {
  label: string;
  name: string;
  render?: ({ value, row }: any) => any;
  align?: 'right' | 'left' | 'center';
  width?: number;
  span?: number;
};

export type Props = {
  columns: Column[];
  rows: any[];
  emptyMessage?: string;
  onRowClick?: (row: any) => void;
};
const SimpleTable: FunctionComponent<Props> = ({
  columns,
  rows,
  emptyMessage,
  onRowClick,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.gridContainer}>
      <Box className={classes.gridItemTable}>
        <TableContainer style={{ maxHeight: '50vh' }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.name}
                    align={column?.align}
                    style={column.width ? { width: column.width } : {}}
                    colSpan={column.span}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 ? (
                <TableEmptyComponent
                  colSpan={columns.length}
                  text={emptyMessage}
                />
              ) : (
                rows.map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    className={classes.pointer}
                    key={String(row.id)}
                    onClick={() => onRowClick?.(row)}
                  >
                    {columns.map((column) => {
                      let value = row[column.name];
                      if (column.render) {
                        value = column.render({ value, row });
                      }
                      return (
                        <TableCell key={column.name} align={column?.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default SimpleTable;
