import React, { ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';

export type Props = {
  title?: string;
  component?: ReactElement;
};
export default ({ title, component }: Props) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box className={classes.titleContainer}>
        {title && (
          <Typography>
            <Box className={classes.title}>{title}</Box>
          </Typography>
        )}
        {component}
      </Box>
    </Box>
  );
};
