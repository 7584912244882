import { FilterElements } from 'material-ui-apollo-table';

export const mapEnumToArray = (values: any) => {
  const options: string[] = [];
  Object.entries(values).forEach((v) => {
    options.push(String(v[1]));
  });
  return options;
};

type Option = {
  label: string;
  value: string;
};
export const mapEnumToSelectOptions = (
  values: any,
  makePlural: boolean,
  lowercase: boolean,
) => {
  const options: Option[] = [];
  Object.entries(values).forEach((v) => {
    const label = `${String(v[0])}${makePlural ? 's' : ''}`;
    options.push({
      label: lowercase ? label.toLowerCase() : label,
      value: String(v[1]),
    });
  });
  return options;
};

export const mapEnumToFilterElements = (values: any): FilterElements[] =>
  [
    {
      text: 'All',
      value: '',
    },
  ].concat(
    Object.entries(values).map((v) => ({
      text: String(v[0])
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2'),
      value: String(v[1]),
    })),
  );
