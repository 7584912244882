import SignalGroupForm from 'components/SignalGroupForm';
import {
  CooldownAfterTriggerInterval,
  SignalGroupItemFirstToggleActionValue,
  SignalGroupItemCooldownAfterReceivedSignalInterval,
} from 'graphql/graphql-types';
import { useUpdateEntireSignalGroup } from 'hooks/signalGroup';
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

const SignalGroupEditScreen: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const { updateEntireSignalGroup } = useUpdateEntireSignalGroup();

  return (
    <SignalGroupForm
      type="update"
      signalGroupId={id}
      onSubmit={(data) => {
        updateEntireSignalGroup({
          id,
          name: data.name,
          stopAfterTrigger: data.afterTrigger === 'stop',
          cooldownAfterTriggerAmount:
            data.afterTrigger === 'cooldown'
              ? data.cooldownAfterTriggerAmount
              : null,
          cooldownAfterTriggerInterval:
            data.afterTrigger === 'cooldown'
              ? (data.cooldownAfterTriggerInterval as CooldownAfterTriggerInterval)
              : null,
          signals: data.signals
            ? data.signals?.map((s) => ({
                id: s.id,
                isPerpetual: s.isPerpetual,
                firstToggleActionValue: s.firstToggleActionValue as SignalGroupItemFirstToggleActionValue,
                cooldownAfterReceivedSignalAmount: s.cooldownAfterReceivedSettings
                  ? s.cooldownAfterReceivedSignalAmount
                  : null,
                cooldownAfterReceivedSignalInterval: s.cooldownAfterReceivedSettings
                  ? (s.cooldownAfterReceivedSignalInterval as SignalGroupItemCooldownAfterReceivedSignalInterval)
                  : null,
                resetCooldownAfterGroupTrigger: s.cooldownAfterReceivedSettings
                  ? s.resetCooldownAfterGroupTrigger
                  : null,
              }))
            : [],
          deleteSignals: data.deleteSignals
            ? data.deleteSignals?.map((s) => String(s))
            : [],
          actions: data.actions
            ? data.actions?.map((a) => ({
                id: a.id ?? v4(),
                payload: a.payload,
              }))
            : [],
          deleteActions: data.deleteActions
            ? data.deleteActions?.map((a) => String(a))
            : [],
        });
      }}
    />
  );
};

export default SignalGroupEditScreen;
