import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any };
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
};



/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  activeSignalAnalytics?: Maybe<ActiveSignalAnalytic>;
  activeSignalGroupAnalytics?: Maybe<ActiveSignalGroupAnalytic>;
  /** Reads and enables pagination through a set of `SignalGroupActionTriggerHistory`. */
  allSignalGroupActionTriggerHistories?: Maybe<SignalGroupActionTriggerHistoriesConnection>;
  /** Reads and enables pagination through a set of `SignalGroupAction`. */
  allSignalGroupActions?: Maybe<SignalGroupActionsConnection>;
  /** Reads and enables pagination through a set of `SignalGroupCurrentInstance`. */
  allSignalGroupCurrentInstances?: Maybe<SignalGroupCurrentInstancesConnection>;
  /** Reads and enables pagination through a set of `SignalGroupInstanceHistory`. */
  allSignalGroupInstanceHistories?: Maybe<SignalGroupInstanceHistoriesConnection>;
  /** Reads and enables pagination through a set of `SignalGroupInstance`. */
  allSignalGroupInstances?: Maybe<SignalGroupInstancesConnection>;
  /** Reads and enables pagination through a set of `SignalGroupItem`. */
  allSignalGroupItems?: Maybe<SignalGroupItemsConnection>;
  /** Reads and enables pagination through a set of `SignalGroupTriggerHistory`. */
  allSignalGroupTriggerHistories?: Maybe<SignalGroupTriggerHistoriesConnection>;
  /** Reads and enables pagination through a set of `SignalGroup`. */
  allSignalGroups?: Maybe<SignalGroupsConnection>;
  /** Reads and enables pagination through a set of `SignalHistory`. */
  allSignalHistories?: Maybe<SignalHistoriesConnection>;
  /** Reads and enables pagination through a set of `Signal`. */
  allSignals?: Maybe<SignalsConnection>;
  /** Reads and enables pagination through a set of `User`. */
  allUsers?: Maybe<UsersConnection>;
  auth?: Maybe<Auth>;
  /** The currently logged in user (or null if not logged in). */
  currentUser?: Maybe<User>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads a single `Signal` using its globally unique `ID`. */
  signal?: Maybe<Signal>;
  /** Reads and enables pagination through a set of `SignalAndSignalGroupRelationship`. */
  signalAndSignalGroupRelationship?: Maybe<SignalAndSignalGroupRelationshipsConnection>;
  signalByExternalId?: Maybe<Signal>;
  signalById?: Maybe<Signal>;
  /** Reads a single `SignalGroup` using its globally unique `ID`. */
  signalGroup?: Maybe<SignalGroup>;
  /** Reads a single `SignalGroupAction` using its globally unique `ID`. */
  signalGroupAction?: Maybe<SignalGroupAction>;
  signalGroupActionById?: Maybe<SignalGroupAction>;
  /** Reads a single `SignalGroupActionTriggerHistory` using its globally unique `ID`. */
  signalGroupActionTriggerHistory?: Maybe<SignalGroupActionTriggerHistory>;
  signalGroupActionTriggerHistoryById?: Maybe<SignalGroupActionTriggerHistory>;
  signalGroupById?: Maybe<SignalGroup>;
  /** Reads a single `SignalGroupCurrentInstance` using its globally unique `ID`. */
  signalGroupCurrentInstance?: Maybe<SignalGroupCurrentInstance>;
  signalGroupCurrentInstanceByGroupId?: Maybe<SignalGroupCurrentInstance>;
  /** Reads a single `SignalGroupInstance` using its globally unique `ID`. */
  signalGroupInstance?: Maybe<SignalGroupInstance>;
  signalGroupInstanceById?: Maybe<SignalGroupInstance>;
  /** Reads a single `SignalGroupInstanceHistory` using its globally unique `ID`. */
  signalGroupInstanceHistory?: Maybe<SignalGroupInstanceHistory>;
  signalGroupInstanceHistoryById?: Maybe<SignalGroupInstanceHistory>;
  /** Reads a single `SignalGroupItem` using its globally unique `ID`. */
  signalGroupItem?: Maybe<SignalGroupItem>;
  signalGroupItemById?: Maybe<SignalGroupItem>;
  /** Reads a single `SignalGroupTriggerHistory` using its globally unique `ID`. */
  signalGroupTriggerHistory?: Maybe<SignalGroupTriggerHistory>;
  signalGroupTriggerHistoryById?: Maybe<SignalGroupTriggerHistory>;
  /** Reads a single `SignalHistory` using its globally unique `ID`. */
  signalHistory?: Maybe<SignalHistory>;
  signalHistoryById?: Maybe<SignalHistory>;
  /** Reads a single `User` using its globally unique `ID`. */
  user?: Maybe<User>;
  userById?: Maybe<User>;
  userByUsername?: Maybe<User>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSignalGroupActionTriggerHistoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupActionTriggerHistoriesOrderBy>>;
  condition?: Maybe<SignalGroupActionTriggerHistoryCondition>;
  filter?: Maybe<SignalGroupActionTriggerHistoryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSignalGroupActionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupActionsOrderBy>>;
  condition?: Maybe<SignalGroupActionCondition>;
  filter?: Maybe<SignalGroupActionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSignalGroupCurrentInstancesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupCurrentInstancesOrderBy>>;
  condition?: Maybe<SignalGroupCurrentInstanceCondition>;
  filter?: Maybe<SignalGroupCurrentInstanceFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSignalGroupInstanceHistoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupInstanceHistoriesOrderBy>>;
  condition?: Maybe<SignalGroupInstanceHistoryCondition>;
  filter?: Maybe<SignalGroupInstanceHistoryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSignalGroupInstancesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupInstancesOrderBy>>;
  condition?: Maybe<SignalGroupInstanceCondition>;
  filter?: Maybe<SignalGroupInstanceFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSignalGroupItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupItemsOrderBy>>;
  condition?: Maybe<SignalGroupItemCondition>;
  filter?: Maybe<SignalGroupItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSignalGroupTriggerHistoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupTriggerHistoriesOrderBy>>;
  condition?: Maybe<SignalGroupTriggerHistoryCondition>;
  filter?: Maybe<SignalGroupTriggerHistoryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSignalGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupsOrderBy>>;
  condition?: Maybe<SignalGroupCondition>;
  filter?: Maybe<SignalGroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSignalHistoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalHistoriesOrderBy>>;
  condition?: Maybe<SignalHistoryCondition>;
  filter?: Maybe<SignalHistoryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSignalsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalsOrderBy>>;
  condition?: Maybe<SignalCondition>;
  filter?: Maybe<SignalFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthArgs = {
  test?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalAndSignalGroupRelationshipArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<SignalAndSignalGroupRelationshipFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalByExternalIdArgs = {
  externalId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupActionArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupActionByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupActionTriggerHistoryArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupActionTriggerHistoryByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupCurrentInstanceArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupCurrentInstanceByGroupIdArgs = {
  groupId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupInstanceArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupInstanceByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupInstanceHistoryArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupInstanceHistoryByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupItemArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupItemByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupTriggerHistoryArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalGroupTriggerHistoryByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalHistoryArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySignalHistoryByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByUsernameArgs = {
  username: Scalars['String'];
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** A connection to a list of `Signal` values. */
export type SignalsConnection = {
  __typename?: 'SignalsConnection';
  /** A list of `Signal` objects. */
  nodes: Array<Signal>;
  /** A list of edges which contains the `Signal` and cursor to aid in pagination. */
  edges: Array<SignalsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Signal` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type Signal = Node & {
  __typename?: 'Signal';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  externalId: Scalars['UUID'];
  userId: Scalars['UUID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  runningAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  customTriggerTemplate?: Maybe<Scalars['JSON']>;
  /** Reads a single `User` that is related to this `Signal`. */
  userByUserId?: Maybe<User>;
  /** Reads and enables pagination through a set of `SignalHistory`. */
  signalHistoriesBySignalId: SignalHistoriesConnection;
  /** Reads and enables pagination through a set of `SignalGroupItem`. */
  signalGroupItemsBySignalId: SignalGroupItemsConnection;
  lastHistory?: Maybe<SignalHistory>;
  /** Reads and enables pagination through a set of `SignalGroup`. */
  signalGroupsBySignalGroupItemSignalIdAndSignalGroupId: SignalSignalGroupsBySignalGroupItemSignalIdAndSignalGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `SignalHistory`. */
  signalHistoriesBySignalGroupItemSignalIdAndLastSignalHistoryId: SignalSignalHistoriesBySignalGroupItemSignalIdAndLastSignalHistoryIdManyToManyConnection;
};


export type SignalSignalHistoriesBySignalIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalHistoriesOrderBy>>;
  condition?: Maybe<SignalHistoryCondition>;
  filter?: Maybe<SignalHistoryFilter>;
};


export type SignalSignalGroupItemsBySignalIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupItemsOrderBy>>;
  condition?: Maybe<SignalGroupItemCondition>;
  filter?: Maybe<SignalGroupItemFilter>;
};


export type SignalSignalGroupsBySignalGroupItemSignalIdAndSignalGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupsOrderBy>>;
  condition?: Maybe<SignalGroupCondition>;
  filter?: Maybe<SignalGroupFilter>;
};


export type SignalSignalHistoriesBySignalGroupItemSignalIdAndLastSignalHistoryIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalHistoriesOrderBy>>;
  condition?: Maybe<SignalHistoryCondition>;
  filter?: Maybe<SignalHistoryFilter>;
};




/** A user who can log in to the application. */
export type User = Node & {
  __typename?: 'User';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Unique identifier for the user. */
  id: Scalars['UUID'];
  /** Public-facing username of the user. */
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: UserRoles;
  isActive: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `Signal`. */
  signalsByUserId: SignalsConnection;
  /** Reads and enables pagination through a set of `SignalGroup`. */
  signalGroupsByUserId: SignalGroupsConnection;
  name?: Maybe<Scalars['String']>;
};


/** A user who can log in to the application. */
export type UserSignalsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalsOrderBy>>;
  condition?: Maybe<SignalCondition>;
  filter?: Maybe<SignalFilter>;
};


/** A user who can log in to the application. */
export type UserSignalGroupsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupsOrderBy>>;
  condition?: Maybe<SignalGroupCondition>;
  filter?: Maybe<SignalGroupFilter>;
};

export enum UserRoles {
  AppUser = 'APP_USER'
}


/** Methods to use when ordering `Signal`. */
export enum SignalsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ExternalIdAsc = 'EXTERNAL_ID_ASC',
  ExternalIdDesc = 'EXTERNAL_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  RunningAtAsc = 'RUNNING_AT_ASC',
  RunningAtDesc = 'RUNNING_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CustomTriggerTemplateAsc = 'CUSTOM_TRIGGER_TEMPLATE_ASC',
  CustomTriggerTemplateDesc = 'CUSTOM_TRIGGER_TEMPLATE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A condition to be used against `Signal` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SignalCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `externalId` field. */
  externalId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `runningAt` field. */
  runningAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `customTriggerTemplate` field. */
  customTriggerTemplate?: Maybe<Scalars['JSON']>;
};

/** A filter to be used against `Signal` object types. All fields are combined with a logical ‘and.’ */
export type SignalFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `externalId` field. */
  externalId?: Maybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `runningAt` field. */
  runningAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `customTriggerTemplate` field. */
  customTriggerTemplate?: Maybe<JsonFilter>;
  /** Filter by the object’s `signalHistoriesBySignalId` relation. */
  signalHistoriesBySignalId?: Maybe<SignalToManySignalHistoryFilter>;
  /** Some related `signalHistoriesBySignalId` exist. */
  signalHistoriesBySignalIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `signalGroupItemsBySignalId` relation. */
  signalGroupItemsBySignalId?: Maybe<SignalToManySignalGroupItemFilter>;
  /** Some related `signalGroupItemsBySignalId` exist. */
  signalGroupItemsBySignalIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SignalFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SignalFilter>>;
  /** Negates the expression. */
  not?: Maybe<SignalFilter>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['UUID']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['UUID']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Boolean']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Boolean']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Boolean']>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Datetime']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Datetime']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['JSON']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['JSON']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['JSON']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  contains?: Maybe<Scalars['JSON']>;
  /** Contains the specified key. */
  containsKey?: Maybe<Scalars['String']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: Maybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: Maybe<Array<Scalars['String']>>;
  /** Contained by the specified JSON. */
  containedBy?: Maybe<Scalars['JSON']>;
};

/** A filter to be used against many `SignalHistory` object types. All fields are combined with a logical ‘and.’ */
export type SignalToManySignalHistoryFilter = {
  /** Every related `SignalHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SignalHistoryFilter>;
  /** Some related `SignalHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SignalHistoryFilter>;
  /** No related `SignalHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SignalHistoryFilter>;
};

/** A filter to be used against `SignalHistory` object types. All fields are combined with a logical ‘and.’ */
export type SignalHistoryFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `signalId` field. */
  signalId?: Maybe<UuidFilter>;
  /** Filter by the object’s `action` field. */
  action?: Maybe<SignalHistoryActionTypeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `message` field. */
  message?: Maybe<StringFilter>;
  /** Filter by the object’s `data` field. */
  data?: Maybe<JsonFilter>;
  /** Filter by the object’s `signalGroupItemsByLastSignalHistoryId` relation. */
  signalGroupItemsByLastSignalHistoryId?: Maybe<SignalHistoryToManySignalGroupItemFilter>;
  /** Some related `signalGroupItemsByLastSignalHistoryId` exist. */
  signalGroupItemsByLastSignalHistoryIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `signalBySignalId` relation. */
  signalBySignalId?: Maybe<SignalFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SignalHistoryFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SignalHistoryFilter>>;
  /** Negates the expression. */
  not?: Maybe<SignalHistoryFilter>;
};

/** A filter to be used against SignalHistoryActionType fields. All fields are combined with a logical ‘and.’ */
export type SignalHistoryActionTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<SignalHistoryActionType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<SignalHistoryActionType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<SignalHistoryActionType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<SignalHistoryActionType>;
  /** Included in the specified list. */
  in?: Maybe<Array<SignalHistoryActionType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<SignalHistoryActionType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<SignalHistoryActionType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<SignalHistoryActionType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<SignalHistoryActionType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<SignalHistoryActionType>;
};

export enum SignalHistoryActionType {
  TurnOn = 'TURN_ON',
  TurnOff = 'TURN_OFF',
  Toggle = 'TOGGLE'
}

/** A filter to be used against many `SignalGroupItem` object types. All fields are combined with a logical ‘and.’ */
export type SignalHistoryToManySignalGroupItemFilter = {
  /** Every related `SignalGroupItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SignalGroupItemFilter>;
  /** Some related `SignalGroupItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SignalGroupItemFilter>;
  /** No related `SignalGroupItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SignalGroupItemFilter>;
};

/** A filter to be used against `SignalGroupItem` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupItemFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `signalId` field. */
  signalId?: Maybe<UuidFilter>;
  /** Filter by the object’s `signalGroupId` field. */
  signalGroupId?: Maybe<UuidFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `lastSignalHistoryId` field. */
  lastSignalHistoryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `state` field. */
  state?: Maybe<SignalGroupItemStateFilter>;
  /** Filter by the object’s `isPerpetual` field. */
  isPerpetual?: Maybe<BooleanFilter>;
  /** Filter by the object’s `firstToggleActionValue` field. */
  firstToggleActionValue?: Maybe<SignalGroupItemFirstToggleActionValueFilter>;
  /** Filter by the object’s `cooldownAfterReceivedSignalAmount` field. */
  cooldownAfterReceivedSignalAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `cooldownAfterReceivedSignalInterval` field. */
  cooldownAfterReceivedSignalInterval?: Maybe<SignalGroupItemCooldownAfterReceivedSignalIntervalFilter>;
  /** Filter by the object’s `resetCooldownAfterGroupTrigger` field. */
  resetCooldownAfterGroupTrigger?: Maybe<BooleanFilter>;
  /** Filter by the object’s `reactivateAt` field. */
  reactivateAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `signalBySignalId` relation. */
  signalBySignalId?: Maybe<SignalFilter>;
  /** Filter by the object’s `signalGroupBySignalGroupId` relation. */
  signalGroupBySignalGroupId?: Maybe<SignalGroupFilter>;
  /** Filter by the object’s `signalHistoryByLastSignalHistoryId` relation. */
  signalHistoryByLastSignalHistoryId?: Maybe<SignalHistoryFilter>;
  /** A related `signalHistoryByLastSignalHistoryId` exists. */
  signalHistoryByLastSignalHistoryIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SignalGroupItemFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SignalGroupItemFilter>>;
  /** Negates the expression. */
  not?: Maybe<SignalGroupItemFilter>;
};

/** A filter to be used against SignalGroupItemState fields. All fields are combined with a logical ‘and.’ */
export type SignalGroupItemStateFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<SignalGroupItemState>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<SignalGroupItemState>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<SignalGroupItemState>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<SignalGroupItemState>;
  /** Included in the specified list. */
  in?: Maybe<Array<SignalGroupItemState>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<SignalGroupItemState>>;
  /** Less than the specified value. */
  lessThan?: Maybe<SignalGroupItemState>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<SignalGroupItemState>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<SignalGroupItemState>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<SignalGroupItemState>;
};

export enum SignalGroupItemState {
  On = 'ON',
  Off = 'OFF'
}

/** A filter to be used against SignalGroupItemFirstToggleActionValue fields. All fields are combined with a logical ‘and.’ */
export type SignalGroupItemFirstToggleActionValueFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<SignalGroupItemFirstToggleActionValue>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<SignalGroupItemFirstToggleActionValue>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<SignalGroupItemFirstToggleActionValue>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<SignalGroupItemFirstToggleActionValue>;
  /** Included in the specified list. */
  in?: Maybe<Array<SignalGroupItemFirstToggleActionValue>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<SignalGroupItemFirstToggleActionValue>>;
  /** Less than the specified value. */
  lessThan?: Maybe<SignalGroupItemFirstToggleActionValue>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<SignalGroupItemFirstToggleActionValue>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<SignalGroupItemFirstToggleActionValue>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<SignalGroupItemFirstToggleActionValue>;
};

export enum SignalGroupItemFirstToggleActionValue {
  On = 'ON',
  Off = 'OFF'
}

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Int']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Int']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
};

/** A filter to be used against SignalGroupItemCooldownAfterReceivedSignalInterval fields. All fields are combined with a logical ‘and.’ */
export type SignalGroupItemCooldownAfterReceivedSignalIntervalFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<SignalGroupItemCooldownAfterReceivedSignalInterval>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<SignalGroupItemCooldownAfterReceivedSignalInterval>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<SignalGroupItemCooldownAfterReceivedSignalInterval>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<SignalGroupItemCooldownAfterReceivedSignalInterval>;
  /** Included in the specified list. */
  in?: Maybe<Array<SignalGroupItemCooldownAfterReceivedSignalInterval>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<SignalGroupItemCooldownAfterReceivedSignalInterval>>;
  /** Less than the specified value. */
  lessThan?: Maybe<SignalGroupItemCooldownAfterReceivedSignalInterval>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<SignalGroupItemCooldownAfterReceivedSignalInterval>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<SignalGroupItemCooldownAfterReceivedSignalInterval>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<SignalGroupItemCooldownAfterReceivedSignalInterval>;
};

export enum SignalGroupItemCooldownAfterReceivedSignalInterval {
  Second = 'SECOND',
  Minute = 'MINUTE',
  Hour = 'HOUR',
  Day = 'DAY'
}

/** A filter to be used against `SignalGroup` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `runningAt` field. */
  runningAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `stopAfterTrigger` field. */
  stopAfterTrigger?: Maybe<BooleanFilter>;
  /** Filter by the object’s `cooldownAfterTriggerAmount` field. */
  cooldownAfterTriggerAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `cooldownAfterTriggerInterval` field. */
  cooldownAfterTriggerInterval?: Maybe<CooldownAfterTriggerIntervalFilter>;
  /** Filter by the object’s `isOnCooldown` field. */
  isOnCooldown?: Maybe<BooleanFilter>;
  /** Filter by the object’s `displayStatus` field. */
  displayStatus?: Maybe<SignalGroupDisplayStatusFilter>;
  /** Filter by the object’s `reactivateAt` field. */
  reactivateAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `isTriggering` field. */
  isTriggering?: Maybe<BooleanFilter>;
  /** Filter by the object’s `signalGroupActionsBySignalGroupId` relation. */
  signalGroupActionsBySignalGroupId?: Maybe<SignalGroupToManySignalGroupActionFilter>;
  /** Some related `signalGroupActionsBySignalGroupId` exist. */
  signalGroupActionsBySignalGroupIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `signalGroupTriggerHistoriesBySignalGroupId` relation. */
  signalGroupTriggerHistoriesBySignalGroupId?: Maybe<SignalGroupToManySignalGroupTriggerHistoryFilter>;
  /** Some related `signalGroupTriggerHistoriesBySignalGroupId` exist. */
  signalGroupTriggerHistoriesBySignalGroupIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `signalGroupItemsBySignalGroupId` relation. */
  signalGroupItemsBySignalGroupId?: Maybe<SignalGroupToManySignalGroupItemFilter>;
  /** Some related `signalGroupItemsBySignalGroupId` exist. */
  signalGroupItemsBySignalGroupIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `signalGroupInstancesBySignalGroupId` relation. */
  signalGroupInstancesBySignalGroupId?: Maybe<SignalGroupToManySignalGroupInstanceFilter>;
  /** Some related `signalGroupInstancesBySignalGroupId` exist. */
  signalGroupInstancesBySignalGroupIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `signalGroupCurrentInstanceByGroupId` relation. */
  signalGroupCurrentInstanceByGroupId?: Maybe<SignalGroupCurrentInstanceFilter>;
  /** A related `signalGroupCurrentInstanceByGroupId` exists. */
  signalGroupCurrentInstanceByGroupIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SignalGroupFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SignalGroupFilter>>;
  /** Negates the expression. */
  not?: Maybe<SignalGroupFilter>;
};

/** A filter to be used against CooldownAfterTriggerInterval fields. All fields are combined with a logical ‘and.’ */
export type CooldownAfterTriggerIntervalFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<CooldownAfterTriggerInterval>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<CooldownAfterTriggerInterval>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<CooldownAfterTriggerInterval>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<CooldownAfterTriggerInterval>;
  /** Included in the specified list. */
  in?: Maybe<Array<CooldownAfterTriggerInterval>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<CooldownAfterTriggerInterval>>;
  /** Less than the specified value. */
  lessThan?: Maybe<CooldownAfterTriggerInterval>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<CooldownAfterTriggerInterval>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<CooldownAfterTriggerInterval>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<CooldownAfterTriggerInterval>;
};

export enum CooldownAfterTriggerInterval {
  Minute = 'MINUTE',
  Hour = 'HOUR',
  Day = 'DAY'
}

/** A filter to be used against SignalGroupDisplayStatus fields. All fields are combined with a logical ‘and.’ */
export type SignalGroupDisplayStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<SignalGroupDisplayStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<SignalGroupDisplayStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<SignalGroupDisplayStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<SignalGroupDisplayStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<SignalGroupDisplayStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<SignalGroupDisplayStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<SignalGroupDisplayStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<SignalGroupDisplayStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<SignalGroupDisplayStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<SignalGroupDisplayStatus>;
};

export enum SignalGroupDisplayStatus {
  Active = 'ACTIVE',
  Stopped = 'STOPPED',
  OnCooldown = 'ON_COOLDOWN'
}

/** A filter to be used against many `SignalGroupAction` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupToManySignalGroupActionFilter = {
  /** Every related `SignalGroupAction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SignalGroupActionFilter>;
  /** Some related `SignalGroupAction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SignalGroupActionFilter>;
  /** No related `SignalGroupAction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SignalGroupActionFilter>;
};

/** A filter to be used against `SignalGroupAction` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupActionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `signalGroupId` field. */
  signalGroupId?: Maybe<UuidFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: Maybe<StringFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `triggerCount` field. */
  triggerCount?: Maybe<IntFilter>;
  /** Filter by the object’s `signalGroupActionTriggerHistoriesBySignalGroupActionId` relation. */
  signalGroupActionTriggerHistoriesBySignalGroupActionId?: Maybe<SignalGroupActionToManySignalGroupActionTriggerHistoryFilter>;
  /** Some related `signalGroupActionTriggerHistoriesBySignalGroupActionId` exist. */
  signalGroupActionTriggerHistoriesBySignalGroupActionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `signalGroupBySignalGroupId` relation. */
  signalGroupBySignalGroupId?: Maybe<SignalGroupFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SignalGroupActionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SignalGroupActionFilter>>;
  /** Negates the expression. */
  not?: Maybe<SignalGroupActionFilter>;
};

/** A filter to be used against many `SignalGroupActionTriggerHistory` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupActionToManySignalGroupActionTriggerHistoryFilter = {
  /** Every related `SignalGroupActionTriggerHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SignalGroupActionTriggerHistoryFilter>;
  /** Some related `SignalGroupActionTriggerHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SignalGroupActionTriggerHistoryFilter>;
  /** No related `SignalGroupActionTriggerHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SignalGroupActionTriggerHistoryFilter>;
};

/** A filter to be used against `SignalGroupActionTriggerHistory` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupActionTriggerHistoryFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `signalGroupActionId` field. */
  signalGroupActionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `result` field. */
  result?: Maybe<SignalGroupActionTriggerHistoryResultFilter>;
  /** Filter by the object’s `startAt` field. */
  startAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `endAt` field. */
  endAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `elapsed` field. */
  elapsed?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `signalGroupActionBySignalGroupActionId` relation. */
  signalGroupActionBySignalGroupActionId?: Maybe<SignalGroupActionFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SignalGroupActionTriggerHistoryFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SignalGroupActionTriggerHistoryFilter>>;
  /** Negates the expression. */
  not?: Maybe<SignalGroupActionTriggerHistoryFilter>;
};

/** A filter to be used against SignalGroupActionTriggerHistoryResult fields. All fields are combined with a logical ‘and.’ */
export type SignalGroupActionTriggerHistoryResultFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<SignalGroupActionTriggerHistoryResult>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<SignalGroupActionTriggerHistoryResult>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<SignalGroupActionTriggerHistoryResult>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<SignalGroupActionTriggerHistoryResult>;
  /** Included in the specified list. */
  in?: Maybe<Array<SignalGroupActionTriggerHistoryResult>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<SignalGroupActionTriggerHistoryResult>>;
  /** Less than the specified value. */
  lessThan?: Maybe<SignalGroupActionTriggerHistoryResult>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<SignalGroupActionTriggerHistoryResult>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<SignalGroupActionTriggerHistoryResult>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<SignalGroupActionTriggerHistoryResult>;
};

export enum SignalGroupActionTriggerHistoryResult {
  Ok = 'OK',
  Error = 'ERROR'
}

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['BigFloat']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['BigFloat']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['BigFloat']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['BigFloat']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['BigFloat']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['BigFloat']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['BigFloat']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['BigFloat']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
};


/** A filter to be used against many `SignalGroupTriggerHistory` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupToManySignalGroupTriggerHistoryFilter = {
  /** Every related `SignalGroupTriggerHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SignalGroupTriggerHistoryFilter>;
  /** Some related `SignalGroupTriggerHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SignalGroupTriggerHistoryFilter>;
  /** No related `SignalGroupTriggerHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SignalGroupTriggerHistoryFilter>;
};

/** A filter to be used against `SignalGroupTriggerHistory` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupTriggerHistoryFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `signalGroupId` field. */
  signalGroupId?: Maybe<UuidFilter>;
  /** Filter by the object’s `result` field. */
  result?: Maybe<SignalGroupTriggerHistoryResultFilter>;
  /** Filter by the object’s `startAt` field. */
  startAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `endAt` field. */
  endAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `elapsed` field. */
  elapsed?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `signalGroupBySignalGroupId` relation. */
  signalGroupBySignalGroupId?: Maybe<SignalGroupFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SignalGroupTriggerHistoryFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SignalGroupTriggerHistoryFilter>>;
  /** Negates the expression. */
  not?: Maybe<SignalGroupTriggerHistoryFilter>;
};

/** A filter to be used against SignalGroupTriggerHistoryResult fields. All fields are combined with a logical ‘and.’ */
export type SignalGroupTriggerHistoryResultFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<SignalGroupTriggerHistoryResult>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<SignalGroupTriggerHistoryResult>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<SignalGroupTriggerHistoryResult>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<SignalGroupTriggerHistoryResult>;
  /** Included in the specified list. */
  in?: Maybe<Array<SignalGroupTriggerHistoryResult>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<SignalGroupTriggerHistoryResult>>;
  /** Less than the specified value. */
  lessThan?: Maybe<SignalGroupTriggerHistoryResult>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<SignalGroupTriggerHistoryResult>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<SignalGroupTriggerHistoryResult>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<SignalGroupTriggerHistoryResult>;
};

export enum SignalGroupTriggerHistoryResult {
  Ok = 'OK',
  Error = 'ERROR'
}

/** A filter to be used against many `SignalGroupItem` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupToManySignalGroupItemFilter = {
  /** Every related `SignalGroupItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SignalGroupItemFilter>;
  /** Some related `SignalGroupItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SignalGroupItemFilter>;
  /** No related `SignalGroupItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SignalGroupItemFilter>;
};

/** A filter to be used against many `SignalGroupInstance` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupToManySignalGroupInstanceFilter = {
  /** Every related `SignalGroupInstance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SignalGroupInstanceFilter>;
  /** Some related `SignalGroupInstance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SignalGroupInstanceFilter>;
  /** No related `SignalGroupInstance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SignalGroupInstanceFilter>;
};

/** A filter to be used against `SignalGroupInstance` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupInstanceFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `signalGroupId` field. */
  signalGroupId?: Maybe<UuidFilter>;
  /** Filter by the object’s `startAt` field. */
  startAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `endAt` field. */
  endAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `elapsed` field. */
  elapsed?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `isCurrent` field. */
  isCurrent?: Maybe<BooleanFilter>;
  /** Filter by the object’s `signalGroupCurrentInstancesByInstanceId` relation. */
  signalGroupCurrentInstancesByInstanceId?: Maybe<SignalGroupInstanceToManySignalGroupCurrentInstanceFilter>;
  /** Some related `signalGroupCurrentInstancesByInstanceId` exist. */
  signalGroupCurrentInstancesByInstanceIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `signalGroupInstanceHistoriesByInstanceId` relation. */
  signalGroupInstanceHistoriesByInstanceId?: Maybe<SignalGroupInstanceToManySignalGroupInstanceHistoryFilter>;
  /** Some related `signalGroupInstanceHistoriesByInstanceId` exist. */
  signalGroupInstanceHistoriesByInstanceIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `signalGroupBySignalGroupId` relation. */
  signalGroupBySignalGroupId?: Maybe<SignalGroupFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SignalGroupInstanceFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SignalGroupInstanceFilter>>;
  /** Negates the expression. */
  not?: Maybe<SignalGroupInstanceFilter>;
};

/** A filter to be used against many `SignalGroupCurrentInstance` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupInstanceToManySignalGroupCurrentInstanceFilter = {
  /** Every related `SignalGroupCurrentInstance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SignalGroupCurrentInstanceFilter>;
  /** Some related `SignalGroupCurrentInstance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SignalGroupCurrentInstanceFilter>;
  /** No related `SignalGroupCurrentInstance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SignalGroupCurrentInstanceFilter>;
};

/** A filter to be used against `SignalGroupCurrentInstance` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupCurrentInstanceFilter = {
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<UuidFilter>;
  /** Filter by the object’s `instanceId` field. */
  instanceId?: Maybe<UuidFilter>;
  /** Filter by the object’s `signalGroupByGroupId` relation. */
  signalGroupByGroupId?: Maybe<SignalGroupFilter>;
  /** Filter by the object’s `signalGroupInstanceByInstanceId` relation. */
  signalGroupInstanceByInstanceId?: Maybe<SignalGroupInstanceFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SignalGroupCurrentInstanceFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SignalGroupCurrentInstanceFilter>>;
  /** Negates the expression. */
  not?: Maybe<SignalGroupCurrentInstanceFilter>;
};

/** A filter to be used against many `SignalGroupInstanceHistory` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupInstanceToManySignalGroupInstanceHistoryFilter = {
  /** Every related `SignalGroupInstanceHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SignalGroupInstanceHistoryFilter>;
  /** Some related `SignalGroupInstanceHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SignalGroupInstanceHistoryFilter>;
  /** No related `SignalGroupInstanceHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SignalGroupInstanceHistoryFilter>;
};

/** A filter to be used against `SignalGroupInstanceHistory` object types. All fields are combined with a logical ‘and.’ */
export type SignalGroupInstanceHistoryFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `instanceId` field. */
  instanceId?: Maybe<UuidFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<SignalGroupInstanceHistoryTypeFilter>;
  /** Filter by the object’s `description` field. */
  description?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `extra` field. */
  extra?: Maybe<JsonFilter>;
  /** Filter by the object’s `signalGroupInstanceByInstanceId` relation. */
  signalGroupInstanceByInstanceId?: Maybe<SignalGroupInstanceFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SignalGroupInstanceHistoryFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SignalGroupInstanceHistoryFilter>>;
  /** Negates the expression. */
  not?: Maybe<SignalGroupInstanceHistoryFilter>;
};

/** A filter to be used against SignalGroupInstanceHistoryType fields. All fields are combined with a logical ‘and.’ */
export type SignalGroupInstanceHistoryTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<SignalGroupInstanceHistoryType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<SignalGroupInstanceHistoryType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<SignalGroupInstanceHistoryType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<SignalGroupInstanceHistoryType>;
  /** Included in the specified list. */
  in?: Maybe<Array<SignalGroupInstanceHistoryType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<SignalGroupInstanceHistoryType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<SignalGroupInstanceHistoryType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<SignalGroupInstanceHistoryType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<SignalGroupInstanceHistoryType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<SignalGroupInstanceHistoryType>;
};

export enum SignalGroupInstanceHistoryType {
  Begin = 'BEGIN',
  End = 'END',
  StartGroup = 'START_GROUP',
  StopGroup = 'STOP_GROUP',
  StopSignal = 'STOP_SIGNAL',
  StartSignal = 'START_SIGNAL',
  SignalReceived = 'SIGNAL_RECEIVED',
  OnCooldown = 'ON_COOLDOWN',
  OffCooldown = 'OFF_COOLDOWN',
  GroupTriggerFinish = 'GROUP_TRIGGER_FINISH',
  PerpetualSignal = 'PERPETUAL_SIGNAL',
  FinalSignalState = 'FINAL_SIGNAL_STATE'
}

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `username` field. */
  username?: Maybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: Maybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: Maybe<StringFilter>;
  /** Filter by the object’s `role` field. */
  role?: Maybe<UserRolesFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `signalsByUserId` relation. */
  signalsByUserId?: Maybe<UserToManySignalFilter>;
  /** Some related `signalsByUserId` exist. */
  signalsByUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `signalGroupsByUserId` relation. */
  signalGroupsByUserId?: Maybe<UserToManySignalGroupFilter>;
  /** Some related `signalGroupsByUserId` exist. */
  signalGroupsByUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserFilter>;
};

/** A filter to be used against UserRoles fields. All fields are combined with a logical ‘and.’ */
export type UserRolesFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<UserRoles>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<UserRoles>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<UserRoles>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<UserRoles>;
  /** Included in the specified list. */
  in?: Maybe<Array<UserRoles>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<UserRoles>>;
  /** Less than the specified value. */
  lessThan?: Maybe<UserRoles>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<UserRoles>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<UserRoles>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<UserRoles>;
};

/** A filter to be used against many `Signal` object types. All fields are combined with a logical ‘and.’ */
export type UserToManySignalFilter = {
  /** Every related `Signal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SignalFilter>;
  /** Some related `Signal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SignalFilter>;
  /** No related `Signal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SignalFilter>;
};

/** A filter to be used against many `SignalGroup` object types. All fields are combined with a logical ‘and.’ */
export type UserToManySignalGroupFilter = {
  /** Every related `SignalGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SignalGroupFilter>;
  /** Some related `SignalGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SignalGroupFilter>;
  /** No related `SignalGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SignalGroupFilter>;
};

/** A filter to be used against many `SignalGroupItem` object types. All fields are combined with a logical ‘and.’ */
export type SignalToManySignalGroupItemFilter = {
  /** Every related `SignalGroupItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<SignalGroupItemFilter>;
  /** Some related `SignalGroupItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<SignalGroupItemFilter>;
  /** No related `SignalGroupItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<SignalGroupItemFilter>;
};

/** A connection to a list of `SignalGroup` values. */
export type SignalGroupsConnection = {
  __typename?: 'SignalGroupsConnection';
  /** A list of `SignalGroup` objects. */
  nodes: Array<SignalGroup>;
  /** A list of edges which contains the `SignalGroup` and cursor to aid in pagination. */
  edges: Array<SignalGroupsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SignalGroup = Node & {
  __typename?: 'SignalGroup';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  runningAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  stopAfterTrigger: Scalars['Boolean'];
  cooldownAfterTriggerAmount?: Maybe<Scalars['Int']>;
  cooldownAfterTriggerInterval?: Maybe<CooldownAfterTriggerInterval>;
  isOnCooldown: Scalars['Boolean'];
  displayStatus?: Maybe<SignalGroupDisplayStatus>;
  reactivateAt?: Maybe<Scalars['Datetime']>;
  isTriggering: Scalars['Boolean'];
  /** Reads a single `User` that is related to this `SignalGroup`. */
  userByUserId?: Maybe<User>;
  /** Reads and enables pagination through a set of `SignalGroupAction`. */
  signalGroupActionsBySignalGroupId: SignalGroupActionsConnection;
  /** Reads and enables pagination through a set of `SignalGroupTriggerHistory`. */
  signalGroupTriggerHistoriesBySignalGroupId: SignalGroupTriggerHistoriesConnection;
  /** Reads and enables pagination through a set of `SignalGroupItem`. */
  signalGroupItemsBySignalGroupId: SignalGroupItemsConnection;
  /** Reads and enables pagination through a set of `SignalGroupInstance`. */
  signalGroupInstancesBySignalGroupId: SignalGroupInstancesConnection;
  /** Reads a single `SignalGroupCurrentInstance` that is related to this `SignalGroup`. */
  signalGroupCurrentInstanceByGroupId?: Maybe<SignalGroupCurrentInstance>;
  currentInstance?: Maybe<SignalGroupInstance>;
  lastTrigger?: Maybe<SignalGroupTriggerHistory>;
  /** Reads and enables pagination through a set of `Signal`. */
  signalsBySignalGroupItemSignalGroupIdAndSignalId: SignalGroupSignalsBySignalGroupItemSignalGroupIdAndSignalIdManyToManyConnection;
  /** Reads and enables pagination through a set of `SignalHistory`. */
  signalHistoriesBySignalGroupItemSignalGroupIdAndLastSignalHistoryId: SignalGroupSignalHistoriesBySignalGroupItemSignalGroupIdAndLastSignalHistoryIdManyToManyConnection;
};


export type SignalGroupSignalGroupActionsBySignalGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupActionsOrderBy>>;
  condition?: Maybe<SignalGroupActionCondition>;
  filter?: Maybe<SignalGroupActionFilter>;
};


export type SignalGroupSignalGroupTriggerHistoriesBySignalGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupTriggerHistoriesOrderBy>>;
  condition?: Maybe<SignalGroupTriggerHistoryCondition>;
  filter?: Maybe<SignalGroupTriggerHistoryFilter>;
};


export type SignalGroupSignalGroupItemsBySignalGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupItemsOrderBy>>;
  condition?: Maybe<SignalGroupItemCondition>;
  filter?: Maybe<SignalGroupItemFilter>;
};


export type SignalGroupSignalGroupInstancesBySignalGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupInstancesOrderBy>>;
  condition?: Maybe<SignalGroupInstanceCondition>;
  filter?: Maybe<SignalGroupInstanceFilter>;
};


export type SignalGroupSignalsBySignalGroupItemSignalGroupIdAndSignalIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalsOrderBy>>;
  condition?: Maybe<SignalCondition>;
  filter?: Maybe<SignalFilter>;
};


export type SignalGroupSignalHistoriesBySignalGroupItemSignalGroupIdAndLastSignalHistoryIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalHistoriesOrderBy>>;
  condition?: Maybe<SignalHistoryCondition>;
  filter?: Maybe<SignalHistoryFilter>;
};

/** A connection to a list of `SignalGroupAction` values. */
export type SignalGroupActionsConnection = {
  __typename?: 'SignalGroupActionsConnection';
  /** A list of `SignalGroupAction` objects. */
  nodes: Array<SignalGroupAction>;
  /** A list of edges which contains the `SignalGroupAction` and cursor to aid in pagination. */
  edges: Array<SignalGroupActionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalGroupAction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SignalGroupAction = Node & {
  __typename?: 'SignalGroupAction';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  signalGroupId: Scalars['UUID'];
  payload?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  /** Reads a single `SignalGroup` that is related to this `SignalGroupAction`. */
  signalGroupBySignalGroupId?: Maybe<SignalGroup>;
  /** Reads and enables pagination through a set of `SignalGroupActionTriggerHistory`. */
  signalGroupActionTriggerHistoriesBySignalGroupActionId: SignalGroupActionTriggerHistoriesConnection;
  triggerCount?: Maybe<Scalars['Int']>;
};


export type SignalGroupActionSignalGroupActionTriggerHistoriesBySignalGroupActionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupActionTriggerHistoriesOrderBy>>;
  condition?: Maybe<SignalGroupActionTriggerHistoryCondition>;
  filter?: Maybe<SignalGroupActionTriggerHistoryFilter>;
};

/** A connection to a list of `SignalGroupActionTriggerHistory` values. */
export type SignalGroupActionTriggerHistoriesConnection = {
  __typename?: 'SignalGroupActionTriggerHistoriesConnection';
  /** A list of `SignalGroupActionTriggerHistory` objects. */
  nodes: Array<SignalGroupActionTriggerHistory>;
  /** A list of edges which contains the `SignalGroupActionTriggerHistory` and cursor to aid in pagination. */
  edges: Array<SignalGroupActionTriggerHistoriesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalGroupActionTriggerHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SignalGroupActionTriggerHistory = Node & {
  __typename?: 'SignalGroupActionTriggerHistory';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  signalGroupActionId: Scalars['UUID'];
  result?: Maybe<SignalGroupActionTriggerHistoryResult>;
  startAt: Scalars['Datetime'];
  endAt?: Maybe<Scalars['Datetime']>;
  elapsed?: Maybe<Scalars['BigFloat']>;
  /** Reads a single `SignalGroupAction` that is related to this `SignalGroupActionTriggerHistory`. */
  signalGroupActionBySignalGroupActionId?: Maybe<SignalGroupAction>;
};

/** A `SignalGroupActionTriggerHistory` edge in the connection. */
export type SignalGroupActionTriggerHistoriesEdge = {
  __typename?: 'SignalGroupActionTriggerHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignalGroupActionTriggerHistory` at the end of the edge. */
  node: SignalGroupActionTriggerHistory;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
};

/** Methods to use when ordering `SignalGroupActionTriggerHistory`. */
export enum SignalGroupActionTriggerHistoriesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SignalGroupActionIdAsc = 'SIGNAL_GROUP_ACTION_ID_ASC',
  SignalGroupActionIdDesc = 'SIGNAL_GROUP_ACTION_ID_DESC',
  ResultAsc = 'RESULT_ASC',
  ResultDesc = 'RESULT_DESC',
  StartAtAsc = 'START_AT_ASC',
  StartAtDesc = 'START_AT_DESC',
  EndAtAsc = 'END_AT_ASC',
  EndAtDesc = 'END_AT_DESC',
  ElapsedAsc = 'ELAPSED_ASC',
  ElapsedDesc = 'ELAPSED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SignalGroupActionTriggerHistory` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type SignalGroupActionTriggerHistoryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `signalGroupActionId` field. */
  signalGroupActionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `result` field. */
  result?: Maybe<SignalGroupActionTriggerHistoryResult>;
  /** Checks for equality with the object’s `startAt` field. */
  startAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `endAt` field. */
  endAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `elapsed` field. */
  elapsed?: Maybe<Scalars['BigFloat']>;
};

/** A `SignalGroupAction` edge in the connection. */
export type SignalGroupActionsEdge = {
  __typename?: 'SignalGroupActionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignalGroupAction` at the end of the edge. */
  node: SignalGroupAction;
};

/** Methods to use when ordering `SignalGroupAction`. */
export enum SignalGroupActionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SignalGroupIdAsc = 'SIGNAL_GROUP_ID_ASC',
  SignalGroupIdDesc = 'SIGNAL_GROUP_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SignalGroupAction` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SignalGroupActionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `signalGroupId` field. */
  signalGroupId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `SignalGroupTriggerHistory` values. */
export type SignalGroupTriggerHistoriesConnection = {
  __typename?: 'SignalGroupTriggerHistoriesConnection';
  /** A list of `SignalGroupTriggerHistory` objects. */
  nodes: Array<SignalGroupTriggerHistory>;
  /** A list of edges which contains the `SignalGroupTriggerHistory` and cursor to aid in pagination. */
  edges: Array<SignalGroupTriggerHistoriesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalGroupTriggerHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SignalGroupTriggerHistory = Node & {
  __typename?: 'SignalGroupTriggerHistory';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  signalGroupId: Scalars['UUID'];
  result?: Maybe<SignalGroupTriggerHistoryResult>;
  startAt: Scalars['Datetime'];
  endAt?: Maybe<Scalars['Datetime']>;
  elapsed?: Maybe<Scalars['BigFloat']>;
  /** Reads a single `SignalGroup` that is related to this `SignalGroupTriggerHistory`. */
  signalGroupBySignalGroupId?: Maybe<SignalGroup>;
};

/** A `SignalGroupTriggerHistory` edge in the connection. */
export type SignalGroupTriggerHistoriesEdge = {
  __typename?: 'SignalGroupTriggerHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignalGroupTriggerHistory` at the end of the edge. */
  node: SignalGroupTriggerHistory;
};

/** Methods to use when ordering `SignalGroupTriggerHistory`. */
export enum SignalGroupTriggerHistoriesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SignalGroupIdAsc = 'SIGNAL_GROUP_ID_ASC',
  SignalGroupIdDesc = 'SIGNAL_GROUP_ID_DESC',
  ResultAsc = 'RESULT_ASC',
  ResultDesc = 'RESULT_DESC',
  StartAtAsc = 'START_AT_ASC',
  StartAtDesc = 'START_AT_DESC',
  EndAtAsc = 'END_AT_ASC',
  EndAtDesc = 'END_AT_DESC',
  ElapsedAsc = 'ELAPSED_ASC',
  ElapsedDesc = 'ELAPSED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SignalGroupTriggerHistory` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type SignalGroupTriggerHistoryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `signalGroupId` field. */
  signalGroupId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `result` field. */
  result?: Maybe<SignalGroupTriggerHistoryResult>;
  /** Checks for equality with the object’s `startAt` field. */
  startAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `endAt` field. */
  endAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `elapsed` field. */
  elapsed?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `SignalGroupItem` values. */
export type SignalGroupItemsConnection = {
  __typename?: 'SignalGroupItemsConnection';
  /** A list of `SignalGroupItem` objects. */
  nodes: Array<SignalGroupItem>;
  /** A list of edges which contains the `SignalGroupItem` and cursor to aid in pagination. */
  edges: Array<SignalGroupItemsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalGroupItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SignalGroupItem = Node & {
  __typename?: 'SignalGroupItem';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  signalId: Scalars['UUID'];
  signalGroupId: Scalars['UUID'];
  isActive: Scalars['Boolean'];
  lastSignalHistoryId?: Maybe<Scalars['UUID']>;
  state?: Maybe<SignalGroupItemState>;
  isPerpetual: Scalars['Boolean'];
  firstToggleActionValue?: Maybe<SignalGroupItemFirstToggleActionValue>;
  cooldownAfterReceivedSignalAmount?: Maybe<Scalars['Int']>;
  cooldownAfterReceivedSignalInterval?: Maybe<SignalGroupItemCooldownAfterReceivedSignalInterval>;
  resetCooldownAfterGroupTrigger?: Maybe<Scalars['Boolean']>;
  reactivateAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Signal` that is related to this `SignalGroupItem`. */
  signalBySignalId?: Maybe<Signal>;
  /** Reads a single `SignalGroup` that is related to this `SignalGroupItem`. */
  signalGroupBySignalGroupId?: Maybe<SignalGroup>;
  /** Reads a single `SignalHistory` that is related to this `SignalGroupItem`. */
  signalHistoryByLastSignalHistoryId?: Maybe<SignalHistory>;
};

export type SignalHistory = Node & {
  __typename?: 'SignalHistory';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  signalId: Scalars['UUID'];
  action: SignalHistoryActionType;
  createdAt?: Maybe<Scalars['Datetime']>;
  message?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  /** Reads a single `Signal` that is related to this `SignalHistory`. */
  signalBySignalId?: Maybe<Signal>;
  /** Reads and enables pagination through a set of `SignalGroupItem`. */
  signalGroupItemsByLastSignalHistoryId: SignalGroupItemsConnection;
  /** Reads and enables pagination through a set of `Signal`. */
  signalsBySignalGroupItemLastSignalHistoryIdAndSignalId: SignalHistorySignalsBySignalGroupItemLastSignalHistoryIdAndSignalIdManyToManyConnection;
  /** Reads and enables pagination through a set of `SignalGroup`. */
  signalGroupsBySignalGroupItemLastSignalHistoryIdAndSignalGroupId: SignalHistorySignalGroupsBySignalGroupItemLastSignalHistoryIdAndSignalGroupIdManyToManyConnection;
};


export type SignalHistorySignalGroupItemsByLastSignalHistoryIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupItemsOrderBy>>;
  condition?: Maybe<SignalGroupItemCondition>;
  filter?: Maybe<SignalGroupItemFilter>;
};


export type SignalHistorySignalsBySignalGroupItemLastSignalHistoryIdAndSignalIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalsOrderBy>>;
  condition?: Maybe<SignalCondition>;
  filter?: Maybe<SignalFilter>;
};


export type SignalHistorySignalGroupsBySignalGroupItemLastSignalHistoryIdAndSignalGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupsOrderBy>>;
  condition?: Maybe<SignalGroupCondition>;
  filter?: Maybe<SignalGroupFilter>;
};

/** Methods to use when ordering `SignalGroupItem`. */
export enum SignalGroupItemsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SignalIdAsc = 'SIGNAL_ID_ASC',
  SignalIdDesc = 'SIGNAL_ID_DESC',
  SignalGroupIdAsc = 'SIGNAL_GROUP_ID_ASC',
  SignalGroupIdDesc = 'SIGNAL_GROUP_ID_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  LastSignalHistoryIdAsc = 'LAST_SIGNAL_HISTORY_ID_ASC',
  LastSignalHistoryIdDesc = 'LAST_SIGNAL_HISTORY_ID_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  IsPerpetualAsc = 'IS_PERPETUAL_ASC',
  IsPerpetualDesc = 'IS_PERPETUAL_DESC',
  FirstToggleActionValueAsc = 'FIRST_TOGGLE_ACTION_VALUE_ASC',
  FirstToggleActionValueDesc = 'FIRST_TOGGLE_ACTION_VALUE_DESC',
  CooldownAfterReceivedSignalAmountAsc = 'COOLDOWN_AFTER_RECEIVED_SIGNAL_AMOUNT_ASC',
  CooldownAfterReceivedSignalAmountDesc = 'COOLDOWN_AFTER_RECEIVED_SIGNAL_AMOUNT_DESC',
  CooldownAfterReceivedSignalIntervalAsc = 'COOLDOWN_AFTER_RECEIVED_SIGNAL_INTERVAL_ASC',
  CooldownAfterReceivedSignalIntervalDesc = 'COOLDOWN_AFTER_RECEIVED_SIGNAL_INTERVAL_DESC',
  ResetCooldownAfterGroupTriggerAsc = 'RESET_COOLDOWN_AFTER_GROUP_TRIGGER_ASC',
  ResetCooldownAfterGroupTriggerDesc = 'RESET_COOLDOWN_AFTER_GROUP_TRIGGER_DESC',
  ReactivateAtAsc = 'REACTIVATE_AT_ASC',
  ReactivateAtDesc = 'REACTIVATE_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SignalGroupItem` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SignalGroupItemCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `signalId` field. */
  signalId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `signalGroupId` field. */
  signalGroupId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `lastSignalHistoryId` field. */
  lastSignalHistoryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `state` field. */
  state?: Maybe<SignalGroupItemState>;
  /** Checks for equality with the object’s `isPerpetual` field. */
  isPerpetual?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `firstToggleActionValue` field. */
  firstToggleActionValue?: Maybe<SignalGroupItemFirstToggleActionValue>;
  /** Checks for equality with the object’s `cooldownAfterReceivedSignalAmount` field. */
  cooldownAfterReceivedSignalAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `cooldownAfterReceivedSignalInterval` field. */
  cooldownAfterReceivedSignalInterval?: Maybe<SignalGroupItemCooldownAfterReceivedSignalInterval>;
  /** Checks for equality with the object’s `resetCooldownAfterGroupTrigger` field. */
  resetCooldownAfterGroupTrigger?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `reactivateAt` field. */
  reactivateAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Signal` values, with data from `SignalGroupItem`. */
export type SignalHistorySignalsBySignalGroupItemLastSignalHistoryIdAndSignalIdManyToManyConnection = {
  __typename?: 'SignalHistorySignalsBySignalGroupItemLastSignalHistoryIdAndSignalIdManyToManyConnection';
  /** A list of `Signal` objects. */
  nodes: Array<Signal>;
  /** A list of edges which contains the `Signal`, info from the `SignalGroupItem`, and the cursor to aid in pagination. */
  edges: Array<SignalHistorySignalsBySignalGroupItemLastSignalHistoryIdAndSignalIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Signal` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Signal` edge in the connection, with data from `SignalGroupItem`. */
export type SignalHistorySignalsBySignalGroupItemLastSignalHistoryIdAndSignalIdManyToManyEdge = {
  __typename?: 'SignalHistorySignalsBySignalGroupItemLastSignalHistoryIdAndSignalIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Signal` at the end of the edge. */
  node: Signal;
  /** Reads and enables pagination through a set of `SignalGroupItem`. */
  signalGroupItemsBySignalId: SignalGroupItemsConnection;
};


/** A `Signal` edge in the connection, with data from `SignalGroupItem`. */
export type SignalHistorySignalsBySignalGroupItemLastSignalHistoryIdAndSignalIdManyToManyEdgeSignalGroupItemsBySignalIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupItemsOrderBy>>;
  condition?: Maybe<SignalGroupItemCondition>;
  filter?: Maybe<SignalGroupItemFilter>;
};

/** A connection to a list of `SignalGroup` values, with data from `SignalGroupItem`. */
export type SignalHistorySignalGroupsBySignalGroupItemLastSignalHistoryIdAndSignalGroupIdManyToManyConnection = {
  __typename?: 'SignalHistorySignalGroupsBySignalGroupItemLastSignalHistoryIdAndSignalGroupIdManyToManyConnection';
  /** A list of `SignalGroup` objects. */
  nodes: Array<SignalGroup>;
  /** A list of edges which contains the `SignalGroup`, info from the `SignalGroupItem`, and the cursor to aid in pagination. */
  edges: Array<SignalHistorySignalGroupsBySignalGroupItemLastSignalHistoryIdAndSignalGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SignalGroup` edge in the connection, with data from `SignalGroupItem`. */
export type SignalHistorySignalGroupsBySignalGroupItemLastSignalHistoryIdAndSignalGroupIdManyToManyEdge = {
  __typename?: 'SignalHistorySignalGroupsBySignalGroupItemLastSignalHistoryIdAndSignalGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignalGroup` at the end of the edge. */
  node: SignalGroup;
  /** Reads and enables pagination through a set of `SignalGroupItem`. */
  signalGroupItemsBySignalGroupId: SignalGroupItemsConnection;
};


/** A `SignalGroup` edge in the connection, with data from `SignalGroupItem`. */
export type SignalHistorySignalGroupsBySignalGroupItemLastSignalHistoryIdAndSignalGroupIdManyToManyEdgeSignalGroupItemsBySignalGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupItemsOrderBy>>;
  condition?: Maybe<SignalGroupItemCondition>;
  filter?: Maybe<SignalGroupItemFilter>;
};

/** Methods to use when ordering `SignalGroup`. */
export enum SignalGroupsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  RunningAtAsc = 'RUNNING_AT_ASC',
  RunningAtDesc = 'RUNNING_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  StopAfterTriggerAsc = 'STOP_AFTER_TRIGGER_ASC',
  StopAfterTriggerDesc = 'STOP_AFTER_TRIGGER_DESC',
  CooldownAfterTriggerAmountAsc = 'COOLDOWN_AFTER_TRIGGER_AMOUNT_ASC',
  CooldownAfterTriggerAmountDesc = 'COOLDOWN_AFTER_TRIGGER_AMOUNT_DESC',
  CooldownAfterTriggerIntervalAsc = 'COOLDOWN_AFTER_TRIGGER_INTERVAL_ASC',
  CooldownAfterTriggerIntervalDesc = 'COOLDOWN_AFTER_TRIGGER_INTERVAL_DESC',
  IsOnCooldownAsc = 'IS_ON_COOLDOWN_ASC',
  IsOnCooldownDesc = 'IS_ON_COOLDOWN_DESC',
  DisplayStatusAsc = 'DISPLAY_STATUS_ASC',
  DisplayStatusDesc = 'DISPLAY_STATUS_DESC',
  ReactivateAtAsc = 'REACTIVATE_AT_ASC',
  ReactivateAtDesc = 'REACTIVATE_AT_DESC',
  IsTriggeringAsc = 'IS_TRIGGERING_ASC',
  IsTriggeringDesc = 'IS_TRIGGERING_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SignalGroup` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SignalGroupCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `runningAt` field. */
  runningAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `stopAfterTrigger` field. */
  stopAfterTrigger?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `cooldownAfterTriggerAmount` field. */
  cooldownAfterTriggerAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `cooldownAfterTriggerInterval` field. */
  cooldownAfterTriggerInterval?: Maybe<CooldownAfterTriggerInterval>;
  /** Checks for equality with the object’s `isOnCooldown` field. */
  isOnCooldown?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `displayStatus` field. */
  displayStatus?: Maybe<SignalGroupDisplayStatus>;
  /** Checks for equality with the object’s `reactivateAt` field. */
  reactivateAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `isTriggering` field. */
  isTriggering?: Maybe<Scalars['Boolean']>;
};

/** A `SignalGroupItem` edge in the connection. */
export type SignalGroupItemsEdge = {
  __typename?: 'SignalGroupItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignalGroupItem` at the end of the edge. */
  node: SignalGroupItem;
};

/** A connection to a list of `SignalGroupInstance` values. */
export type SignalGroupInstancesConnection = {
  __typename?: 'SignalGroupInstancesConnection';
  /** A list of `SignalGroupInstance` objects. */
  nodes: Array<SignalGroupInstance>;
  /** A list of edges which contains the `SignalGroupInstance` and cursor to aid in pagination. */
  edges: Array<SignalGroupInstancesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalGroupInstance` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SignalGroupInstance = Node & {
  __typename?: 'SignalGroupInstance';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  signalGroupId: Scalars['UUID'];
  startAt: Scalars['Datetime'];
  endAt?: Maybe<Scalars['Datetime']>;
  elapsed?: Maybe<Scalars['BigFloat']>;
  /** Reads a single `SignalGroup` that is related to this `SignalGroupInstance`. */
  signalGroupBySignalGroupId?: Maybe<SignalGroup>;
  /** Reads and enables pagination through a set of `SignalGroupCurrentInstance`. */
  signalGroupCurrentInstancesByInstanceId: SignalGroupCurrentInstancesConnection;
  /** Reads and enables pagination through a set of `SignalGroupInstanceHistory`. */
  signalGroupInstanceHistoriesByInstanceId: SignalGroupInstanceHistoriesConnection;
  isCurrent?: Maybe<Scalars['Boolean']>;
};


export type SignalGroupInstanceSignalGroupCurrentInstancesByInstanceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupCurrentInstancesOrderBy>>;
  condition?: Maybe<SignalGroupCurrentInstanceCondition>;
  filter?: Maybe<SignalGroupCurrentInstanceFilter>;
};


export type SignalGroupInstanceSignalGroupInstanceHistoriesByInstanceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupInstanceHistoriesOrderBy>>;
  condition?: Maybe<SignalGroupInstanceHistoryCondition>;
  filter?: Maybe<SignalGroupInstanceHistoryFilter>;
};

/** A connection to a list of `SignalGroupCurrentInstance` values. */
export type SignalGroupCurrentInstancesConnection = {
  __typename?: 'SignalGroupCurrentInstancesConnection';
  /** A list of `SignalGroupCurrentInstance` objects. */
  nodes: Array<SignalGroupCurrentInstance>;
  /** A list of edges which contains the `SignalGroupCurrentInstance` and cursor to aid in pagination. */
  edges: Array<SignalGroupCurrentInstancesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalGroupCurrentInstance` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SignalGroupCurrentInstance = Node & {
  __typename?: 'SignalGroupCurrentInstance';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  groupId: Scalars['UUID'];
  instanceId: Scalars['UUID'];
  /** Reads a single `SignalGroup` that is related to this `SignalGroupCurrentInstance`. */
  signalGroupByGroupId?: Maybe<SignalGroup>;
  /** Reads a single `SignalGroupInstance` that is related to this `SignalGroupCurrentInstance`. */
  signalGroupInstanceByInstanceId?: Maybe<SignalGroupInstance>;
};

/** A `SignalGroupCurrentInstance` edge in the connection. */
export type SignalGroupCurrentInstancesEdge = {
  __typename?: 'SignalGroupCurrentInstancesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignalGroupCurrentInstance` at the end of the edge. */
  node: SignalGroupCurrentInstance;
};

/** Methods to use when ordering `SignalGroupCurrentInstance`. */
export enum SignalGroupCurrentInstancesOrderBy {
  Natural = 'NATURAL',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  InstanceIdAsc = 'INSTANCE_ID_ASC',
  InstanceIdDesc = 'INSTANCE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SignalGroupCurrentInstance` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type SignalGroupCurrentInstanceCondition = {
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `instanceId` field. */
  instanceId?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `SignalGroupInstanceHistory` values. */
export type SignalGroupInstanceHistoriesConnection = {
  __typename?: 'SignalGroupInstanceHistoriesConnection';
  /** A list of `SignalGroupInstanceHistory` objects. */
  nodes: Array<SignalGroupInstanceHistory>;
  /** A list of edges which contains the `SignalGroupInstanceHistory` and cursor to aid in pagination. */
  edges: Array<SignalGroupInstanceHistoriesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalGroupInstanceHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SignalGroupInstanceHistory = Node & {
  __typename?: 'SignalGroupInstanceHistory';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  instanceId: Scalars['UUID'];
  type: SignalGroupInstanceHistoryType;
  description: Scalars['String'];
  createdAt: Scalars['Datetime'];
  extra: Scalars['JSON'];
  /** Reads a single `SignalGroupInstance` that is related to this `SignalGroupInstanceHistory`. */
  signalGroupInstanceByInstanceId?: Maybe<SignalGroupInstance>;
};

/** A `SignalGroupInstanceHistory` edge in the connection. */
export type SignalGroupInstanceHistoriesEdge = {
  __typename?: 'SignalGroupInstanceHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignalGroupInstanceHistory` at the end of the edge. */
  node: SignalGroupInstanceHistory;
};

/** Methods to use when ordering `SignalGroupInstanceHistory`. */
export enum SignalGroupInstanceHistoriesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InstanceIdAsc = 'INSTANCE_ID_ASC',
  InstanceIdDesc = 'INSTANCE_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ExtraAsc = 'EXTRA_ASC',
  ExtraDesc = 'EXTRA_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SignalGroupInstanceHistory` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type SignalGroupInstanceHistoryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `instanceId` field. */
  instanceId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<SignalGroupInstanceHistoryType>;
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `extra` field. */
  extra?: Maybe<Scalars['JSON']>;
};

/** A `SignalGroupInstance` edge in the connection. */
export type SignalGroupInstancesEdge = {
  __typename?: 'SignalGroupInstancesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignalGroupInstance` at the end of the edge. */
  node: SignalGroupInstance;
};

/** Methods to use when ordering `SignalGroupInstance`. */
export enum SignalGroupInstancesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SignalGroupIdAsc = 'SIGNAL_GROUP_ID_ASC',
  SignalGroupIdDesc = 'SIGNAL_GROUP_ID_DESC',
  StartAtAsc = 'START_AT_ASC',
  StartAtDesc = 'START_AT_DESC',
  EndAtAsc = 'END_AT_ASC',
  EndAtDesc = 'END_AT_DESC',
  ElapsedAsc = 'ELAPSED_ASC',
  ElapsedDesc = 'ELAPSED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SignalGroupInstance` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type SignalGroupInstanceCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `signalGroupId` field. */
  signalGroupId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `startAt` field. */
  startAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `endAt` field. */
  endAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `elapsed` field. */
  elapsed?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Signal` values, with data from `SignalGroupItem`. */
export type SignalGroupSignalsBySignalGroupItemSignalGroupIdAndSignalIdManyToManyConnection = {
  __typename?: 'SignalGroupSignalsBySignalGroupItemSignalGroupIdAndSignalIdManyToManyConnection';
  /** A list of `Signal` objects. */
  nodes: Array<Signal>;
  /** A list of edges which contains the `Signal`, info from the `SignalGroupItem`, and the cursor to aid in pagination. */
  edges: Array<SignalGroupSignalsBySignalGroupItemSignalGroupIdAndSignalIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Signal` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Signal` edge in the connection, with data from `SignalGroupItem`. */
export type SignalGroupSignalsBySignalGroupItemSignalGroupIdAndSignalIdManyToManyEdge = {
  __typename?: 'SignalGroupSignalsBySignalGroupItemSignalGroupIdAndSignalIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Signal` at the end of the edge. */
  node: Signal;
  /** Reads and enables pagination through a set of `SignalGroupItem`. */
  signalGroupItemsBySignalId: SignalGroupItemsConnection;
};


/** A `Signal` edge in the connection, with data from `SignalGroupItem`. */
export type SignalGroupSignalsBySignalGroupItemSignalGroupIdAndSignalIdManyToManyEdgeSignalGroupItemsBySignalIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupItemsOrderBy>>;
  condition?: Maybe<SignalGroupItemCondition>;
  filter?: Maybe<SignalGroupItemFilter>;
};

/** A connection to a list of `SignalHistory` values, with data from `SignalGroupItem`. */
export type SignalGroupSignalHistoriesBySignalGroupItemSignalGroupIdAndLastSignalHistoryIdManyToManyConnection = {
  __typename?: 'SignalGroupSignalHistoriesBySignalGroupItemSignalGroupIdAndLastSignalHistoryIdManyToManyConnection';
  /** A list of `SignalHistory` objects. */
  nodes: Array<SignalHistory>;
  /** A list of edges which contains the `SignalHistory`, info from the `SignalGroupItem`, and the cursor to aid in pagination. */
  edges: Array<SignalGroupSignalHistoriesBySignalGroupItemSignalGroupIdAndLastSignalHistoryIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SignalHistory` edge in the connection, with data from `SignalGroupItem`. */
export type SignalGroupSignalHistoriesBySignalGroupItemSignalGroupIdAndLastSignalHistoryIdManyToManyEdge = {
  __typename?: 'SignalGroupSignalHistoriesBySignalGroupItemSignalGroupIdAndLastSignalHistoryIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignalHistory` at the end of the edge. */
  node: SignalHistory;
  /** Reads and enables pagination through a set of `SignalGroupItem`. */
  signalGroupItemsByLastSignalHistoryId: SignalGroupItemsConnection;
};


/** A `SignalHistory` edge in the connection, with data from `SignalGroupItem`. */
export type SignalGroupSignalHistoriesBySignalGroupItemSignalGroupIdAndLastSignalHistoryIdManyToManyEdgeSignalGroupItemsByLastSignalHistoryIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupItemsOrderBy>>;
  condition?: Maybe<SignalGroupItemCondition>;
  filter?: Maybe<SignalGroupItemFilter>;
};

/** Methods to use when ordering `SignalHistory`. */
export enum SignalHistoriesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SignalIdAsc = 'SIGNAL_ID_ASC',
  SignalIdDesc = 'SIGNAL_ID_DESC',
  ActionAsc = 'ACTION_ASC',
  ActionDesc = 'ACTION_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  MessageAsc = 'MESSAGE_ASC',
  MessageDesc = 'MESSAGE_DESC',
  DataAsc = 'DATA_ASC',
  DataDesc = 'DATA_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SignalHistory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SignalHistoryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `signalId` field. */
  signalId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `action` field. */
  action?: Maybe<SignalHistoryActionType>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `message` field. */
  message?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `data` field. */
  data?: Maybe<Scalars['JSON']>;
};

/** A `SignalGroup` edge in the connection. */
export type SignalGroupsEdge = {
  __typename?: 'SignalGroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignalGroup` at the end of the edge. */
  node: SignalGroup;
};

/** A connection to a list of `SignalHistory` values. */
export type SignalHistoriesConnection = {
  __typename?: 'SignalHistoriesConnection';
  /** A list of `SignalHistory` objects. */
  nodes: Array<SignalHistory>;
  /** A list of edges which contains the `SignalHistory` and cursor to aid in pagination. */
  edges: Array<SignalHistoriesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SignalHistory` edge in the connection. */
export type SignalHistoriesEdge = {
  __typename?: 'SignalHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignalHistory` at the end of the edge. */
  node: SignalHistory;
};

/** A connection to a list of `SignalGroup` values, with data from `SignalGroupItem`. */
export type SignalSignalGroupsBySignalGroupItemSignalIdAndSignalGroupIdManyToManyConnection = {
  __typename?: 'SignalSignalGroupsBySignalGroupItemSignalIdAndSignalGroupIdManyToManyConnection';
  /** A list of `SignalGroup` objects. */
  nodes: Array<SignalGroup>;
  /** A list of edges which contains the `SignalGroup`, info from the `SignalGroupItem`, and the cursor to aid in pagination. */
  edges: Array<SignalSignalGroupsBySignalGroupItemSignalIdAndSignalGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SignalGroup` edge in the connection, with data from `SignalGroupItem`. */
export type SignalSignalGroupsBySignalGroupItemSignalIdAndSignalGroupIdManyToManyEdge = {
  __typename?: 'SignalSignalGroupsBySignalGroupItemSignalIdAndSignalGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignalGroup` at the end of the edge. */
  node: SignalGroup;
  /** Reads and enables pagination through a set of `SignalGroupItem`. */
  signalGroupItemsBySignalGroupId: SignalGroupItemsConnection;
};


/** A `SignalGroup` edge in the connection, with data from `SignalGroupItem`. */
export type SignalSignalGroupsBySignalGroupItemSignalIdAndSignalGroupIdManyToManyEdgeSignalGroupItemsBySignalGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupItemsOrderBy>>;
  condition?: Maybe<SignalGroupItemCondition>;
  filter?: Maybe<SignalGroupItemFilter>;
};

/** A connection to a list of `SignalHistory` values, with data from `SignalGroupItem`. */
export type SignalSignalHistoriesBySignalGroupItemSignalIdAndLastSignalHistoryIdManyToManyConnection = {
  __typename?: 'SignalSignalHistoriesBySignalGroupItemSignalIdAndLastSignalHistoryIdManyToManyConnection';
  /** A list of `SignalHistory` objects. */
  nodes: Array<SignalHistory>;
  /** A list of edges which contains the `SignalHistory`, info from the `SignalGroupItem`, and the cursor to aid in pagination. */
  edges: Array<SignalSignalHistoriesBySignalGroupItemSignalIdAndLastSignalHistoryIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SignalHistory` edge in the connection, with data from `SignalGroupItem`. */
export type SignalSignalHistoriesBySignalGroupItemSignalIdAndLastSignalHistoryIdManyToManyEdge = {
  __typename?: 'SignalSignalHistoriesBySignalGroupItemSignalIdAndLastSignalHistoryIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignalHistory` at the end of the edge. */
  node: SignalHistory;
  /** Reads and enables pagination through a set of `SignalGroupItem`. */
  signalGroupItemsByLastSignalHistoryId: SignalGroupItemsConnection;
};


/** A `SignalHistory` edge in the connection, with data from `SignalGroupItem`. */
export type SignalSignalHistoriesBySignalGroupItemSignalIdAndLastSignalHistoryIdManyToManyEdgeSignalGroupItemsByLastSignalHistoryIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupItemsOrderBy>>;
  condition?: Maybe<SignalGroupItemCondition>;
  filter?: Maybe<SignalGroupItemFilter>;
};

/** A `Signal` edge in the connection. */
export type SignalsEdge = {
  __typename?: 'SignalsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Signal` at the end of the edge. */
  node: Signal;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  RoleAsc = 'ROLE_ASC',
  RoleDesc = 'ROLE_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `username` field. */
  username?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `role` field. */
  role?: Maybe<UserRoles>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type ActiveSignalAnalytic = {
  __typename?: 'ActiveSignalAnalytic';
  activeCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  totalTriggered?: Maybe<Scalars['Int']>;
};

export type ActiveSignalGroupAnalytic = {
  __typename?: 'ActiveSignalGroupAnalytic';
  activeCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  totalTriggered?: Maybe<Scalars['Int']>;
};

/** A connection to a list of `SignalAndSignalGroupRelationship` values. */
export type SignalAndSignalGroupRelationshipsConnection = {
  __typename?: 'SignalAndSignalGroupRelationshipsConnection';
  /** A list of `SignalAndSignalGroupRelationship` objects. */
  nodes: Array<SignalAndSignalGroupRelationship>;
  /** A list of edges which contains the `SignalAndSignalGroupRelationship` and cursor to aid in pagination. */
  edges: Array<SignalAndSignalGroupRelationshipsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalAndSignalGroupRelationship` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SignalAndSignalGroupRelationship = {
  __typename?: 'SignalAndSignalGroupRelationship';
  signalGroup?: Maybe<Scalars['String']>;
  signal?: Maybe<Scalars['String']>;
};

/** A `SignalAndSignalGroupRelationship` edge in the connection. */
export type SignalAndSignalGroupRelationshipsEdge = {
  __typename?: 'SignalAndSignalGroupRelationshipsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignalAndSignalGroupRelationship` at the end of the edge. */
  node: SignalAndSignalGroupRelationship;
};

/** A filter to be used against `SignalAndSignalGroupRelationship` object types. All fields are combined with a logical ‘and.’ */
export type SignalAndSignalGroupRelationshipFilter = {
  /** Filter by the object’s `signalGroup` field. */
  signalGroup?: Maybe<StringFilter>;
  /** Filter by the object’s `signal` field. */
  signal?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SignalAndSignalGroupRelationshipFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SignalAndSignalGroupRelationshipFilter>>;
  /** Negates the expression. */
  not?: Maybe<SignalAndSignalGroupRelationshipFilter>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a single `Signal`. */
  createSignal?: Maybe<CreateSignalPayload>;
  /** Updates a single `Signal` using its globally unique id and a patch. */
  updateSignal?: Maybe<UpdateSignalPayload>;
  /** Updates a single `Signal` using a unique key and a patch. */
  updateSignalById?: Maybe<UpdateSignalPayload>;
  /** Updates a single `Signal` using a unique key and a patch. */
  updateSignalByExternalId?: Maybe<UpdateSignalPayload>;
  /** Updates a single `SignalGroup` using its globally unique id and a patch. */
  updateSignalGroup?: Maybe<UpdateSignalGroupPayload>;
  /** Updates a single `SignalGroup` using a unique key and a patch. */
  updateSignalGroupById?: Maybe<UpdateSignalGroupPayload>;
  /** Updates a single `SignalGroupAction` using its globally unique id and a patch. */
  updateSignalGroupAction?: Maybe<UpdateSignalGroupActionPayload>;
  /** Updates a single `SignalGroupAction` using a unique key and a patch. */
  updateSignalGroupActionById?: Maybe<UpdateSignalGroupActionPayload>;
  /** Updates a single `SignalGroupItem` using its globally unique id and a patch. */
  updateSignalGroupItem?: Maybe<UpdateSignalGroupItemPayload>;
  /** Updates a single `SignalGroupItem` using a unique key and a patch. */
  updateSignalGroupItemById?: Maybe<UpdateSignalGroupItemPayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUserById?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUserByUsername?: Maybe<UpdateUserPayload>;
  /** Deletes a single `Signal` using its globally unique id. */
  deleteSignal?: Maybe<DeleteSignalPayload>;
  /** Deletes a single `Signal` using a unique key. */
  deleteSignalById?: Maybe<DeleteSignalPayload>;
  /** Deletes a single `Signal` using a unique key. */
  deleteSignalByExternalId?: Maybe<DeleteSignalPayload>;
  /** Deletes a single `SignalGroup` using its globally unique id. */
  deleteSignalGroup?: Maybe<DeleteSignalGroupPayload>;
  /** Deletes a single `SignalGroup` using a unique key. */
  deleteSignalGroupById?: Maybe<DeleteSignalGroupPayload>;
  /** Deletes a single `SignalGroupAction` using its globally unique id. */
  deleteSignalGroupAction?: Maybe<DeleteSignalGroupActionPayload>;
  /** Deletes a single `SignalGroupAction` using a unique key. */
  deleteSignalGroupActionById?: Maybe<DeleteSignalGroupActionPayload>;
  /** Deletes a single `SignalGroupItem` using its globally unique id. */
  deleteSignalGroupItem?: Maybe<DeleteSignalGroupItemPayload>;
  /** Deletes a single `SignalGroupItem` using a unique key. */
  deleteSignalGroupItemById?: Maybe<DeleteSignalGroupItemPayload>;
  clearSignalGroupItemById?: Maybe<ClearSignalGroupItemByIdPayload>;
  createSignalGroup?: Maybe<CreateSignalGroupPayload>;
  insertSignalHistory?: Maybe<InsertSignalHistoryPayload>;
  startSignalById?: Maybe<StartSignalByIdPayload>;
  stopSignalById?: Maybe<StopSignalByIdPayload>;
  updateEntireSignalGroupById?: Maybe<UpdateEntireSignalGroupByIdPayload>;
  /** Use this mutation to register a user */
  registerUser?: Maybe<RegisterUserPayload>;
  /** Use this mutation to log in to account by username and password */
  loginByUsernameAndPassword?: Maybe<LoginByUsernameAndPasswordPayload>;
  /** Use this mutation to auto log in by refreshToken */
  loginByRefreshToken?: Maybe<LoginByRefreshTokenPayload>;
  /** Use this mutation to logout from your account */
  logout?: Maybe<LogoutPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSignalArgs = {
  input: CreateSignalInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSignalArgs = {
  input: UpdateSignalInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSignalByIdArgs = {
  input: UpdateSignalByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSignalByExternalIdArgs = {
  input: UpdateSignalByExternalIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSignalGroupArgs = {
  input: UpdateSignalGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSignalGroupByIdArgs = {
  input: UpdateSignalGroupByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSignalGroupActionArgs = {
  input: UpdateSignalGroupActionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSignalGroupActionByIdArgs = {
  input: UpdateSignalGroupActionByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSignalGroupItemArgs = {
  input: UpdateSignalGroupItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSignalGroupItemByIdArgs = {
  input: UpdateSignalGroupItemByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByIdArgs = {
  input: UpdateUserByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByUsernameArgs = {
  input: UpdateUserByUsernameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSignalArgs = {
  input: DeleteSignalInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSignalByIdArgs = {
  input: DeleteSignalByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSignalByExternalIdArgs = {
  input: DeleteSignalByExternalIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSignalGroupArgs = {
  input: DeleteSignalGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSignalGroupByIdArgs = {
  input: DeleteSignalGroupByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSignalGroupActionArgs = {
  input: DeleteSignalGroupActionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSignalGroupActionByIdArgs = {
  input: DeleteSignalGroupActionByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSignalGroupItemArgs = {
  input: DeleteSignalGroupItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSignalGroupItemByIdArgs = {
  input: DeleteSignalGroupItemByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationClearSignalGroupItemByIdArgs = {
  input: ClearSignalGroupItemByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSignalGroupArgs = {
  input: CreateSignalGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertSignalHistoryArgs = {
  input: InsertSignalHistoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationStartSignalByIdArgs = {
  input: StartSignalByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationStopSignalByIdArgs = {
  input: StopSignalByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEntireSignalGroupByIdArgs = {
  input: UpdateEntireSignalGroupByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoginByUsernameAndPasswordArgs = {
  input: LoginByUsernameAndPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoginByRefreshTokenArgs = {
  input?: Maybe<LoginByRefreshTokenInput>;
};

/** The output of our create `Signal` mutation. */
export type CreateSignalPayload = {
  __typename?: 'CreateSignalPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Signal` that was created by this mutation. */
  signal?: Maybe<Signal>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Signal`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `Signal`. May be used by Relay 1. */
  signalEdge?: Maybe<SignalsEdge>;
};


/** The output of our create `Signal` mutation. */
export type CreateSignalPayloadSignalEdgeArgs = {
  orderBy?: Maybe<Array<SignalsOrderBy>>;
};

/** All input for the create `Signal` mutation. */
export type CreateSignalInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Signal` to be created by this mutation. */
  signal: SignalInput;
};

/** An input for mutations affecting `Signal` */
export type SignalInput = {
  name: Scalars['String'];
  customTriggerTemplate?: Maybe<Scalars['JSON']>;
};

/** The output of our update `Signal` mutation. */
export type UpdateSignalPayload = {
  __typename?: 'UpdateSignalPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Signal` that was updated by this mutation. */
  signal?: Maybe<Signal>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Signal`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `Signal`. May be used by Relay 1. */
  signalEdge?: Maybe<SignalsEdge>;
};


/** The output of our update `Signal` mutation. */
export type UpdateSignalPayloadSignalEdgeArgs = {
  orderBy?: Maybe<Array<SignalsOrderBy>>;
};

/** All input for the `updateSignal` mutation. */
export type UpdateSignalInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Signal` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Signal` being updated. */
  signalPatch: SignalPatch;
};

/** Represents an update to a `Signal`. Fields that are set will be updated. */
export type SignalPatch = {
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  customTriggerTemplate?: Maybe<Scalars['JSON']>;
};

/** All input for the `updateSignalById` mutation. */
export type UpdateSignalByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Signal` being updated. */
  signalPatch: SignalPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateSignalByExternalId` mutation. */
export type UpdateSignalByExternalIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Signal` being updated. */
  signalPatch: SignalPatch;
  externalId: Scalars['UUID'];
};

/** The output of our update `SignalGroup` mutation. */
export type UpdateSignalGroupPayload = {
  __typename?: 'UpdateSignalGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SignalGroup` that was updated by this mutation. */
  signalGroup?: Maybe<SignalGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `SignalGroup`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `SignalGroup`. May be used by Relay 1. */
  signalGroupEdge?: Maybe<SignalGroupsEdge>;
};


/** The output of our update `SignalGroup` mutation. */
export type UpdateSignalGroupPayloadSignalGroupEdgeArgs = {
  orderBy?: Maybe<Array<SignalGroupsOrderBy>>;
};

/** All input for the `updateSignalGroup` mutation. */
export type UpdateSignalGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SignalGroup` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SignalGroup` being updated. */
  signalGroupPatch: SignalGroupPatch;
};

/** Represents an update to a `SignalGroup`. Fields that are set will be updated. */
export type SignalGroupPatch = {
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  stopAfterTrigger?: Maybe<Scalars['Boolean']>;
  cooldownAfterTriggerAmount?: Maybe<Scalars['Int']>;
  cooldownAfterTriggerInterval?: Maybe<CooldownAfterTriggerInterval>;
};

/** All input for the `updateSignalGroupById` mutation. */
export type UpdateSignalGroupByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SignalGroup` being updated. */
  signalGroupPatch: SignalGroupPatch;
  id: Scalars['UUID'];
};

/** The output of our update `SignalGroupAction` mutation. */
export type UpdateSignalGroupActionPayload = {
  __typename?: 'UpdateSignalGroupActionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SignalGroupAction` that was updated by this mutation. */
  signalGroupAction?: Maybe<SignalGroupAction>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SignalGroup` that is related to this `SignalGroupAction`. */
  signalGroupBySignalGroupId?: Maybe<SignalGroup>;
  /** An edge for our `SignalGroupAction`. May be used by Relay 1. */
  signalGroupActionEdge?: Maybe<SignalGroupActionsEdge>;
};


/** The output of our update `SignalGroupAction` mutation. */
export type UpdateSignalGroupActionPayloadSignalGroupActionEdgeArgs = {
  orderBy?: Maybe<Array<SignalGroupActionsOrderBy>>;
};

/** All input for the `updateSignalGroupAction` mutation. */
export type UpdateSignalGroupActionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SignalGroupAction` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SignalGroupAction` being updated. */
  signalGroupActionPatch: SignalGroupActionPatch;
};

/** Represents an update to a `SignalGroupAction`. Fields that are set will be updated. */
export type SignalGroupActionPatch = {
  payload?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

/** All input for the `updateSignalGroupActionById` mutation. */
export type UpdateSignalGroupActionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SignalGroupAction` being updated. */
  signalGroupActionPatch: SignalGroupActionPatch;
  id: Scalars['UUID'];
};

/** The output of our update `SignalGroupItem` mutation. */
export type UpdateSignalGroupItemPayload = {
  __typename?: 'UpdateSignalGroupItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SignalGroupItem` that was updated by this mutation. */
  signalGroupItem?: Maybe<SignalGroupItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Signal` that is related to this `SignalGroupItem`. */
  signalBySignalId?: Maybe<Signal>;
  /** Reads a single `SignalGroup` that is related to this `SignalGroupItem`. */
  signalGroupBySignalGroupId?: Maybe<SignalGroup>;
  /** Reads a single `SignalHistory` that is related to this `SignalGroupItem`. */
  signalHistoryByLastSignalHistoryId?: Maybe<SignalHistory>;
  /** An edge for our `SignalGroupItem`. May be used by Relay 1. */
  signalGroupItemEdge?: Maybe<SignalGroupItemsEdge>;
};


/** The output of our update `SignalGroupItem` mutation. */
export type UpdateSignalGroupItemPayloadSignalGroupItemEdgeArgs = {
  orderBy?: Maybe<Array<SignalGroupItemsOrderBy>>;
};

/** All input for the `updateSignalGroupItem` mutation. */
export type UpdateSignalGroupItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SignalGroupItem` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SignalGroupItem` being updated. */
  signalGroupItemPatch: SignalGroupItemPatch;
};

/** Represents an update to a `SignalGroupItem`. Fields that are set will be updated. */
export type SignalGroupItemPatch = {
  isActive?: Maybe<Scalars['Boolean']>;
  lastSignalHistoryId?: Maybe<Scalars['UUID']>;
  isPerpetual?: Maybe<Scalars['Boolean']>;
  firstToggleActionValue?: Maybe<SignalGroupItemFirstToggleActionValue>;
  cooldownAfterReceivedSignalAmount?: Maybe<Scalars['Int']>;
  cooldownAfterReceivedSignalInterval?: Maybe<SignalGroupItemCooldownAfterReceivedSignalInterval>;
  resetCooldownAfterGroupTrigger?: Maybe<Scalars['Boolean']>;
};

/** All input for the `updateSignalGroupItemById` mutation. */
export type UpdateSignalGroupItemByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SignalGroupItem` being updated. */
  signalGroupItemPatch: SignalGroupItemPatch;
  id: Scalars['UUID'];
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `User` that was updated by this mutation. */
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  userPatch: UserPatch;
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  /** Public-facing username of the user. */
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoles>;
  isActive?: Maybe<Scalars['Boolean']>;
};

/** All input for the `updateUserById` mutation. */
export type UpdateUserByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `User` being updated. */
  userPatch: UserPatch;
  /** Unique identifier for the user. */
  id: Scalars['UUID'];
};

/** All input for the `updateUserByUsername` mutation. */
export type UpdateUserByUsernameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `User` being updated. */
  userPatch: UserPatch;
  /** Public-facing username of the user. */
  username: Scalars['String'];
};

/** The output of our delete `Signal` mutation. */
export type DeleteSignalPayload = {
  __typename?: 'DeleteSignalPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Signal` that was deleted by this mutation. */
  signal?: Maybe<Signal>;
  deletedSignalId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Signal`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `Signal`. May be used by Relay 1. */
  signalEdge?: Maybe<SignalsEdge>;
};


/** The output of our delete `Signal` mutation. */
export type DeleteSignalPayloadSignalEdgeArgs = {
  orderBy?: Maybe<Array<SignalsOrderBy>>;
};

/** All input for the `deleteSignal` mutation. */
export type DeleteSignalInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Signal` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSignalById` mutation. */
export type DeleteSignalByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteSignalByExternalId` mutation. */
export type DeleteSignalByExternalIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  externalId: Scalars['UUID'];
};

/** The output of our delete `SignalGroup` mutation. */
export type DeleteSignalGroupPayload = {
  __typename?: 'DeleteSignalGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SignalGroup` that was deleted by this mutation. */
  signalGroup?: Maybe<SignalGroup>;
  deletedSignalGroupId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `SignalGroup`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `SignalGroup`. May be used by Relay 1. */
  signalGroupEdge?: Maybe<SignalGroupsEdge>;
};


/** The output of our delete `SignalGroup` mutation. */
export type DeleteSignalGroupPayloadSignalGroupEdgeArgs = {
  orderBy?: Maybe<Array<SignalGroupsOrderBy>>;
};

/** All input for the `deleteSignalGroup` mutation. */
export type DeleteSignalGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SignalGroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSignalGroupById` mutation. */
export type DeleteSignalGroupByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `SignalGroupAction` mutation. */
export type DeleteSignalGroupActionPayload = {
  __typename?: 'DeleteSignalGroupActionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SignalGroupAction` that was deleted by this mutation. */
  signalGroupAction?: Maybe<SignalGroupAction>;
  deletedSignalGroupActionId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SignalGroup` that is related to this `SignalGroupAction`. */
  signalGroupBySignalGroupId?: Maybe<SignalGroup>;
  /** An edge for our `SignalGroupAction`. May be used by Relay 1. */
  signalGroupActionEdge?: Maybe<SignalGroupActionsEdge>;
};


/** The output of our delete `SignalGroupAction` mutation. */
export type DeleteSignalGroupActionPayloadSignalGroupActionEdgeArgs = {
  orderBy?: Maybe<Array<SignalGroupActionsOrderBy>>;
};

/** All input for the `deleteSignalGroupAction` mutation. */
export type DeleteSignalGroupActionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SignalGroupAction` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSignalGroupActionById` mutation. */
export type DeleteSignalGroupActionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `SignalGroupItem` mutation. */
export type DeleteSignalGroupItemPayload = {
  __typename?: 'DeleteSignalGroupItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SignalGroupItem` that was deleted by this mutation. */
  signalGroupItem?: Maybe<SignalGroupItem>;
  deletedSignalGroupItemId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Signal` that is related to this `SignalGroupItem`. */
  signalBySignalId?: Maybe<Signal>;
  /** Reads a single `SignalGroup` that is related to this `SignalGroupItem`. */
  signalGroupBySignalGroupId?: Maybe<SignalGroup>;
  /** Reads a single `SignalHistory` that is related to this `SignalGroupItem`. */
  signalHistoryByLastSignalHistoryId?: Maybe<SignalHistory>;
  /** An edge for our `SignalGroupItem`. May be used by Relay 1. */
  signalGroupItemEdge?: Maybe<SignalGroupItemsEdge>;
};


/** The output of our delete `SignalGroupItem` mutation. */
export type DeleteSignalGroupItemPayloadSignalGroupItemEdgeArgs = {
  orderBy?: Maybe<Array<SignalGroupItemsOrderBy>>;
};

/** All input for the `deleteSignalGroupItem` mutation. */
export type DeleteSignalGroupItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SignalGroupItem` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSignalGroupItemById` mutation. */
export type DeleteSignalGroupItemByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our `clearSignalGroupItemById` mutation. */
export type ClearSignalGroupItemByIdPayload = {
  __typename?: 'ClearSignalGroupItemByIdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  signalGroupItem?: Maybe<SignalGroupItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Signal` that is related to this `SignalGroupItem`. */
  signalBySignalId?: Maybe<Signal>;
  /** Reads a single `SignalGroup` that is related to this `SignalGroupItem`. */
  signalGroupBySignalGroupId?: Maybe<SignalGroup>;
  /** Reads a single `SignalHistory` that is related to this `SignalGroupItem`. */
  signalHistoryByLastSignalHistoryId?: Maybe<SignalHistory>;
  /** An edge for our `SignalGroupItem`. May be used by Relay 1. */
  signalGroupItemEdge?: Maybe<SignalGroupItemsEdge>;
};


/** The output of our `clearSignalGroupItemById` mutation. */
export type ClearSignalGroupItemByIdPayloadSignalGroupItemEdgeArgs = {
  orderBy?: Maybe<Array<SignalGroupItemsOrderBy>>;
};

/** All input for the `clearSignalGroupItemById` mutation. */
export type ClearSignalGroupItemByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our `createSignalGroup` mutation. */
export type CreateSignalGroupPayload = {
  __typename?: 'CreateSignalGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  signalGroup?: Maybe<SignalGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `SignalGroup`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `SignalGroup`. May be used by Relay 1. */
  signalGroupEdge?: Maybe<SignalGroupsEdge>;
};


/** The output of our `createSignalGroup` mutation. */
export type CreateSignalGroupPayloadSignalGroupEdgeArgs = {
  orderBy?: Maybe<Array<SignalGroupsOrderBy>>;
};

/** All input for the `createSignalGroup` mutation. */
export type CreateSignalGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  signals: Array<Maybe<CreateUpdateSignalGroupSignalsInputRecordInput>>;
  actions: Array<Maybe<Scalars['String']>>;
  stopAfterTrigger: Scalars['Boolean'];
  cooldownAfterTriggerAmount?: Maybe<Scalars['Int']>;
  cooldownAfterTriggerInterval?: Maybe<CooldownAfterTriggerInterval>;
};

/** An input for mutations affecting `CreateUpdateSignalGroupSignalsInputRecord` */
export type CreateUpdateSignalGroupSignalsInputRecordInput = {
  id?: Maybe<Scalars['UUID']>;
  isPerpetual?: Maybe<Scalars['Boolean']>;
  firstToggleActionValue?: Maybe<SignalGroupItemFirstToggleActionValue>;
  cooldownAfterReceivedSignalAmount?: Maybe<Scalars['Int']>;
  cooldownAfterReceivedSignalInterval?: Maybe<SignalGroupItemCooldownAfterReceivedSignalInterval>;
  resetCooldownAfterGroupTrigger?: Maybe<Scalars['Boolean']>;
};

/** The output of our `insertSignalHistory` mutation. */
export type InsertSignalHistoryPayload = {
  __typename?: 'InsertSignalHistoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertSignalHistory` mutation. */
export type InsertSignalHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  externalUuid: Scalars['UUID'];
  action: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
};

/** The output of our `startSignalById` mutation. */
export type StartSignalByIdPayload = {
  __typename?: 'StartSignalByIdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  signal?: Maybe<Signal>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Signal`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `Signal`. May be used by Relay 1. */
  signalEdge?: Maybe<SignalsEdge>;
};


/** The output of our `startSignalById` mutation. */
export type StartSignalByIdPayloadSignalEdgeArgs = {
  orderBy?: Maybe<Array<SignalsOrderBy>>;
};

/** All input for the `startSignalById` mutation. */
export type StartSignalByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  startRelatedSignals: Scalars['Boolean'];
};

/** The output of our `stopSignalById` mutation. */
export type StopSignalByIdPayload = {
  __typename?: 'StopSignalByIdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  signal?: Maybe<Signal>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Signal`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `Signal`. May be used by Relay 1. */
  signalEdge?: Maybe<SignalsEdge>;
};


/** The output of our `stopSignalById` mutation. */
export type StopSignalByIdPayloadSignalEdgeArgs = {
  orderBy?: Maybe<Array<SignalsOrderBy>>;
};

/** All input for the `stopSignalById` mutation. */
export type StopSignalByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  stopRelatedSignals: Scalars['Boolean'];
};

/** The output of our `updateEntireSignalGroupById` mutation. */
export type UpdateEntireSignalGroupByIdPayload = {
  __typename?: 'UpdateEntireSignalGroupByIdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  signalGroup?: Maybe<SignalGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `SignalGroup`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `SignalGroup`. May be used by Relay 1. */
  signalGroupEdge?: Maybe<SignalGroupsEdge>;
};


/** The output of our `updateEntireSignalGroupById` mutation. */
export type UpdateEntireSignalGroupByIdPayloadSignalGroupEdgeArgs = {
  orderBy?: Maybe<Array<SignalGroupsOrderBy>>;
};

/** All input for the `updateEntireSignalGroupById` mutation. */
export type UpdateEntireSignalGroupByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  signals: Array<Maybe<CreateUpdateSignalGroupSignalsInputRecordInput>>;
  deleteSignals: Array<Maybe<Scalars['UUID']>>;
  actions: Array<Maybe<UpdateEntireSignalGroupByIdActionInput>>;
  deleteActions: Array<Maybe<Scalars['UUID']>>;
  stopAfterTrigger: Scalars['Boolean'];
  cooldownAfterTriggerAmount?: Maybe<Scalars['Int']>;
  cooldownAfterTriggerInterval?: Maybe<CooldownAfterTriggerInterval>;
};

/** An input for mutations affecting `UpdateEntireSignalGroupByIdAction` */
export type UpdateEntireSignalGroupByIdActionInput = {
  id?: Maybe<Scalars['UUID']>;
  payload?: Maybe<Scalars['String']>;
};

export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  credentials: Credentials;
};

export type Credentials = {
  __typename?: 'Credentials';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type RegisterUserInput = {
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type LoginByUsernameAndPasswordPayload = {
  __typename?: 'LoginByUsernameAndPasswordPayload';
  credentials: Credentials;
};

export type LoginByUsernameAndPasswordInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type LoginByRefreshTokenPayload = {
  __typename?: 'LoginByRefreshTokenPayload';
  credentials: Credentials;
};

export type LoginByRefreshTokenInput = {
  refreshToken: Scalars['String'];
};

export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type Subscription = {
  __typename?: 'Subscription';
  listen: ListenPayload;
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionListenArgs = {
  topic: Scalars['String'];
};

export type ListenPayload = {
  __typename?: 'ListenPayload';
  /** Our root query field type. Allows us to run any query from our subscription payload. */
  query?: Maybe<Query>;
  relatedNode?: Maybe<Node>;
  relatedNodeId?: Maybe<Scalars['ID']>;
};

export type Auth = {
  __typename?: 'Auth';
  accessToken?: Maybe<Scalars['String']>;
  isInitialized: Scalars['Boolean'];
  query?: Maybe<Query>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type DashboardAnalyticsQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardAnalyticsQuery = (
  { __typename?: 'Query' }
  & { activeSignalAnalytics?: Maybe<(
    { __typename?: 'ActiveSignalAnalytic' }
    & Pick<ActiveSignalAnalytic, 'activeCount' | 'totalCount' | 'totalTriggered'>
  )>, activeSignalGroupAnalytics?: Maybe<(
    { __typename?: 'ActiveSignalGroupAnalytic' }
    & Pick<ActiveSignalGroupAnalytic, 'activeCount' | 'totalCount' | 'totalTriggered'>
  )>, mostRecentSignals?: Maybe<(
    { __typename?: 'SignalHistoriesConnection' }
    & { nodes: Array<(
      { __typename?: 'SignalHistory' }
      & IncomingSignalListEntryFragment
    )> }
  )> }
);

export type GetAuthQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthQuery = (
  { __typename?: 'Query' }
  & { auth?: Maybe<(
    { __typename?: 'Auth' }
    & Pick<Auth, 'isInitialized' | 'refreshToken' | 'accessToken'>
  )> }
);

export type CredentialsFragment = (
  { __typename?: 'Credentials' }
  & Pick<Credentials, 'accessToken' | 'refreshToken'>
);

export type LoginByUsernameAndPasswordMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginByUsernameAndPasswordMutation = (
  { __typename?: 'Mutation' }
  & { loginByUsernameAndPassword?: Maybe<(
    { __typename?: 'LoginByUsernameAndPasswordPayload' }
    & { credentials: (
      { __typename?: 'Credentials' }
      & CredentialsFragment
    ) }
  )> }
);

export type LoginByRefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type LoginByRefreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { loginByRefreshToken?: Maybe<(
    { __typename?: 'LoginByRefreshTokenPayload' }
    & { credentials: (
      { __typename?: 'Credentials' }
      & CredentialsFragment
    ) }
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout?: Maybe<(
    { __typename?: 'LogoutPayload' }
    & Pick<LogoutPayload, 'success'>
  )> }
);

export type RegisterUserMutationVariables = Exact<{
  input: RegisterUserInput;
}>;


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { registerUser?: Maybe<(
    { __typename?: 'RegisterUserPayload' }
    & { credentials: (
      { __typename?: 'Credentials' }
      & CredentialsFragment
    ) }
  )> }
);

export type IncomingSignalListEntryFragment = (
  { __typename?: 'SignalHistory' }
  & Pick<SignalHistory, 'id' | 'action' | 'message' | 'data' | 'createdAt'>
  & { signalBySignalId?: Maybe<(
    { __typename?: 'Signal' }
    & Pick<Signal, 'id' | 'name'>
  )> }
);

export type AllIncomingSignalsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<SignalHistoryFilter>;
}>;


export type AllIncomingSignalsQuery = (
  { __typename?: 'Query' }
  & { allSignalHistories?: Maybe<(
    { __typename?: 'SignalHistoriesConnection' }
    & Pick<SignalHistoriesConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'SignalHistory' }
      & IncomingSignalListEntryFragment
    )> }
  )> }
);

export type SignalEntryFragment = (
  { __typename?: 'Signal' }
  & Pick<Signal, 'id' | 'name'>
);

export type GetSignalsQueryVariables = Exact<{
  value: Scalars['String'];
}>;


export type GetSignalsQuery = (
  { __typename?: 'Query' }
  & { allSignals?: Maybe<(
    { __typename?: 'SignalsConnection' }
    & { nodes: Array<(
      { __typename?: 'Signal' }
      & SignalEntryFragment
    )> }
  )> }
);

export type SignalByIdEntryFragment = (
  { __typename?: 'Signal' }
  & Pick<Signal, 'id' | 'externalId' | 'name' | 'isActive' | 'runningAt' | 'createdAt' | 'customTriggerTemplate'>
  & { signalGroupsBySignalGroupItemSignalIdAndSignalGroupId: (
    { __typename?: 'SignalSignalGroupsBySignalGroupItemSignalIdAndSignalGroupIdManyToManyConnection' }
    & { nodes: Array<(
      { __typename?: 'SignalGroup' }
      & Pick<SignalGroup, 'id' | 'name'>
    )> }
  ) }
);

export type SignalByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SignalByIdQuery = (
  { __typename?: 'Query' }
  & { signalById?: Maybe<(
    { __typename?: 'Signal' }
    & SignalByIdEntryFragment
  )> }
);

export type AllSignalsEntryFragment = (
  { __typename?: 'Signal' }
  & Pick<Signal, 'id' | 'name' | 'isActive' | 'createdAt'>
  & { signalGroupsBySignalGroupItemSignalIdAndSignalGroupId: (
    { __typename?: 'SignalSignalGroupsBySignalGroupItemSignalIdAndSignalGroupIdManyToManyConnection' }
    & Pick<SignalSignalGroupsBySignalGroupItemSignalIdAndSignalGroupIdManyToManyConnection, 'totalCount'>
  ), lastHistory?: Maybe<(
    { __typename?: 'SignalHistory' }
    & Pick<SignalHistory, 'action' | 'createdAt'>
  )> }
);

export type AllSignalsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalsOrderBy> | SignalsOrderBy>;
  filter?: Maybe<SignalFilter>;
}>;


export type AllSignalsQuery = (
  { __typename?: 'Query' }
  & { allSignals?: Maybe<(
    { __typename?: 'SignalsConnection' }
    & Pick<SignalsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'Signal' }
      & AllSignalsEntryFragment
    )> }
  )> }
);

export type CreateSignalMutationVariables = Exact<{
  input: CreateSignalInput;
}>;


export type CreateSignalMutation = (
  { __typename?: 'Mutation' }
  & { createSignal?: Maybe<(
    { __typename?: 'CreateSignalPayload' }
    & { signal?: Maybe<(
      { __typename?: 'Signal' }
      & SignalByIdEntryFragment
    )> }
  )> }
);

export type UpdateSignalByIdMutationVariables = Exact<{
  input: UpdateSignalByIdInput;
}>;


export type UpdateSignalByIdMutation = (
  { __typename?: 'Mutation' }
  & { updateSignalById?: Maybe<(
    { __typename?: 'UpdateSignalPayload' }
    & { signal?: Maybe<(
      { __typename?: 'Signal' }
      & SignalByIdEntryFragment
    )> }
  )> }
);

export type DeleteSignalByIdMutationVariables = Exact<{
  input: DeleteSignalByIdInput;
}>;


export type DeleteSignalByIdMutation = (
  { __typename?: 'Mutation' }
  & { deleteSignalById?: Maybe<(
    { __typename?: 'DeleteSignalPayload' }
    & Pick<DeleteSignalPayload, 'deletedSignalId'>
  )> }
);

export type StartSignalByIdMutationVariables = Exact<{
  input: StartSignalByIdInput;
}>;


export type StartSignalByIdMutation = (
  { __typename?: 'Mutation' }
  & { startSignalById?: Maybe<(
    { __typename?: 'StartSignalByIdPayload' }
    & { signal?: Maybe<(
      { __typename?: 'Signal' }
      & SignalByIdEntryFragment
    )> }
  )> }
);

export type StopSignalByIdMutationVariables = Exact<{
  input: StopSignalByIdInput;
}>;


export type StopSignalByIdMutation = (
  { __typename?: 'Mutation' }
  & { stopSignalById?: Maybe<(
    { __typename?: 'StopSignalByIdPayload' }
    & { signal?: Maybe<(
      { __typename?: 'Signal' }
      & SignalByIdEntryFragment
    )> }
  )> }
);

export type AllSignalGroupsEntryFragment = (
  { __typename?: 'SignalGroup' }
  & Pick<SignalGroup, 'id' | 'name' | 'displayStatus' | 'isActive' | 'createdAt'>
  & { signalGroupItemsBySignalGroupId: (
    { __typename?: 'SignalGroupItemsConnection' }
    & Pick<SignalGroupItemsConnection, 'totalCount'>
  ), signalGroupTriggerHistoriesBySignalGroupId: (
    { __typename?: 'SignalGroupTriggerHistoriesConnection' }
    & Pick<SignalGroupTriggerHistoriesConnection, 'totalCount'>
  ), lastTrigger?: Maybe<(
    { __typename?: 'SignalGroupTriggerHistory' }
    & Pick<SignalGroupTriggerHistory, 'endAt'>
  )> }
);

export type SignalGroupEntryFragment = (
  { __typename?: 'SignalGroup' }
  & Pick<SignalGroup, 'id' | 'name' | 'displayStatus' | 'reactivateAt' | 'isActive' | 'stopAfterTrigger' | 'cooldownAfterTriggerAmount' | 'cooldownAfterTriggerInterval' | 'runningAt' | 'createdAt'>
  & { signalGroupItemsBySignalGroupId: (
    { __typename?: 'SignalGroupItemsConnection' }
    & { nodes: Array<(
      { __typename?: 'SignalGroupItem' }
      & { signalHistoryByLastSignalHistoryId?: Maybe<(
        { __typename?: 'SignalHistory' }
        & Pick<SignalHistory, 'action' | 'createdAt'>
      )>, signalBySignalId?: Maybe<(
        { __typename?: 'Signal' }
        & Pick<Signal, 'id' | 'name'>
      )> }
      & SignalGroupItemEntryFragment
    )> }
  ), signalGroupActionsBySignalGroupId: (
    { __typename?: 'SignalGroupActionsConnection' }
    & { nodes: Array<(
      { __typename?: 'SignalGroupAction' }
      & SignalGroupActionEntryFragment
    )> }
  ) }
);

export type SignalGroupByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SignalGroupByIdQuery = (
  { __typename?: 'Query' }
  & { signalGroupById?: Maybe<(
    { __typename?: 'SignalGroup' }
    & SignalGroupEntryFragment
  )> }
);

export type AllSignalGroupsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SignalGroupsOrderBy> | SignalGroupsOrderBy>;
  filter?: Maybe<SignalGroupFilter>;
}>;


export type AllSignalGroupsQuery = (
  { __typename?: 'Query' }
  & { allSignalGroups?: Maybe<(
    { __typename?: 'SignalGroupsConnection' }
    & Pick<SignalGroupsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'SignalGroup' }
      & AllSignalGroupsEntryFragment
    )> }
  )> }
);

export type CreateSignalGroupMutationVariables = Exact<{
  input: CreateSignalGroupInput;
}>;


export type CreateSignalGroupMutation = (
  { __typename?: 'Mutation' }
  & { createSignalGroup?: Maybe<(
    { __typename?: 'CreateSignalGroupPayload' }
    & { signalGroup?: Maybe<(
      { __typename?: 'SignalGroup' }
      & SignalGroupEntryFragment
    )> }
  )> }
);

export type UpdateSignalGroupByIdMutationVariables = Exact<{
  input: UpdateSignalGroupByIdInput;
}>;


export type UpdateSignalGroupByIdMutation = (
  { __typename?: 'Mutation' }
  & { updateSignalGroupById?: Maybe<(
    { __typename?: 'UpdateSignalGroupPayload' }
    & { signalGroup?: Maybe<(
      { __typename?: 'SignalGroup' }
      & SignalGroupEntryFragment
    )> }
  )> }
);

export type DeleteSignalGroupByIdMutationVariables = Exact<{
  input: DeleteSignalGroupByIdInput;
}>;


export type DeleteSignalGroupByIdMutation = (
  { __typename?: 'Mutation' }
  & { deleteSignalGroupById?: Maybe<(
    { __typename?: 'DeleteSignalGroupPayload' }
    & Pick<DeleteSignalGroupPayload, 'deletedSignalGroupId'>
  )> }
);

export type UpdateEntireSignalGroupByIdMutationVariables = Exact<{
  input: UpdateEntireSignalGroupByIdInput;
}>;


export type UpdateEntireSignalGroupByIdMutation = (
  { __typename?: 'Mutation' }
  & { updateEntireSignalGroupById?: Maybe<(
    { __typename?: 'UpdateEntireSignalGroupByIdPayload' }
    & { signalGroup?: Maybe<(
      { __typename?: 'SignalGroup' }
      & SignalGroupEntryFragment
    )> }
  )> }
);

export type SignalGroupActionEntryFragment = (
  { __typename?: 'SignalGroupAction' }
  & Pick<SignalGroupAction, 'id' | 'payload' | 'isActive' | 'triggerCount'>
);

export type UpdateSignalGroupActionByIdMutationVariables = Exact<{
  input: UpdateSignalGroupActionByIdInput;
}>;


export type UpdateSignalGroupActionByIdMutation = (
  { __typename?: 'Mutation' }
  & { updateSignalGroupActionById?: Maybe<(
    { __typename?: 'UpdateSignalGroupActionPayload' }
    & { signalGroupAction?: Maybe<(
      { __typename?: 'SignalGroupAction' }
      & SignalGroupActionEntryFragment
    )> }
  )> }
);

export type DeleteSignalGroupActionByIdMutationVariables = Exact<{
  input: DeleteSignalGroupActionByIdInput;
}>;


export type DeleteSignalGroupActionByIdMutation = (
  { __typename?: 'Mutation' }
  & { deleteSignalGroupActionById?: Maybe<(
    { __typename?: 'DeleteSignalGroupActionPayload' }
    & Pick<DeleteSignalGroupActionPayload, 'deletedSignalGroupActionId'>
  )> }
);

export type SignalGroupInstanceEntryFragment = (
  { __typename?: 'SignalGroupInstance' }
  & Pick<SignalGroupInstance, 'id' | 'startAt' | 'endAt' | 'isCurrent' | 'elapsed'>
);

export type SignalGroupInstanceByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SignalGroupInstanceByIdQuery = (
  { __typename?: 'Query' }
  & { signalGroupInstanceById?: Maybe<(
    { __typename?: 'SignalGroupInstance' }
    & { signalGroupBySignalGroupId?: Maybe<(
      { __typename?: 'SignalGroup' }
      & Pick<SignalGroup, 'name' | 'id'>
    )>, signalGroupInstanceHistoriesByInstanceId: (
      { __typename?: 'SignalGroupInstanceHistoriesConnection' }
      & { nodes: Array<(
        { __typename?: 'SignalGroupInstanceHistory' }
        & SignalGroupInstanceHistoryEntryFragment
      )> }
    ) }
    & SignalGroupInstanceEntryFragment
  )> }
);

export type AllSignalGroupInstancesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<SignalGroupInstanceFilter>;
}>;


export type AllSignalGroupInstancesQuery = (
  { __typename?: 'Query' }
  & { allSignalGroupInstances?: Maybe<(
    { __typename?: 'SignalGroupInstancesConnection' }
    & Pick<SignalGroupInstancesConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'SignalGroupInstance' }
      & SignalGroupInstanceEntryFragment
    )> }
  )> }
);

export type SignalGroupInstanceHistoryEntryFragment = (
  { __typename?: 'SignalGroupInstanceHistory' }
  & Pick<SignalGroupInstanceHistory, 'id' | 'type' | 'description' | 'createdAt' | 'extra'>
);

export type SignalGroupItemEntryFragment = (
  { __typename?: 'SignalGroupItem' }
  & Pick<SignalGroupItem, 'id' | 'isActive' | 'state' | 'isPerpetual' | 'firstToggleActionValue' | 'cooldownAfterReceivedSignalAmount' | 'cooldownAfterReceivedSignalInterval' | 'resetCooldownAfterGroupTrigger'>
);

export type UpdateSignalGroupItemByIdMutationVariables = Exact<{
  input: UpdateSignalGroupItemByIdInput;
}>;


export type UpdateSignalGroupItemByIdMutation = (
  { __typename?: 'Mutation' }
  & { updateSignalGroupItemById?: Maybe<(
    { __typename?: 'UpdateSignalGroupItemPayload' }
    & { signalGroupItem?: Maybe<(
      { __typename?: 'SignalGroupItem' }
      & SignalGroupItemEntryFragment
    )> }
  )> }
);

export type ClearSignalGroupItemByIdMutationVariables = Exact<{
  input: ClearSignalGroupItemByIdInput;
}>;


export type ClearSignalGroupItemByIdMutation = (
  { __typename?: 'Mutation' }
  & { clearSignalGroupItemById?: Maybe<(
    { __typename?: 'ClearSignalGroupItemByIdPayload' }
    & { signalGroupItem?: Maybe<(
      { __typename?: 'SignalGroupItem' }
      & SignalGroupItemEntryFragment
    )> }
  )> }
);

export type DeleteSignalGroupItemByIdMutationVariables = Exact<{
  input: DeleteSignalGroupItemByIdInput;
}>;


export type DeleteSignalGroupItemByIdMutation = (
  { __typename?: 'Mutation' }
  & { deleteSignalGroupItemById?: Maybe<(
    { __typename?: 'DeleteSignalGroupItemPayload' }
    & Pick<DeleteSignalGroupItemPayload, 'deletedSignalGroupItemId'>
  )> }
);

export type CurrentUserEntryFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'role'>
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & CurrentUserEntryFragment
  )> }
);

export const CredentialsFragmentDoc = gql`
    fragment Credentials on Credentials {
  accessToken
  refreshToken
}
    `;
export const IncomingSignalListEntryFragmentDoc = gql`
    fragment IncomingSignalListEntry on SignalHistory {
  id
  action
  message
  data
  signalBySignalId {
    id
    name
  }
  createdAt
}
    `;
export const SignalEntryFragmentDoc = gql`
    fragment SignalEntry on Signal {
  id
  name
}
    `;
export const SignalByIdEntryFragmentDoc = gql`
    fragment SignalByIdEntry on Signal {
  id
  externalId
  name
  isActive
  runningAt
  createdAt
  customTriggerTemplate
  signalGroupsBySignalGroupItemSignalIdAndSignalGroupId {
    nodes {
      id
      name
    }
  }
}
    `;
export const AllSignalsEntryFragmentDoc = gql`
    fragment AllSignalsEntry on Signal {
  id
  name
  isActive
  createdAt
  signalGroupsBySignalGroupItemSignalIdAndSignalGroupId {
    totalCount
  }
  lastHistory {
    action
    createdAt
  }
}
    `;
export const AllSignalGroupsEntryFragmentDoc = gql`
    fragment AllSignalGroupsEntry on SignalGroup {
  id
  name
  displayStatus
  isActive
  createdAt
  signalGroupItemsBySignalGroupId {
    totalCount
  }
  signalGroupTriggerHistoriesBySignalGroupId {
    totalCount
  }
  lastTrigger {
    endAt
  }
}
    `;
export const SignalGroupItemEntryFragmentDoc = gql`
    fragment SignalGroupItemEntry on SignalGroupItem {
  id
  isActive
  state
  isPerpetual
  firstToggleActionValue
  cooldownAfterReceivedSignalAmount
  cooldownAfterReceivedSignalInterval
  resetCooldownAfterGroupTrigger
}
    `;
export const SignalGroupActionEntryFragmentDoc = gql`
    fragment SignalGroupActionEntry on SignalGroupAction {
  id
  payload
  isActive
  triggerCount
}
    `;
export const SignalGroupEntryFragmentDoc = gql`
    fragment SignalGroupEntry on SignalGroup {
  id
  name
  displayStatus
  reactivateAt
  isActive
  stopAfterTrigger
  cooldownAfterTriggerAmount
  cooldownAfterTriggerInterval
  runningAt
  createdAt
  signalGroupItemsBySignalGroupId {
    nodes {
      ...SignalGroupItemEntry
      signalHistoryByLastSignalHistoryId {
        action
        createdAt
      }
      signalBySignalId {
        id
        name
      }
    }
  }
  signalGroupActionsBySignalGroupId(orderBy: CREATED_AT_ASC) {
    nodes {
      ...SignalGroupActionEntry
    }
  }
}
    ${SignalGroupItemEntryFragmentDoc}
${SignalGroupActionEntryFragmentDoc}`;
export const SignalGroupInstanceEntryFragmentDoc = gql`
    fragment SignalGroupInstanceEntry on SignalGroupInstance {
  id
  startAt
  endAt
  isCurrent
  elapsed
}
    `;
export const SignalGroupInstanceHistoryEntryFragmentDoc = gql`
    fragment SignalGroupInstanceHistoryEntry on SignalGroupInstanceHistory {
  id
  type
  description
  createdAt
  extra
}
    `;
export const CurrentUserEntryFragmentDoc = gql`
    fragment CurrentUserEntry on User {
  id
  username
  firstName
  lastName
  role
}
    `;
export const DashboardAnalyticsDocument = gql`
    query DashboardAnalytics {
  activeSignalAnalytics {
    activeCount
    totalCount
    totalTriggered
  }
  activeSignalGroupAnalytics {
    activeCount
    totalCount
    totalTriggered
  }
  mostRecentSignals: allSignalHistories(orderBy: CREATED_AT_DESC, first: 10) {
    nodes {
      ...IncomingSignalListEntry
    }
  }
}
    ${IncomingSignalListEntryFragmentDoc}`;

/**
 * __useDashboardAnalyticsQuery__
 *
 * To run a query within a React component, call `useDashboardAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardAnalyticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>(DashboardAnalyticsDocument, options);
      }
export function useDashboardAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>(DashboardAnalyticsDocument, options);
        }
export type DashboardAnalyticsQueryHookResult = ReturnType<typeof useDashboardAnalyticsQuery>;
export type DashboardAnalyticsLazyQueryHookResult = ReturnType<typeof useDashboardAnalyticsLazyQuery>;
export type DashboardAnalyticsQueryResult = Apollo.QueryResult<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>;
export const GetAuthDocument = gql`
    query GetAuth {
  auth @client {
    isInitialized
    refreshToken
    accessToken
  }
}
    `;

/**
 * __useGetAuthQuery__
 *
 * To run a query within a React component, call `useGetAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthQuery, GetAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuthQuery, GetAuthQueryVariables>(GetAuthDocument, options);
      }
export function useGetAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthQuery, GetAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuthQuery, GetAuthQueryVariables>(GetAuthDocument, options);
        }
export type GetAuthQueryHookResult = ReturnType<typeof useGetAuthQuery>;
export type GetAuthLazyQueryHookResult = ReturnType<typeof useGetAuthLazyQuery>;
export type GetAuthQueryResult = Apollo.QueryResult<GetAuthQuery, GetAuthQueryVariables>;
export const LoginByUsernameAndPasswordDocument = gql`
    mutation LoginByUsernameAndPassword($username: String!, $password: String!) {
  loginByUsernameAndPassword(input: {username: $username, password: $password}) {
    credentials {
      ...Credentials
    }
  }
}
    ${CredentialsFragmentDoc}`;
export type LoginByUsernameAndPasswordMutationFn = Apollo.MutationFunction<LoginByUsernameAndPasswordMutation, LoginByUsernameAndPasswordMutationVariables>;

/**
 * __useLoginByUsernameAndPasswordMutation__
 *
 * To run a mutation, you first call `useLoginByUsernameAndPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByUsernameAndPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByUsernameAndPasswordMutation, { data, loading, error }] = useLoginByUsernameAndPasswordMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginByUsernameAndPasswordMutation(baseOptions?: Apollo.MutationHookOptions<LoginByUsernameAndPasswordMutation, LoginByUsernameAndPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginByUsernameAndPasswordMutation, LoginByUsernameAndPasswordMutationVariables>(LoginByUsernameAndPasswordDocument, options);
      }
export type LoginByUsernameAndPasswordMutationHookResult = ReturnType<typeof useLoginByUsernameAndPasswordMutation>;
export type LoginByUsernameAndPasswordMutationResult = Apollo.MutationResult<LoginByUsernameAndPasswordMutation>;
export type LoginByUsernameAndPasswordMutationOptions = Apollo.BaseMutationOptions<LoginByUsernameAndPasswordMutation, LoginByUsernameAndPasswordMutationVariables>;
export const LoginByRefreshTokenDocument = gql`
    mutation LoginByRefreshToken($refreshToken: String!) {
  loginByRefreshToken(input: {refreshToken: $refreshToken}) {
    credentials {
      ...Credentials
    }
  }
}
    ${CredentialsFragmentDoc}`;
export type LoginByRefreshTokenMutationFn = Apollo.MutationFunction<LoginByRefreshTokenMutation, LoginByRefreshTokenMutationVariables>;

/**
 * __useLoginByRefreshTokenMutation__
 *
 * To run a mutation, you first call `useLoginByRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByRefreshTokenMutation, { data, loading, error }] = useLoginByRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useLoginByRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<LoginByRefreshTokenMutation, LoginByRefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginByRefreshTokenMutation, LoginByRefreshTokenMutationVariables>(LoginByRefreshTokenDocument, options);
      }
export type LoginByRefreshTokenMutationHookResult = ReturnType<typeof useLoginByRefreshTokenMutation>;
export type LoginByRefreshTokenMutationResult = Apollo.MutationResult<LoginByRefreshTokenMutation>;
export type LoginByRefreshTokenMutationOptions = Apollo.BaseMutationOptions<LoginByRefreshTokenMutation, LoginByRefreshTokenMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($input: RegisterUserInput!) {
  registerUser(input: $input) {
    credentials {
      ...Credentials
    }
  }
}
    ${CredentialsFragmentDoc}`;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const AllIncomingSignalsDocument = gql`
    query AllIncomingSignals($first: Int, $last: Int, $after: Cursor, $before: Cursor, $filter: SignalHistoryFilter) {
  allSignalHistories(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: CREATED_AT_DESC
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...IncomingSignalListEntry
    }
  }
}
    ${IncomingSignalListEntryFragmentDoc}`;

/**
 * __useAllIncomingSignalsQuery__
 *
 * To run a query within a React component, call `useAllIncomingSignalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIncomingSignalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIncomingSignalsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllIncomingSignalsQuery(baseOptions?: Apollo.QueryHookOptions<AllIncomingSignalsQuery, AllIncomingSignalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllIncomingSignalsQuery, AllIncomingSignalsQueryVariables>(AllIncomingSignalsDocument, options);
      }
export function useAllIncomingSignalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllIncomingSignalsQuery, AllIncomingSignalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllIncomingSignalsQuery, AllIncomingSignalsQueryVariables>(AllIncomingSignalsDocument, options);
        }
export type AllIncomingSignalsQueryHookResult = ReturnType<typeof useAllIncomingSignalsQuery>;
export type AllIncomingSignalsLazyQueryHookResult = ReturnType<typeof useAllIncomingSignalsLazyQuery>;
export type AllIncomingSignalsQueryResult = Apollo.QueryResult<AllIncomingSignalsQuery, AllIncomingSignalsQueryVariables>;
export const GetSignalsDocument = gql`
    query GetSignals($value: String!) {
  allSignals(orderBy: NAME_ASC, filter: {name: {includesInsensitive: $value}}) {
    nodes {
      ...SignalEntry
    }
  }
}
    ${SignalEntryFragmentDoc}`;

/**
 * __useGetSignalsQuery__
 *
 * To run a query within a React component, call `useGetSignalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignalsQuery({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useGetSignalsQuery(baseOptions: Apollo.QueryHookOptions<GetSignalsQuery, GetSignalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignalsQuery, GetSignalsQueryVariables>(GetSignalsDocument, options);
      }
export function useGetSignalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignalsQuery, GetSignalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignalsQuery, GetSignalsQueryVariables>(GetSignalsDocument, options);
        }
export type GetSignalsQueryHookResult = ReturnType<typeof useGetSignalsQuery>;
export type GetSignalsLazyQueryHookResult = ReturnType<typeof useGetSignalsLazyQuery>;
export type GetSignalsQueryResult = Apollo.QueryResult<GetSignalsQuery, GetSignalsQueryVariables>;
export const SignalByIdDocument = gql`
    query SignalById($id: UUID!) {
  signalById(id: $id) {
    ...SignalByIdEntry
  }
}
    ${SignalByIdEntryFragmentDoc}`;

/**
 * __useSignalByIdQuery__
 *
 * To run a query within a React component, call `useSignalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignalByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSignalByIdQuery(baseOptions: Apollo.QueryHookOptions<SignalByIdQuery, SignalByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignalByIdQuery, SignalByIdQueryVariables>(SignalByIdDocument, options);
      }
export function useSignalByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignalByIdQuery, SignalByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignalByIdQuery, SignalByIdQueryVariables>(SignalByIdDocument, options);
        }
export type SignalByIdQueryHookResult = ReturnType<typeof useSignalByIdQuery>;
export type SignalByIdLazyQueryHookResult = ReturnType<typeof useSignalByIdLazyQuery>;
export type SignalByIdQueryResult = Apollo.QueryResult<SignalByIdQuery, SignalByIdQueryVariables>;
export const AllSignalsDocument = gql`
    query AllSignals($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [SignalsOrderBy!], $filter: SignalFilter) {
  allSignals(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...AllSignalsEntry
    }
  }
}
    ${AllSignalsEntryFragmentDoc}`;

/**
 * __useAllSignalsQuery__
 *
 * To run a query within a React component, call `useAllSignalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSignalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSignalsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllSignalsQuery(baseOptions?: Apollo.QueryHookOptions<AllSignalsQuery, AllSignalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSignalsQuery, AllSignalsQueryVariables>(AllSignalsDocument, options);
      }
export function useAllSignalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSignalsQuery, AllSignalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSignalsQuery, AllSignalsQueryVariables>(AllSignalsDocument, options);
        }
export type AllSignalsQueryHookResult = ReturnType<typeof useAllSignalsQuery>;
export type AllSignalsLazyQueryHookResult = ReturnType<typeof useAllSignalsLazyQuery>;
export type AllSignalsQueryResult = Apollo.QueryResult<AllSignalsQuery, AllSignalsQueryVariables>;
export const CreateSignalDocument = gql`
    mutation CreateSignal($input: CreateSignalInput!) {
  createSignal(input: $input) {
    signal {
      ...SignalByIdEntry
    }
  }
}
    ${SignalByIdEntryFragmentDoc}`;
export type CreateSignalMutationFn = Apollo.MutationFunction<CreateSignalMutation, CreateSignalMutationVariables>;

/**
 * __useCreateSignalMutation__
 *
 * To run a mutation, you first call `useCreateSignalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignalMutation, { data, loading, error }] = useCreateSignalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSignalMutation(baseOptions?: Apollo.MutationHookOptions<CreateSignalMutation, CreateSignalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSignalMutation, CreateSignalMutationVariables>(CreateSignalDocument, options);
      }
export type CreateSignalMutationHookResult = ReturnType<typeof useCreateSignalMutation>;
export type CreateSignalMutationResult = Apollo.MutationResult<CreateSignalMutation>;
export type CreateSignalMutationOptions = Apollo.BaseMutationOptions<CreateSignalMutation, CreateSignalMutationVariables>;
export const UpdateSignalByIdDocument = gql`
    mutation UpdateSignalById($input: UpdateSignalByIdInput!) {
  updateSignalById(input: $input) {
    signal {
      ...SignalByIdEntry
    }
  }
}
    ${SignalByIdEntryFragmentDoc}`;
export type UpdateSignalByIdMutationFn = Apollo.MutationFunction<UpdateSignalByIdMutation, UpdateSignalByIdMutationVariables>;

/**
 * __useUpdateSignalByIdMutation__
 *
 * To run a mutation, you first call `useUpdateSignalByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSignalByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSignalByIdMutation, { data, loading, error }] = useUpdateSignalByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSignalByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSignalByIdMutation, UpdateSignalByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSignalByIdMutation, UpdateSignalByIdMutationVariables>(UpdateSignalByIdDocument, options);
      }
export type UpdateSignalByIdMutationHookResult = ReturnType<typeof useUpdateSignalByIdMutation>;
export type UpdateSignalByIdMutationResult = Apollo.MutationResult<UpdateSignalByIdMutation>;
export type UpdateSignalByIdMutationOptions = Apollo.BaseMutationOptions<UpdateSignalByIdMutation, UpdateSignalByIdMutationVariables>;
export const DeleteSignalByIdDocument = gql`
    mutation DeleteSignalById($input: DeleteSignalByIdInput!) {
  deleteSignalById(input: $input) {
    deletedSignalId
  }
}
    `;
export type DeleteSignalByIdMutationFn = Apollo.MutationFunction<DeleteSignalByIdMutation, DeleteSignalByIdMutationVariables>;

/**
 * __useDeleteSignalByIdMutation__
 *
 * To run a mutation, you first call `useDeleteSignalByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSignalByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSignalByIdMutation, { data, loading, error }] = useDeleteSignalByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSignalByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSignalByIdMutation, DeleteSignalByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSignalByIdMutation, DeleteSignalByIdMutationVariables>(DeleteSignalByIdDocument, options);
      }
export type DeleteSignalByIdMutationHookResult = ReturnType<typeof useDeleteSignalByIdMutation>;
export type DeleteSignalByIdMutationResult = Apollo.MutationResult<DeleteSignalByIdMutation>;
export type DeleteSignalByIdMutationOptions = Apollo.BaseMutationOptions<DeleteSignalByIdMutation, DeleteSignalByIdMutationVariables>;
export const StartSignalByIdDocument = gql`
    mutation StartSignalById($input: StartSignalByIdInput!) {
  startSignalById(input: $input) {
    signal {
      ...SignalByIdEntry
    }
  }
}
    ${SignalByIdEntryFragmentDoc}`;
export type StartSignalByIdMutationFn = Apollo.MutationFunction<StartSignalByIdMutation, StartSignalByIdMutationVariables>;

/**
 * __useStartSignalByIdMutation__
 *
 * To run a mutation, you first call `useStartSignalByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSignalByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSignalByIdMutation, { data, loading, error }] = useStartSignalByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartSignalByIdMutation(baseOptions?: Apollo.MutationHookOptions<StartSignalByIdMutation, StartSignalByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartSignalByIdMutation, StartSignalByIdMutationVariables>(StartSignalByIdDocument, options);
      }
export type StartSignalByIdMutationHookResult = ReturnType<typeof useStartSignalByIdMutation>;
export type StartSignalByIdMutationResult = Apollo.MutationResult<StartSignalByIdMutation>;
export type StartSignalByIdMutationOptions = Apollo.BaseMutationOptions<StartSignalByIdMutation, StartSignalByIdMutationVariables>;
export const StopSignalByIdDocument = gql`
    mutation StopSignalById($input: StopSignalByIdInput!) {
  stopSignalById(input: $input) {
    signal {
      ...SignalByIdEntry
    }
  }
}
    ${SignalByIdEntryFragmentDoc}`;
export type StopSignalByIdMutationFn = Apollo.MutationFunction<StopSignalByIdMutation, StopSignalByIdMutationVariables>;

/**
 * __useStopSignalByIdMutation__
 *
 * To run a mutation, you first call `useStopSignalByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopSignalByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopSignalByIdMutation, { data, loading, error }] = useStopSignalByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopSignalByIdMutation(baseOptions?: Apollo.MutationHookOptions<StopSignalByIdMutation, StopSignalByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopSignalByIdMutation, StopSignalByIdMutationVariables>(StopSignalByIdDocument, options);
      }
export type StopSignalByIdMutationHookResult = ReturnType<typeof useStopSignalByIdMutation>;
export type StopSignalByIdMutationResult = Apollo.MutationResult<StopSignalByIdMutation>;
export type StopSignalByIdMutationOptions = Apollo.BaseMutationOptions<StopSignalByIdMutation, StopSignalByIdMutationVariables>;
export const SignalGroupByIdDocument = gql`
    query SignalGroupById($id: UUID!) {
  signalGroupById(id: $id) {
    ...SignalGroupEntry
  }
}
    ${SignalGroupEntryFragmentDoc}`;

/**
 * __useSignalGroupByIdQuery__
 *
 * To run a query within a React component, call `useSignalGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignalGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignalGroupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSignalGroupByIdQuery(baseOptions: Apollo.QueryHookOptions<SignalGroupByIdQuery, SignalGroupByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignalGroupByIdQuery, SignalGroupByIdQueryVariables>(SignalGroupByIdDocument, options);
      }
export function useSignalGroupByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignalGroupByIdQuery, SignalGroupByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignalGroupByIdQuery, SignalGroupByIdQueryVariables>(SignalGroupByIdDocument, options);
        }
export type SignalGroupByIdQueryHookResult = ReturnType<typeof useSignalGroupByIdQuery>;
export type SignalGroupByIdLazyQueryHookResult = ReturnType<typeof useSignalGroupByIdLazyQuery>;
export type SignalGroupByIdQueryResult = Apollo.QueryResult<SignalGroupByIdQuery, SignalGroupByIdQueryVariables>;
export const AllSignalGroupsDocument = gql`
    query AllSignalGroups($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [SignalGroupsOrderBy!], $filter: SignalGroupFilter) {
  allSignalGroups(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...AllSignalGroupsEntry
    }
  }
}
    ${AllSignalGroupsEntryFragmentDoc}`;

/**
 * __useAllSignalGroupsQuery__
 *
 * To run a query within a React component, call `useAllSignalGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSignalGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSignalGroupsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllSignalGroupsQuery(baseOptions?: Apollo.QueryHookOptions<AllSignalGroupsQuery, AllSignalGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSignalGroupsQuery, AllSignalGroupsQueryVariables>(AllSignalGroupsDocument, options);
      }
export function useAllSignalGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSignalGroupsQuery, AllSignalGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSignalGroupsQuery, AllSignalGroupsQueryVariables>(AllSignalGroupsDocument, options);
        }
export type AllSignalGroupsQueryHookResult = ReturnType<typeof useAllSignalGroupsQuery>;
export type AllSignalGroupsLazyQueryHookResult = ReturnType<typeof useAllSignalGroupsLazyQuery>;
export type AllSignalGroupsQueryResult = Apollo.QueryResult<AllSignalGroupsQuery, AllSignalGroupsQueryVariables>;
export const CreateSignalGroupDocument = gql`
    mutation CreateSignalGroup($input: CreateSignalGroupInput!) {
  createSignalGroup(input: $input) {
    signalGroup {
      ...SignalGroupEntry
    }
  }
}
    ${SignalGroupEntryFragmentDoc}`;
export type CreateSignalGroupMutationFn = Apollo.MutationFunction<CreateSignalGroupMutation, CreateSignalGroupMutationVariables>;

/**
 * __useCreateSignalGroupMutation__
 *
 * To run a mutation, you first call `useCreateSignalGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignalGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignalGroupMutation, { data, loading, error }] = useCreateSignalGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSignalGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateSignalGroupMutation, CreateSignalGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSignalGroupMutation, CreateSignalGroupMutationVariables>(CreateSignalGroupDocument, options);
      }
export type CreateSignalGroupMutationHookResult = ReturnType<typeof useCreateSignalGroupMutation>;
export type CreateSignalGroupMutationResult = Apollo.MutationResult<CreateSignalGroupMutation>;
export type CreateSignalGroupMutationOptions = Apollo.BaseMutationOptions<CreateSignalGroupMutation, CreateSignalGroupMutationVariables>;
export const UpdateSignalGroupByIdDocument = gql`
    mutation UpdateSignalGroupById($input: UpdateSignalGroupByIdInput!) {
  updateSignalGroupById(input: $input) {
    signalGroup {
      ...SignalGroupEntry
    }
  }
}
    ${SignalGroupEntryFragmentDoc}`;
export type UpdateSignalGroupByIdMutationFn = Apollo.MutationFunction<UpdateSignalGroupByIdMutation, UpdateSignalGroupByIdMutationVariables>;

/**
 * __useUpdateSignalGroupByIdMutation__
 *
 * To run a mutation, you first call `useUpdateSignalGroupByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSignalGroupByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSignalGroupByIdMutation, { data, loading, error }] = useUpdateSignalGroupByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSignalGroupByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSignalGroupByIdMutation, UpdateSignalGroupByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSignalGroupByIdMutation, UpdateSignalGroupByIdMutationVariables>(UpdateSignalGroupByIdDocument, options);
      }
export type UpdateSignalGroupByIdMutationHookResult = ReturnType<typeof useUpdateSignalGroupByIdMutation>;
export type UpdateSignalGroupByIdMutationResult = Apollo.MutationResult<UpdateSignalGroupByIdMutation>;
export type UpdateSignalGroupByIdMutationOptions = Apollo.BaseMutationOptions<UpdateSignalGroupByIdMutation, UpdateSignalGroupByIdMutationVariables>;
export const DeleteSignalGroupByIdDocument = gql`
    mutation DeleteSignalGroupById($input: DeleteSignalGroupByIdInput!) {
  deleteSignalGroupById(input: $input) {
    deletedSignalGroupId
  }
}
    `;
export type DeleteSignalGroupByIdMutationFn = Apollo.MutationFunction<DeleteSignalGroupByIdMutation, DeleteSignalGroupByIdMutationVariables>;

/**
 * __useDeleteSignalGroupByIdMutation__
 *
 * To run a mutation, you first call `useDeleteSignalGroupByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSignalGroupByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSignalGroupByIdMutation, { data, loading, error }] = useDeleteSignalGroupByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSignalGroupByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSignalGroupByIdMutation, DeleteSignalGroupByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSignalGroupByIdMutation, DeleteSignalGroupByIdMutationVariables>(DeleteSignalGroupByIdDocument, options);
      }
export type DeleteSignalGroupByIdMutationHookResult = ReturnType<typeof useDeleteSignalGroupByIdMutation>;
export type DeleteSignalGroupByIdMutationResult = Apollo.MutationResult<DeleteSignalGroupByIdMutation>;
export type DeleteSignalGroupByIdMutationOptions = Apollo.BaseMutationOptions<DeleteSignalGroupByIdMutation, DeleteSignalGroupByIdMutationVariables>;
export const UpdateEntireSignalGroupByIdDocument = gql`
    mutation UpdateEntireSignalGroupById($input: UpdateEntireSignalGroupByIdInput!) {
  updateEntireSignalGroupById(input: $input) {
    signalGroup {
      ...SignalGroupEntry
    }
  }
}
    ${SignalGroupEntryFragmentDoc}`;
export type UpdateEntireSignalGroupByIdMutationFn = Apollo.MutationFunction<UpdateEntireSignalGroupByIdMutation, UpdateEntireSignalGroupByIdMutationVariables>;

/**
 * __useUpdateEntireSignalGroupByIdMutation__
 *
 * To run a mutation, you first call `useUpdateEntireSignalGroupByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntireSignalGroupByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntireSignalGroupByIdMutation, { data, loading, error }] = useUpdateEntireSignalGroupByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEntireSignalGroupByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntireSignalGroupByIdMutation, UpdateEntireSignalGroupByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntireSignalGroupByIdMutation, UpdateEntireSignalGroupByIdMutationVariables>(UpdateEntireSignalGroupByIdDocument, options);
      }
export type UpdateEntireSignalGroupByIdMutationHookResult = ReturnType<typeof useUpdateEntireSignalGroupByIdMutation>;
export type UpdateEntireSignalGroupByIdMutationResult = Apollo.MutationResult<UpdateEntireSignalGroupByIdMutation>;
export type UpdateEntireSignalGroupByIdMutationOptions = Apollo.BaseMutationOptions<UpdateEntireSignalGroupByIdMutation, UpdateEntireSignalGroupByIdMutationVariables>;
export const UpdateSignalGroupActionByIdDocument = gql`
    mutation UpdateSignalGroupActionById($input: UpdateSignalGroupActionByIdInput!) {
  updateSignalGroupActionById(input: $input) {
    signalGroupAction {
      ...SignalGroupActionEntry
    }
  }
}
    ${SignalGroupActionEntryFragmentDoc}`;
export type UpdateSignalGroupActionByIdMutationFn = Apollo.MutationFunction<UpdateSignalGroupActionByIdMutation, UpdateSignalGroupActionByIdMutationVariables>;

/**
 * __useUpdateSignalGroupActionByIdMutation__
 *
 * To run a mutation, you first call `useUpdateSignalGroupActionByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSignalGroupActionByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSignalGroupActionByIdMutation, { data, loading, error }] = useUpdateSignalGroupActionByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSignalGroupActionByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSignalGroupActionByIdMutation, UpdateSignalGroupActionByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSignalGroupActionByIdMutation, UpdateSignalGroupActionByIdMutationVariables>(UpdateSignalGroupActionByIdDocument, options);
      }
export type UpdateSignalGroupActionByIdMutationHookResult = ReturnType<typeof useUpdateSignalGroupActionByIdMutation>;
export type UpdateSignalGroupActionByIdMutationResult = Apollo.MutationResult<UpdateSignalGroupActionByIdMutation>;
export type UpdateSignalGroupActionByIdMutationOptions = Apollo.BaseMutationOptions<UpdateSignalGroupActionByIdMutation, UpdateSignalGroupActionByIdMutationVariables>;
export const DeleteSignalGroupActionByIdDocument = gql`
    mutation DeleteSignalGroupActionById($input: DeleteSignalGroupActionByIdInput!) {
  deleteSignalGroupActionById(input: $input) {
    deletedSignalGroupActionId
  }
}
    `;
export type DeleteSignalGroupActionByIdMutationFn = Apollo.MutationFunction<DeleteSignalGroupActionByIdMutation, DeleteSignalGroupActionByIdMutationVariables>;

/**
 * __useDeleteSignalGroupActionByIdMutation__
 *
 * To run a mutation, you first call `useDeleteSignalGroupActionByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSignalGroupActionByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSignalGroupActionByIdMutation, { data, loading, error }] = useDeleteSignalGroupActionByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSignalGroupActionByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSignalGroupActionByIdMutation, DeleteSignalGroupActionByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSignalGroupActionByIdMutation, DeleteSignalGroupActionByIdMutationVariables>(DeleteSignalGroupActionByIdDocument, options);
      }
export type DeleteSignalGroupActionByIdMutationHookResult = ReturnType<typeof useDeleteSignalGroupActionByIdMutation>;
export type DeleteSignalGroupActionByIdMutationResult = Apollo.MutationResult<DeleteSignalGroupActionByIdMutation>;
export type DeleteSignalGroupActionByIdMutationOptions = Apollo.BaseMutationOptions<DeleteSignalGroupActionByIdMutation, DeleteSignalGroupActionByIdMutationVariables>;
export const SignalGroupInstanceByIdDocument = gql`
    query SignalGroupInstanceById($id: UUID!) {
  signalGroupInstanceById(id: $id) {
    ...SignalGroupInstanceEntry
    signalGroupBySignalGroupId {
      name
      id
    }
    signalGroupInstanceHistoriesByInstanceId(
      orderBy: CREATED_AT_DESC
      condition: {type: FINAL_SIGNAL_STATE}
    ) {
      nodes {
        ...SignalGroupInstanceHistoryEntry
      }
    }
  }
}
    ${SignalGroupInstanceEntryFragmentDoc}
${SignalGroupInstanceHistoryEntryFragmentDoc}`;

/**
 * __useSignalGroupInstanceByIdQuery__
 *
 * To run a query within a React component, call `useSignalGroupInstanceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignalGroupInstanceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignalGroupInstanceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSignalGroupInstanceByIdQuery(baseOptions: Apollo.QueryHookOptions<SignalGroupInstanceByIdQuery, SignalGroupInstanceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignalGroupInstanceByIdQuery, SignalGroupInstanceByIdQueryVariables>(SignalGroupInstanceByIdDocument, options);
      }
export function useSignalGroupInstanceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignalGroupInstanceByIdQuery, SignalGroupInstanceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignalGroupInstanceByIdQuery, SignalGroupInstanceByIdQueryVariables>(SignalGroupInstanceByIdDocument, options);
        }
export type SignalGroupInstanceByIdQueryHookResult = ReturnType<typeof useSignalGroupInstanceByIdQuery>;
export type SignalGroupInstanceByIdLazyQueryHookResult = ReturnType<typeof useSignalGroupInstanceByIdLazyQuery>;
export type SignalGroupInstanceByIdQueryResult = Apollo.QueryResult<SignalGroupInstanceByIdQuery, SignalGroupInstanceByIdQueryVariables>;
export const AllSignalGroupInstancesDocument = gql`
    query AllSignalGroupInstances($first: Int, $last: Int, $after: Cursor, $before: Cursor, $filter: SignalGroupInstanceFilter) {
  allSignalGroupInstances(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: END_AT_DESC
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...SignalGroupInstanceEntry
    }
  }
}
    ${SignalGroupInstanceEntryFragmentDoc}`;

/**
 * __useAllSignalGroupInstancesQuery__
 *
 * To run a query within a React component, call `useAllSignalGroupInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSignalGroupInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSignalGroupInstancesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllSignalGroupInstancesQuery(baseOptions?: Apollo.QueryHookOptions<AllSignalGroupInstancesQuery, AllSignalGroupInstancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSignalGroupInstancesQuery, AllSignalGroupInstancesQueryVariables>(AllSignalGroupInstancesDocument, options);
      }
export function useAllSignalGroupInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSignalGroupInstancesQuery, AllSignalGroupInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSignalGroupInstancesQuery, AllSignalGroupInstancesQueryVariables>(AllSignalGroupInstancesDocument, options);
        }
export type AllSignalGroupInstancesQueryHookResult = ReturnType<typeof useAllSignalGroupInstancesQuery>;
export type AllSignalGroupInstancesLazyQueryHookResult = ReturnType<typeof useAllSignalGroupInstancesLazyQuery>;
export type AllSignalGroupInstancesQueryResult = Apollo.QueryResult<AllSignalGroupInstancesQuery, AllSignalGroupInstancesQueryVariables>;
export const UpdateSignalGroupItemByIdDocument = gql`
    mutation UpdateSignalGroupItemById($input: UpdateSignalGroupItemByIdInput!) {
  updateSignalGroupItemById(input: $input) {
    signalGroupItem {
      ...SignalGroupItemEntry
    }
  }
}
    ${SignalGroupItemEntryFragmentDoc}`;
export type UpdateSignalGroupItemByIdMutationFn = Apollo.MutationFunction<UpdateSignalGroupItemByIdMutation, UpdateSignalGroupItemByIdMutationVariables>;

/**
 * __useUpdateSignalGroupItemByIdMutation__
 *
 * To run a mutation, you first call `useUpdateSignalGroupItemByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSignalGroupItemByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSignalGroupItemByIdMutation, { data, loading, error }] = useUpdateSignalGroupItemByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSignalGroupItemByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSignalGroupItemByIdMutation, UpdateSignalGroupItemByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSignalGroupItemByIdMutation, UpdateSignalGroupItemByIdMutationVariables>(UpdateSignalGroupItemByIdDocument, options);
      }
export type UpdateSignalGroupItemByIdMutationHookResult = ReturnType<typeof useUpdateSignalGroupItemByIdMutation>;
export type UpdateSignalGroupItemByIdMutationResult = Apollo.MutationResult<UpdateSignalGroupItemByIdMutation>;
export type UpdateSignalGroupItemByIdMutationOptions = Apollo.BaseMutationOptions<UpdateSignalGroupItemByIdMutation, UpdateSignalGroupItemByIdMutationVariables>;
export const ClearSignalGroupItemByIdDocument = gql`
    mutation ClearSignalGroupItemById($input: ClearSignalGroupItemByIdInput!) {
  clearSignalGroupItemById(input: $input) {
    signalGroupItem {
      ...SignalGroupItemEntry
    }
  }
}
    ${SignalGroupItemEntryFragmentDoc}`;
export type ClearSignalGroupItemByIdMutationFn = Apollo.MutationFunction<ClearSignalGroupItemByIdMutation, ClearSignalGroupItemByIdMutationVariables>;

/**
 * __useClearSignalGroupItemByIdMutation__
 *
 * To run a mutation, you first call `useClearSignalGroupItemByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearSignalGroupItemByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearSignalGroupItemByIdMutation, { data, loading, error }] = useClearSignalGroupItemByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearSignalGroupItemByIdMutation(baseOptions?: Apollo.MutationHookOptions<ClearSignalGroupItemByIdMutation, ClearSignalGroupItemByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearSignalGroupItemByIdMutation, ClearSignalGroupItemByIdMutationVariables>(ClearSignalGroupItemByIdDocument, options);
      }
export type ClearSignalGroupItemByIdMutationHookResult = ReturnType<typeof useClearSignalGroupItemByIdMutation>;
export type ClearSignalGroupItemByIdMutationResult = Apollo.MutationResult<ClearSignalGroupItemByIdMutation>;
export type ClearSignalGroupItemByIdMutationOptions = Apollo.BaseMutationOptions<ClearSignalGroupItemByIdMutation, ClearSignalGroupItemByIdMutationVariables>;
export const DeleteSignalGroupItemByIdDocument = gql`
    mutation DeleteSignalGroupItemById($input: DeleteSignalGroupItemByIdInput!) {
  deleteSignalGroupItemById(input: $input) {
    deletedSignalGroupItemId
  }
}
    `;
export type DeleteSignalGroupItemByIdMutationFn = Apollo.MutationFunction<DeleteSignalGroupItemByIdMutation, DeleteSignalGroupItemByIdMutationVariables>;

/**
 * __useDeleteSignalGroupItemByIdMutation__
 *
 * To run a mutation, you first call `useDeleteSignalGroupItemByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSignalGroupItemByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSignalGroupItemByIdMutation, { data, loading, error }] = useDeleteSignalGroupItemByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSignalGroupItemByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSignalGroupItemByIdMutation, DeleteSignalGroupItemByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSignalGroupItemByIdMutation, DeleteSignalGroupItemByIdMutationVariables>(DeleteSignalGroupItemByIdDocument, options);
      }
export type DeleteSignalGroupItemByIdMutationHookResult = ReturnType<typeof useDeleteSignalGroupItemByIdMutation>;
export type DeleteSignalGroupItemByIdMutationResult = Apollo.MutationResult<DeleteSignalGroupItemByIdMutation>;
export type DeleteSignalGroupItemByIdMutationOptions = Apollo.BaseMutationOptions<DeleteSignalGroupItemByIdMutation, DeleteSignalGroupItemByIdMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...CurrentUserEntry
  }
}
    ${CurrentUserEntryFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "Query",
      "Signal",
      "User",
      "SignalGroup",
      "SignalGroupAction",
      "SignalGroupActionTriggerHistory",
      "SignalGroupTriggerHistory",
      "SignalGroupItem",
      "SignalHistory",
      "SignalGroupInstance",
      "SignalGroupCurrentInstance",
      "SignalGroupInstanceHistory"
    ]
  }
};
      export default result;
    