import React, { FunctionComponent } from 'react';

type Props = {
  style?: any;
};
const CardHorizontalLine: FunctionComponent<Props> = ({ style }) => (
  <hr
    style={{
      borderTop: '0px',
      borderRight: '0px',
      borderLeft: '0px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      margin: 0,
      ...style,
    }}
  />
);
export default CardHorizontalLine;
