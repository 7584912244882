import { createStyles, TableSortLabel, withStyles } from '@material-ui/core';

const StyledTableSortLabel = withStyles(() =>
  createStyles({
    root: {
      cursor: 'pointer',
      display: 'inline-flex',
      justifyContent: 'flex-start',
      flexDirection: 'inherit',
      alignItems: 'center',
      '&:focus': {
        color: '#3a3a3a0',
      },
      '&:hover': {
        color: '#3a3a3a0',
        '&& $icon': {
          // opacity: 0.5,
          color: '#3a3a3a0',
        },
      },
      '&$active': {
        color: '#3a3a3a0',
        // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
        '&& $icon': {
          opacity: 1,
          color: '#3a3a3a0',
        },
      },
    },
    // don't remove empty active and icon because bug
    active: {},
    icon: {},
  }),
)(TableSortLabel);

export default StyledTableSortLabel;
