import { Chip, makeStyles } from '@material-ui/core';
import Icon from '@material-ui/icons/AlarmOff';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  status: {
    backgroundColor: '#FBE9E7',
    border: '1px solid rgba(191,54,12,0.5)',
    borderRadius: '4px',
    color: '#BF360C',
    fontWeight: 500,
  },
  icon: {
    color: '#BF360C',
  },
}));

type Props = {
  label?: string;
  icon?: boolean;
};
const FailChip: FunctionComponent<Props> = ({
  label = 'failed',
  icon = true,
}) => {
  const classes = useStyles();

  return (
    <Chip
      size="small"
      label={label}
      className={classes.status}
      icon={icon ? <Icon className={classes.icon} /> : undefined}
    />
  );
};

export default FailChip;
