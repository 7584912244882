import {
  Box,
  Container,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import BrandLogoLight from 'assets/brand-icon-nameDark-transparent.png';
import SignalHooksGlance from 'assets/signalhooks-at-a-glance.png';
import SignalHooksPreview from 'assets/signalhooks-preview.png';
import HowItWorksTradingView from 'assets/how-it-works-tradingview.png';
import HowItWorksSignalHooks from 'assets/how-it-works-signalhooks.png';
import HowItWorks3Commas from 'assets/how-it-works-3commas.png';
import Button from 'components/Button';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { openInNewTab } from 'utils/general';

const useStyles = makeStyles((theme) => ({
  brandlogo: {
    margin: '1rem 0 1rem',
  },
  sectionHeader: {
    margin: '1rem 0 5rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  sectionHeaderText: {
    maxWidth: '500px',
    [theme.breakpoints.down('md')]: {
      order: '2',
      textAlign: 'center',
      alignItems: 'center',
    },
  },
  sectionBody: { margin: '1rem 0 1rem', padding: '6rem 0' },
  sectionWorks: {
    margin: '1rem 0 1rem',
  },
  sectionSteps: {
    // boxShadow:
    //   'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
    // '&:hover': {
    //   boxShadow:
    //     'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
    // },
    borderRadius: '1rem',
    padding: '2.5rem 1rem 1rem',
    margin: '1.5rem auto 1rem',
    maxWidth: '22rem',
  },
  signalhooksglance: {
    margin: '0.5rem 0 1rem',
    maxWidth: '320px',
  },
  heading: {
    color: '#2a404c',
    fontSize: '2.8rem',
    fontWeight: 400,
    margin: '1rem 0 1.3rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  subheading: {
    color: 'rgba(42, 64, 76, 0.87)',
    fontSize: '1.2rem',
    margin: '0 0 1.4rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  subheadingSecondary: {
    color: 'rgba(0,0,0,0.7)',
    fontSize: '1rem',
    fontWeight: 400,
    margin: '1rem auto 1rem',
    maxWidth: '16rem',
  },
  signin: {
    borderRadius: '10rem',
    fontSize: '1rem',
    textTransform: 'capitalize',
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0.4rem',
      fontSize: '0.8rem',
      padding: '0.3rem 0.7rem',
    },
  },
  signupCTA1: {
    backgroundColor: '#ff307b',
    '&:hover': {
      backgroundColor: '#CC2763',
    },
    border: '0px solid #fff',
    borderRadius: '10rem',
    color: '#fff',
    fontSize: '1.1rem',
    padding: '0.5rem 1.5rem',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.05rem',
      padding: '0.4rem 1.25rem',
    },
  },
  docCTA: {
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#f7fafc',
    },
    border: '0px solid #f7fafc',
    borderRadius: '10rem',
    color: 'rgba(0,0,0,0.75)',
    fontSize: '0.9rem',
    padding: '0.6rem 1.5rem',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.85rem',
      padding: '0.4rem 1.25rem',
    },
  },
  iconWorks: {
    color: '#fff',
  },
  signalhooksPreview: {
    margin: '0.5rem 0 1rem',
    maxWidth: '600px',
  },
  howItWorksImg: {
    margin: '0.5rem 0 1rem',
    maxWidth: '500px',
  },
}));

const LandingPage: FunctionComponent = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Box>
      <Box>
        <Container maxWidth="lg">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box flexGrow={1}>
              <img
                src={BrandLogoLight}
                alt="SignalHooks"
                style={{ width: '180px' }}
                className={classes.brandlogo}
              />
            </Box>
            <Box>
              <Button
                text="Sign Into Account"
                fullWidth
                className={classes.signin}
                onClick={() => history.push('/signIn')}
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box style={{ padding: '0 0rem' }}>
        <Container maxWidth="lg">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            className={classes.sectionHeader}
          >
            <Box
              display="flex"
              flexDirection="column"
              className={classes.sectionHeaderText}
            >
              <Typography variant="h2" gutterBottom className={classes.heading}>
                #1 platform to build crypto bot trading strategies
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.subheading}
              >
                Combine TradingView price levels, indicators, and trend line alerts to
                trigger buy/sell orders on 3Commas.
              </Typography>
              <Box display="flex">
                <Button
                  text="Start trading with an edge"
                  fullWidth
                  className={classes.signupCTA1}
                  onClick={() => history.push('/signUp')}
                />
              </Box>
              <Box display="flex" style={{ marginTop: '0.75em' }}>
                <Button
                  text="or read the documentation here"
                  fullWidth
                  className={classes.docCTA}
                  onClick={() => openInNewTab('https://docs.signalhooks.com')}
                />
              </Box>
            </Box>
            <img
              src={SignalHooksGlance}
              alt="SignalHooks"
              style={{ width: '100%' }}
              className={classes.signalhooksglance}
            />
          </Box>
        </Container>
        <Box style={{ background: '#F7FAFC' }}>
          <Container maxWidth="md">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              className={classes.sectionBody}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.sectionWorks}
                >
                    <img
                      src={SignalHooksPreview}
                      alt="SignalHooks"
                      style={{ width: '100%' }}
                      className={classes.signalhooksPreview}
                    />
                  <Typography variant="h4" gutterBottom>
                    How does SignalHooks work?
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.subheading}
                  >
                    SignalHooks combines multiple alerts from TradingView to
                    give traders the edge in fine-tuning their entry/exits. Once
                    all alerts are triggered, SignalHooks sends 3Commas a
                    message to take action.
                  </Typography>
                  <Typography variant="body2">Indicators used in example are from <Link href="http://trendespresso.com">TrendEspresso</Link></Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box style={{ background: '#ffffff' }}>
          <Container maxWidth="sm">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              className={classes.sectionBody}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.sectionWorks}
                >
                  <img
                    src={HowItWorksTradingView}
                    alt="SignalHooks"
                    style={{ width: '100%' }}
                    className={classes.howItWorksImg}
                  />
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.subheading}
                  >
                    Alerts created on TradingView are tied to Signals. Signals change their state based on incoming message from the alert, where 1 alert can set the Signal state to ON and another alert can set state to OFF.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box style={{ background: '#ffffff' }}>
          <Container maxWidth="sm">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              className={classes.sectionBody}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.sectionWorks}
                >
                  <img
                    src={HowItWorksSignalHooks}
                    alt="SignalHooks"
                    style={{ width: '100%' }}
                    className={classes.howItWorksImg}
                  />
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.subheading}
                  >
                    Relevant Signals are grouped according to your strategy. Signal Groups wait for all Signal states to be triggered ON.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box style={{ background: '#ffffff' }}>
          <Container maxWidth="sm">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              className={classes.sectionBody}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.sectionWorks}
                >
                  <img
                    src={HowItWorks3Commas}
                    alt="SignalHooks"
                    style={{ width: '100%' }}
                    className={classes.howItWorksImg}
                  />
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.subheading}
                  >
                    Once a Signal Group is triggered, all 3Commas bots associated with that group received a relevant message, whether that is to start/stop bot or close at market price.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box style={{ background: '#F7FAFC' }}>
          <Container maxWidth="lg">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              className={classes.sectionBody}
            >
              <Typography
                variant="body1"
                gutterBottom
                className={classes.subheading}
              >
                SignalHooks
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPage;
