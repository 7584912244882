import {
  ClearSignalGroupItemByIdInput,
  DeleteSignalGroupItemByIdInput,
  SignalGroupItemPatch,
  useClearSignalGroupItemByIdMutation,
  useDeleteSignalGroupItemByIdMutation,
  useUpdateSignalGroupItemByIdMutation,
} from 'graphql/graphql-types';
import { useCallback } from 'react';
import { logError } from 'utils/logging';

export const useUpdateSignalGroupItem = () => {
  const [commit] = useUpdateSignalGroupItemByIdMutation();

  const updateSignalGroupItem = useCallback(
    (signalGroupItemId: string, data: SignalGroupItemPatch) => {
      const execute = async () => {
        try {
          await commit({
            variables: {
              input: {
                id: signalGroupItemId,
                signalGroupItemPatch: data,
              },
            },
          });
        } catch (e) {
          logError(e);
        }
      };
      execute();
    },
    [],
  );

  return { updateSignalGroupItem };
};

export const useClearSignalGroupItem = () => {
  const [commit] = useClearSignalGroupItemByIdMutation();

  const clearSignalGroupItem = useCallback(
    (data: ClearSignalGroupItemByIdInput) => {
      const execute = async () => {
        try {
          await commit({
            variables: {
              input: data,
            },
          });
        } catch (e) {
          logError(e);
        }
      };
      execute();
    },
    [],
  );

  return { clearSignalGroupItem };
};

export const useDeleteSignalGroupItem = () => {
  const [commit] = useDeleteSignalGroupItemByIdMutation();

  const deleteSignalGroupItem = useCallback(
    (data: DeleteSignalGroupItemByIdInput) => {
      const execute = async () => {
        try {
          await commit({
            variables: {
              input: data,
            },
            update(cache) {
              const normalizedId = cache.identify({
                id: data.id,
                __typename: 'SignalGroupItem',
              });
              cache.evict({ id: normalizedId });
              cache.gc();
            },
          });
        } catch (e) {
          logError(e);
        }
      };
      execute();
    },
    [],
  );

  return { deleteSignalGroupItem };
};
