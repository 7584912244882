import SignalGroupForm from 'components/SignalGroupForm';
import {
  CooldownAfterTriggerInterval,
  SignalGroupItemCooldownAfterReceivedSignalInterval,
  SignalGroupItemFirstToggleActionValue,
} from 'graphql/graphql-types';
import { useCreateSignalGroup } from 'hooks/signalGroup';
import React, { FunctionComponent } from 'react';

const SignalGroupCreateScreen: FunctionComponent = () => {
  const { createSignalGroup } = useCreateSignalGroup();

  return (
    <SignalGroupForm
      type="create"
      onSubmit={async (data) => {
        createSignalGroup({
          name: data.name,
          stopAfterTrigger: data.afterTrigger === 'stop',
          cooldownAfterTriggerAmount:
            data.afterTrigger === 'cooldown'
              ? data.cooldownAfterTriggerAmount
              : null,
          cooldownAfterTriggerInterval:
            data.afterTrigger === 'cooldown'
              ? (data.cooldownAfterTriggerInterval as CooldownAfterTriggerInterval)
              : null,
          signals: data.signals.map((s) => ({
            id: s.id,
            isPerpetual: s.isPerpetual,
            firstToggleActionValue: s.firstToggleActionValue as SignalGroupItemFirstToggleActionValue,
            cooldownAfterReceivedSignalAmount: s.cooldownAfterReceivedSettings
              ? s.cooldownAfterReceivedSignalAmount
              : null,
            cooldownAfterReceivedSignalInterval: s.cooldownAfterReceivedSettings
              ? (s.cooldownAfterReceivedSignalInterval as SignalGroupItemCooldownAfterReceivedSignalInterval)
              : null,
            resetCooldownAfterGroupTrigger: s.cooldownAfterReceivedSettings
              ? s.resetCooldownAfterGroupTrigger
              : null,
          })),
          actions: data.actions.map((a) => a.payload),
        });
      }}
    />
  );
};

export default SignalGroupCreateScreen;
