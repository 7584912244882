import {
  CreateSignalGroupInput,
  SignalGroupPatch,
  useCreateSignalGroupMutation,
  useDeleteSignalGroupByIdMutation,
  useUpdateSignalGroupByIdMutation,
  useUpdateEntireSignalGroupByIdMutation,
  UpdateEntireSignalGroupByIdInput,
} from 'graphql/graphql-types';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { logError } from 'utils/logging';

export const useCreateSignalGroup = () => {
  const history = useHistory();
  const [commit] = useCreateSignalGroupMutation();

  const createSignalGroup = useCallback((data: CreateSignalGroupInput) => {
    const execute = async () => {
      try {
        const result = await commit({
          variables: {
            input: data,
          },
        });
        history.push(
          `/signalGroups/${result.data?.createSignalGroup?.signalGroup?.id}`,
        );
      } catch (e) {
        logError(e);
      }
    };
    execute();
  }, []);

  return { createSignalGroup };
};

export const useUpdateSignalGroup = (signalGroupId: string) => {
  const [commit] = useUpdateSignalGroupByIdMutation();

  const updateSignalGroup = useCallback(
    (data: SignalGroupPatch) => {
      const execute = async () => {
        try {
          await commit({
            variables: {
              input: {
                id: signalGroupId,
                signalGroupPatch: data,
              },
            },
          });
        } catch (e) {
          logError(e);
        }
      };
      if (signalGroupId) {
        execute();
      }
    },
    [signalGroupId],
  );

  return { updateSignalGroup };
};

export const useUpdateEntireSignalGroup = () => {
  const history = useHistory();
  const [commit] = useUpdateEntireSignalGroupByIdMutation();

  const updateEntireSignalGroup = useCallback(
    async (data: UpdateEntireSignalGroupByIdInput) => {
      try {
        await commit({
          variables: {
            input: data,
          },
        });
        history.push(`/signalGroups/${data.id}`);
      } catch (e) {
        logError(e);
      }
    },
    [],
  );

  return { updateEntireSignalGroup };
};

export const useDeleteSignalGroup = (signalGroupId: string) => {
  const history = useHistory();
  const [commit] = useDeleteSignalGroupByIdMutation();

  const deleteSignalGroup = useCallback(() => {
    const execute = async () => {
      try {
        await commit({
          variables: {
            input: {
              id: signalGroupId,
            },
          },
        });
        history.push('/signalGroups');
      } catch (e) {
        logError(e);
      }
    };
    if (signalGroupId) {
      execute();
    }
  }, [signalGroupId]);

  return { deleteSignalGroup };
};
