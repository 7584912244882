import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { useStyles } from './styles';

export type Props = {
  hide?: boolean;
};
export default ({ hide = false }: Props) => {
  const classes = useStyles();
  if (hide) {
    return null;
  }
  return (
    <Box className={classes.headerBottomDividerContainer}>
      <Divider />
    </Box>
  );
};
