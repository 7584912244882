import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import 'typeface-roboto';
import { ENV, IS_LOCAL } from 'utils/env';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn:
    'https://e988930b351e4294b237f4c37214bcab@o877984.ingest.sentry.io/5829257',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  enabled: !IS_LOCAL,
  environment: ENV,
  tracesSampleRate: 1.0,
});

ReactGA.initialize('G-33M84S771F');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
