import {
  Box,
  Breadcrumbs,
  CardContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import LoopIcon from '@material-ui/icons/Loop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import StopIcon from '@material-ui/icons/Stop';
import ConfirmAlert from 'components/Alerts/ConfirmAlert';
import Card from 'components/Card';
import FailCircle from 'components/Chips/FailCircle';
import NeutralCircle from 'components/Chips/NeutralCircle';
import StoppedCircle from 'components/Chips/StoppedCircle';
import SuccessCircle from 'components/Chips/SuccessCircle';
import CopyTextButton from 'components/CopyTextButton';
import LightTooltip from 'components/LightTooltip';
import LineItems from 'components/LineItems';
import Link from 'components/Link';
import PostTriggerOption from 'components/PostTriggerOption';
import SignalActionPill from 'components/SignalActionPill';
import SignalGroupStatusPill from 'components/SignalGroupStatusPill';
import Table, { SimpleTable } from 'components/Table';
import ViewScreen from 'components/ViewScreen';
import {
  AllSignalGroupInstancesDocument,
  SignalGroupItemState,
  useSignalGroupByIdQuery,
} from 'graphql/graphql-types';
import { useModal, useSelectedValueModal } from 'hooks/modal';
import { useDeleteSignalGroup, useUpdateSignalGroup } from 'hooks/signalGroup';
import {
  useDeleteSignalGroupAction,
  useUpdateSignalGroupAction,
} from 'hooks/signalGroupAction';
import {
  useClearSignalGroupItem,
  useDeleteSignalGroupItem,
  useUpdateSignalGroupItem,
} from 'hooks/signalGroupItem';
import React, { FunctionComponent, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { displayElapsedTime, formatDateTime } from 'utils/time';

const instanceHistoryColumns = [
  {
    label: '',
    name: 'isCurrent',
    width: 1,
    render: ({ value }: any) =>
      value ? (
        <LoopIcon
          fontSize="small"
          style={{ color: 'rgba(80, 100, 110, 0.7)', verticalAlign: 'middle' }}
        />
      ) : (
        <CheckCircleIcon
          fontSize="small"
          style={{ color: 'rgba(80, 100, 110, 0.5)', verticalAlign: 'middle' }}
        />
      ),
    disableFilter: true,
  },
  {
    label: 'Start',
    name: 'startAt',
    render: ({ value }: any) => (
      <Box display="flex">
        <Box>{formatDateTime(value)}</Box>
      </Box>
    ),
    disableFilter: true,
  },
  {
    label: 'End',
    name: 'endAt',
    render: ({ value }: any) => formatDateTime(value),
    disableFilter: true,
  },
  {
    label: 'Elapsed',
    name: 'elapsed',
    width: 176,
    render: ({ value }: any) => displayElapsedTime(value),
    disableFilter: true,
  },
];

const SignalGroupViewScreen: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { data, loading, error, refetch } = useSignalGroupByIdQuery({
    variables: {
      id,
    },
    pollInterval: 3000,
  });
  const group = data?.signalGroupById;
  const name = group?.name ?? '';
  const displayStatus = group?.displayStatus;
  const isActive = group?.isActive;
  const runningAt = group?.runningAt;
  const createdAt = group?.createdAt;
  const reactivateAt = group?.reactivateAt;
  const stopAfterTrigger = group?.stopAfterTrigger;
  const cooldownAmount = group?.cooldownAfterTriggerAmount ?? undefined;
  const cooldownInterval = group?.cooldownAfterTriggerInterval ?? undefined;
  const items = group?.signalGroupItemsBySignalGroupId?.nodes ?? [];
  const actions = group?.signalGroupActionsBySignalGroupId?.nodes ?? [];
  const instanceHistoryCondition = {
    signalGroupId: {
      equalTo: id,
    },
  };

  const { updateSignalGroup } = useUpdateSignalGroup(id);
  const { deleteSignalGroup } = useDeleteSignalGroup(id);
  const { updateSignalGroupItem } = useUpdateSignalGroupItem();
  const { deleteSignalGroupItem } = useDeleteSignalGroupItem();
  const { clearSignalGroupItem } = useClearSignalGroupItem();
  const { updateSignalGroupAction } = useUpdateSignalGroupAction();
  const { deleteSignalGroupAction } = useDeleteSignalGroupAction();
  const {
    visible: visibleDeleteConfirm,
    open: openDeleteConfirm,
    close: closeDeleteConfirm,
  } = useModal();
  const {
    visible: visibleActiveConfirm,
    open: openActiveConfirm,
    close: closeActiveConfirm,
  } = useModal();
  const {
    selectedValue: startSignalId,
    open: openStartSignal,
    close: closeStartSignal,
  } = useSelectedValueModal();
  const {
    selectedValue: stopSignalId,
    open: openStopSignal,
    close: closeStopSignal,
  } = useSelectedValueModal();
  const {
    selectedValue: clearSignalId,
    open: openClearSignal,
    close: closeClearSignal,
  } = useSelectedValueModal();
  const {
    selectedValue: deleteSignalId,
    open: openDeleteSignal,
    close: closeDeleteSignal,
  } = useSelectedValueModal();
  const {
    selectedValue: startActionId,
    open: openStartAction,
    close: closeStartAction,
  } = useSelectedValueModal();
  const {
    selectedValue: stopActionId,
    open: openStopAction,
    close: closeStopAction,
  } = useSelectedValueModal();
  const {
    selectedValue: deleteActionId,
    open: openDeleteAction,
    close: closeDeleteAction,
  } = useSelectedValueModal();

  const openEdit = useCallback(() => {
    if (id) {
      history.push(`/signalGroups/${id}/edit`);
    }
  }, [id]);

  const updateActiveStatus = useCallback(() => {
    updateSignalGroup({ isActive: !isActive });
  }, [isActive]);

  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '1rem' }}>
        <Link to="/signalGroups">Signal Groups</Link>
        <Typography color="textPrimary">{name}</Typography>
      </Breadcrumbs>
      <Card>
        <ViewScreen
          loadingProps={{
            loading,
            variant: 'rect',
            height: 150,
          }}
          error={error}
          header={{
            leftProps: {
              component: (
                <CopyTextButton data={name}>
                  <Typography color="textPrimary">{name}</Typography>
                </CopyTextButton>
              ),
            },
            rightProps: {
              buttons: [
                {
                  startIcon: isActive ? <StopIcon /> : <PlayArrowIcon />,
                  text: isActive ? 'Stop' : 'Start',
                  onClick: openActiveConfirm,
                },
                {
                  startIcon: <DeleteOutlineIcon />,
                  text: 'Delete',
                  onClick: openDeleteConfirm,
                },
                {
                  startIcon: <EditOutlinedIcon />,
                  text: 'Edit',
                  onClick: openEdit,
                },
              ],
            },
            dividerProps: {
              hide: false,
            },
          }}
        >
          <CardContent>
            <LineItems
              description="Status"
              value={<SignalGroupStatusPill status={displayStatus} />}
            />
            {reactivateAt ? (
              <LineItems
                description="Signal group resumes"
                value={formatDateTime(reactivateAt)}
              />
            ) : null}
            {stopAfterTrigger ? (
              <LineItems
                description="Post Trigger option"
                value={
                  <PostTriggerOption
                    stopAfterTrigger={stopAfterTrigger}
                    cooldownAmount={cooldownAmount}
                    cooldownInterval={cooldownInterval}
                  />
                }
              />
            ) : null}
            <LineItems
              description="Runtime"
              value={formatDateTime(runningAt)}
            />
            <LineItems
              description="Created"
              value={formatDateTime(createdAt)}
            />
            <ConfirmAlert
              isOpen={visibleActiveConfirm}
              title={isActive ? 'Stop Group' : 'Start Group'}
              description={
                isActive
                  ? 'Stopping group will stop listening to incoming alerts for the signals associated with this group.'
                  : 'Start listening to incoming alerts for the signals associated with this group.'
              }
              agreeText="Yes"
              disagreeText="Cancel"
              onAgree={() => {
                closeActiveConfirm();
                updateActiveStatus();
              }}
              onDiscard={closeActiveConfirm}
            />
            <ConfirmAlert
              isOpen={visibleDeleteConfirm}
              title="Confirm Delete Signal Group"
              description="This action cannot be reverted. This action does not delete associated signals."
              agreeText="Yes"
              disagreeText="Cancel"
              onAgree={() => {
                closeDeleteConfirm();
                deleteSignalGroup();
              }}
              onDiscard={closeDeleteConfirm}
            />
          </CardContent>
        </ViewScreen>
      </Card>
      <Card>
        <ViewScreen
          loadingProps={{
            loading,
            variant: 'rect',
            height: 150,
          }}
          error={error}
          header={{
            leftProps: {
              title: 'Signals',
            },
            dividerProps: {
              hide: false,
            },
            rightProps: {
              buttons: [
                {
                  text: 'Refresh',
                  startIcon: <RefreshOutlinedIcon />,
                  onClick: () => refetch(),
                  disabled: loading,
                },
              ],
            },
          }}
        >
          <SimpleTable
            columns={[
              {
                label: '',
                width: 10,
                name: 'state',
                render: ({ row }: any) => {
                  const state = row?.state as SignalGroupItemState;
                  const signalStateIsActive = row?.isActive;
                  if (!signalStateIsActive) {
                    return <StoppedCircle text="Stopped" />;
                  }
                  if (!state) {
                    return <NeutralCircle text="Waiting" />;
                  }
                  if (state === SignalGroupItemState.On) {
                    return <SuccessCircle text="On" />;
                  }
                  if (state === SignalGroupItemState.Off) {
                    return <FailCircle text="Off" />;
                  }
                  return null;
                },
              },
              {
                label: 'Name',
                name: 'signalBySignalId.name',
                render: ({ row }: any) => (
                  <Box display="flex">
                    <Box display="flex" flexDirection="column">
                      <Box>
                        <Typography>{row?.signalBySignalId?.name}</Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Box>
                          <SignalActionPill
                            action={
                              row?.signalHistoryByLastSignalHistoryId?.action
                            }
                          />
                        </Box>
                        <Box
                          display="flex"
                          style={{
                            marginLeft: '0.4rem',
                          }}
                        >
                          <Typography variant="caption">
                            {formatDateTime(
                              row?.signalHistoryByLastSignalHistoryId
                                ?.createdAt,
                              'ddd, MMM DD, YYYY [|] h:mm:ss A',
                            )}
                          </Typography>
                        </Box>
                      </Box>
                      <Box style={{ marginRight: '0.3rem' }}>
                        <Typography
                          variant="caption"
                          style={{ textTransform: 'uppercase' }}
                        >
                          {row?.isPerpetual ? 'Perpetual' : 'Not perpetual'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ),
              },
              {
                label: 'Actions',
                width: 176,
                name: 'signalBySignalId.isActive',
                render: ({ row }: any) => {
                  const signalGroupItemId = row?.id;
                  const signalGroupItemIsActive = row?.isActive;
                  return (
                    <>
                      <LightTooltip
                        title={
                          signalGroupItemIsActive
                            ? 'Stop listening to signal'
                            : 'Start listening to signal'
                        }
                      >
                        <IconButton
                          aria-label={
                            signalGroupItemIsActive ? 'stop' : 'start'
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (signalGroupItemIsActive) {
                              openStopSignal(signalGroupItemId);
                            } else {
                              openStartSignal(signalGroupItemId);
                            }
                          }}
                        >
                          {signalGroupItemIsActive ? (
                            <StopIcon
                              style={{ color: 'rgba(69,90,100,0.7)' }}
                            />
                          ) : (
                            <PlayArrowIcon
                              style={{ color: 'rgba(69,90,100,0.7)' }}
                            />
                          )}
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip title="Reset signal state">
                        <IconButton
                          aria-label="clear"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openClearSignal(signalGroupItemId);
                          }}
                        >
                          <ClearAllIcon
                            style={{ color: 'rgba(69,90,100,0.7)' }}
                          />
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip title="Remove signal">
                        <IconButton
                          aria-label="delete"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openDeleteSignal(signalGroupItemId);
                          }}
                        >
                          <CloseIcon style={{ color: 'rgba(69,90,100,0.7)' }} />
                        </IconButton>
                      </LightTooltip>
                    </>
                  );
                },
              },
            ]}
            rows={items}
            onRowClick={(row) =>
              history.push(`/signals/${row.signalBySignalId.id}`)
            }
          />
        </ViewScreen>
      </Card>
      <Card>
        <ViewScreen
          loadingProps={{
            loading,
            variant: 'rect',
            height: 150,
          }}
          error={error}
          header={{
            leftProps: {
              title: 'Triggers',
            },
            dividerProps: {
              hide: false,
            },
          }}
        >
          <SimpleTable
            columns={[
              {
                label: 'Action/Message',
                name: 'payload',
              },
              {
                label: 'Trigger Count',
                width: 135,
                name: 'triggerCount',
              },
              {
                label: 'Actions',
                width: 176,
                name: 'isActive',
                render: ({ row }: any) => {
                  const actionId = row?.id;
                  const actionIsActive = row?.isActive;
                  return (
                    <>
                      <LightTooltip
                        title={
                          actionIsActive
                            ? 'Stop action from triggering'
                            : 'Start action to trigger'
                        }
                      >
                        <IconButton
                          aria-label={actionIsActive ? 'stop' : 'start'}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (actionIsActive) {
                              openStopAction(actionId);
                            } else {
                              openStartAction(actionId);
                            }
                          }}
                        >
                          {actionIsActive ? (
                            <StopIcon
                              style={{ color: 'rgba(69,90,100,0.7)' }}
                            />
                          ) : (
                            <PlayArrowIcon
                              style={{ color: 'rgba(69,90,100,0.7)' }}
                            />
                          )}
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip title="Delete action">
                        <IconButton
                          aria-label="delete"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openDeleteAction(actionId);
                          }}
                        >
                          <CloseIcon style={{ color: 'rgba(69,90,100,0.7)' }} />
                        </IconButton>
                      </LightTooltip>
                    </>
                  );
                },
              },
            ]}
            rows={actions}
          />
        </ViewScreen>
      </Card>
      <Card>
        <ViewScreen
          loadingProps={{
            loading,
            variant: 'rect',
            height: 150,
          }}
          error={error}
          header={{
            leftProps: {
              title: 'Trigger History',
            },
            dividerProps: {
              hide: false,
            },
          }}
        >
          <Table
            columns={instanceHistoryColumns}
            condition={instanceHistoryCondition}
            query={AllSignalGroupInstancesDocument}
            onRowClick={(row) =>
              history.push(`/signalGroups/${id}/instances/${row.id}`)
            }
          />
        </ViewScreen>
      </Card>
      <ConfirmAlert
        isOpen={!!clearSignalId}
        title="Reset Signal State"
        description="Are you sure you want to reset signal state?"
        agreeText="Yes"
        disagreeText="Cancel"
        onAgree={() => {
          closeClearSignal();
          clearSignalGroupItem({
            id: clearSignalId,
          });
        }}
        onDiscard={closeClearSignal}
      />
      <ConfirmAlert
        isOpen={!!deleteSignalId}
        title="Delete Signal"
        description="Are you sure you want to delete this signal?"
        agreeText="Yes"
        disagreeText="Cancel"
        onAgree={() => {
          closeDeleteSignal();
          deleteSignalGroupItem({
            id: deleteSignalId,
          });
        }}
        onDiscard={closeDeleteSignal}
      />
      <ConfirmAlert
        isOpen={!!startSignalId}
        title="Start Signal"
        description="Are you sure you want to start this signal?"
        agreeText="Yes"
        disagreeText="Cancel"
        onAgree={() => {
          closeStartSignal();
          // eslint-disable-next-line
          updateSignalGroupItem(startSignalId!, {
            isActive: true,
          });
        }}
        onDiscard={closeStartSignal}
      />
      <ConfirmAlert
        isOpen={!!stopSignalId}
        title="Stop Signal"
        description="Are you sure you want to stop this signal?"
        agreeText="Yes"
        disagreeText="Cancel"
        onAgree={() => {
          closeStopSignal();
          // eslint-disable-next-line
          updateSignalGroupItem(stopSignalId!, {
            isActive: false,
          });
        }}
        onDiscard={closeStopSignal}
      />
      <ConfirmAlert
        isOpen={!!startActionId}
        title="Start Action"
        description="Are you sure you want to start this action?"
        agreeText="Yes"
        disagreeText="Cancel"
        onAgree={() => {
          closeStartAction();
          // eslint-disable-next-line
          updateSignalGroupAction(startActionId!, {
            isActive: true,
          });
        }}
        onDiscard={closeStartAction}
      />
      <ConfirmAlert
        isOpen={!!stopActionId}
        title="Stop Action"
        description="Are you sure you want to stop this action?"
        agreeText="Yes"
        disagreeText="Cancel"
        onAgree={() => {
          closeStopAction();
          // eslint-disable-next-line
          updateSignalGroupAction(stopActionId!, {
            isActive: false,
          });
        }}
        onDiscard={closeStopAction}
      />
      <ConfirmAlert
        isOpen={!!deleteActionId}
        title="Delete Action"
        description="Are you sure you want to delete this action?"
        agreeText="Yes"
        disagreeText="Cancel"
        onAgree={() => {
          closeDeleteAction();
          deleteSignalGroupAction({
            id: deleteActionId,
          });
        }}
        onDiscard={closeDeleteAction}
      />
    </Box>
  );
};

export default SignalGroupViewScreen;
