import ListScreen from 'components/ListScreen';
import SignalActionPill from 'components/SignalActionPill';
import { AllIncomingSignalsDocument } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDateTime } from 'utils/time';

const columns = [
  {
    label: 'Signal',
    name: 'signalBySignalId.name',
    disableFilter: true,
  },
  {
    label: 'Message',
    name: 'message',
    disableFilter: true,
  },
  {
    label: 'Action',
    name: 'action',
    disableFilter: true,
    width: 50,
    render: ({ value }: any) => <SignalActionPill action={value} />,
  },
  {
    label: 'Time',
    name: 'createdAt',
    disableFilter: true,
    width: 250,
    render: ({ value }: any) => formatDateTime(value),
  },
];

const IncomingSignalListScreen: FunctionComponent = () => {
  const history = useHistory();

  return (
    <ListScreen
      title="Incoming Signals"
      query={AllIncomingSignalsDocument}
      onRowClick={(row) => history.push(`/signals/${row.signalBySignalId.id}`)}
      columns={columns}
    />
  );
};

export default IncomingSignalListScreen;
