import {
  Box,
  Breadcrumbs,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  RadioGroup,
  Select as MUISelect,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import BrandCheckbox from 'components/BrandCheckbox';
import BrandRadio from 'components/BrandRadio';
import Button from 'components/Button';
import Card from 'components/Card';
import CardHorizontalLine from 'components/CardHorizontalLine';
import Autocomplete from 'components/Forms/Autocomplete';
import SelectField from 'components/Forms/SelectField';
import TextField from 'components/Forms/TextField';
import WhenFieldChanges from 'components/Forms/WhenFieldChanges';
import FormWrapper from 'components/FormWrapper';
import LightTooltip from 'components/LightTooltip';
import Link from 'components/Link';
import arrayMutators from 'final-form-arrays';
import {
  CooldownAfterTriggerInterval,
  GetSignalsDocument,
  SignalGroupItemFirstToggleActionValue,
  useSignalGroupByIdLazyQuery,
  SignalGroupItemCooldownAfterReceivedSignalInterval,
} from 'graphql/graphql-types';
import { makeValidate } from 'mui-rff';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { mapEnumToArray, mapEnumToSelectOptions } from 'utils/graphql';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const cooldownAfterTriggerValues = mapEnumToArray(CooldownAfterTriggerInterval);
const cooldownAfterTriggerOptions = mapEnumToSelectOptions(
  CooldownAfterTriggerInterval,
  true,
  true,
);

const firstToggleActionValues = mapEnumToArray(
  SignalGroupItemFirstToggleActionValue,
);
const firstToggleActionOptions = mapEnumToSelectOptions(
  SignalGroupItemFirstToggleActionValue,
  false,
  false,
);

const cooldownAfterReceivedSignalIntervalValues = mapEnumToArray(
  SignalGroupItemCooldownAfterReceivedSignalInterval,
);
const cooldownAfterReceivedSignalIntervalOptions = mapEnumToSelectOptions(
  SignalGroupItemCooldownAfterReceivedSignalInterval,
  true,
  true,
);

const Schema = Yup.object({
  name: Yup.string().required('Required'),
  afterTrigger: Yup.string()
    .oneOf(['stop', 'cooldown', 'nothing'])
    .required('Required'),
  cooldownAfterTriggerAmount: Yup.number().when('afterTrigger', {
    is: (value: any) => value === 'cooldown',
    then: Yup.number().positive('Must be positive number').required('Required'),
    otherwise: Yup.number().positive('Must positive number').notRequired(),
  }),
  cooldownAfterTriggerInterval: Yup.string().when('afterTrigger', {
    is: (value: any) => value === 'cooldown',
    then: Yup.string().oneOf(cooldownAfterTriggerValues).required('Required'),
    otherwise: Yup.string().oneOf(cooldownAfterTriggerValues).notRequired(),
  }),
  signals: Yup.array()
    .ensure()
    .of(
      Yup.object({
        id: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        isPerpetual: Yup.boolean().required('Required'),
        firstToggleActionValue: Yup.string().oneOf(firstToggleActionValues),
        cooldownAfterReceivedSettings: Yup.boolean(),
        cooldownAfterReceivedSignalAmount: Yup.number().when(
          'cooldownAfterReceivedSettings',
          {
            is: (value: any) => !!value,
            then: Yup.number()
              .positive('Must be positive number')
              .required('Required'),
            otherwise: Yup.number()
              .positive('Must positive number')
              .notRequired(),
          },
        ),
        cooldownAfterReceivedSignalInterval: Yup.string().when(
          'cooldownAfterReceivedSettings',
          {
            is: (value: any) => !!value,
            then: Yup.string()
              .oneOf(cooldownAfterReceivedSignalIntervalValues)
              .required('Required'),
            otherwise: Yup.string()
              .oneOf(cooldownAfterReceivedSignalIntervalValues)
              .notRequired(),
          },
        ),
        resetCooldownAfterGroupTrigger: Yup.boolean().when(
          'cooldownAfterReceivedSettings',
          {
            is: (value: any) => !!value,
            then: Yup.boolean().required('Required'),
            otherwise: Yup.boolean().notRequired(),
          },
        ),
      }),
    )
    .required('Required'),
  deleteSignals: Yup.array().of(Yup.string().required()),
  actions: Yup.array()
    .ensure()
    .of(
      Yup.object({
        id: Yup.string().optional(),
        payload: Yup.string().required('Required'),
      }),
    )
    .required('Required'),
  deleteActions: Yup.array().of(Yup.string().required()),
});

type DataSchema = Asserts<typeof Schema>;

const validate = makeValidate<DataSchema>(Schema);

type Props = {
  type: 'create' | 'update';
  signalGroupId?: string;
  onSubmit: (data: DataSchema) => void;
};
const SignalGroupForm: FunctionComponent<Props> = ({
  type,
  signalGroupId,
  onSubmit,
}) => {
  const [ready, setReady] = useState(false);
  const [initialValues, setInitialValues] = useState<DataSchema>();
  const [get, { data }] = useSignalGroupByIdLazyQuery();

  useEffect(() => {
    if (type === 'create') {
      setInitialValues({
        name: '',
        signals: [],
        deleteSignals: [],
        actions: [],
        deleteActions: [],
        afterTrigger: 'nothing',
        cooldownAfterTriggerAmount: undefined,
        cooldownAfterTriggerInterval: undefined,
      });
      setReady(true);
    }
    if (type === 'update' && signalGroupId) {
      get({
        variables: {
          id: signalGroupId,
        },
      });
    }
  }, [type, signalGroupId]);

  useEffect(() => {
    if (data?.signalGroupById) {
      const signalGroup = data.signalGroupById;
      const cooldownAfterTriggerAmount =
        signalGroup.cooldownAfterTriggerAmount ?? undefined;
      const cooldownAfterTriggerInterval =
        signalGroup.cooldownAfterTriggerInterval ?? undefined;
      let existingAfterTrigger = 'nothing';
      if (signalGroup.stopAfterTrigger) {
        existingAfterTrigger = 'stop';
      } else if (!!cooldownAfterTriggerAmount && cooldownAfterTriggerInterval) {
        existingAfterTrigger = 'cooldown';
      }

      setInitialValues({
        name: signalGroup.name,
        signals: signalGroup.signalGroupItemsBySignalGroupId?.nodes?.map(
          (i) => ({
            id: String(i.signalBySignalId?.id),
            deleteId: String(i.id),
            name: String(i.signalBySignalId?.name),
            isPerpetual: i.isPerpetual,
            firstToggleActionValue: i.firstToggleActionValue ?? undefined,
            cooldownAfterReceivedSettings: !!(
              i?.cooldownAfterReceivedSignalAmount &&
              i?.cooldownAfterReceivedSignalInterval
            ),
            cooldownAfterReceivedSignalAmount:
              i?.cooldownAfterReceivedSignalAmount ?? undefined,
            cooldownAfterReceivedSignalInterval:
              i?.cooldownAfterReceivedSignalInterval ?? undefined,
            resetCooldownAfterGroupTrigger:
              i?.resetCooldownAfterGroupTrigger ?? undefined,
          }),
        ),
        deleteSignals: [],
        actions: signalGroup.signalGroupActionsBySignalGroupId?.nodes?.map(
          (a) => ({
            id: a.id,
            payload: String(a.payload),
          }),
        ),
        deleteActions: [],
        afterTrigger: existingAfterTrigger,
        cooldownAfterTriggerAmount,
        cooldownAfterTriggerInterval,
      });
      setReady(true);
    }
  }, [data]);

  return (
    <FormWrapper ready={ready}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          form: {
            mutators: { push, update },
          },
          ...props
        }) => (
          <form>
            <Breadcrumbs
              aria-label="breadcrumb"
              style={{ marginBottom: '1rem' }}
            >
              <Link to="/signalGroups">Signal Groups</Link>
              <Typography color="textPrimary">
                {type === 'update' ? props.values.name : 'New Signal Group'}
              </Typography>
            </Breadcrumbs>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8}>
                <Grid spacing={3}>
                  <Grid item xs={12} style={{ minWidth: '42rem' }}>
                    <Card>
                      <CardHeader title="Signal Group Name" />
                      <CardHorizontalLine />
                      <CardContent>
                        <TextField
                          required
                          variant="outlined"
                          label="Name"
                          id="name"
                          name="name"
                          size="small"
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} style={{ minWidth: '42rem' }}>
                    <Card>
                      <CardHeader
                        title="Incoming Signals"
                        subheader="Select from created signals."
                      />
                      <CardHorizontalLine />
                      <Box>
                        <FieldArray name="signals">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <Box
                                display="flex"
                                flexDirection="row"
                                style={{
                                  background: 'rgba(247, 250, 252, 0.5)',
                                  borderColor: 'rgb(196 196 196)',
                                  borderStyle: 'solid',
                                  borderWidth: '0 0 1px 0',
                                  padding: '0.3rem 1rem 0.3rem 1rem',
                                  marginBottom: '-1px',
                                }}
                              >
                                <Box
                                  display="flex"
                                  flex="1"
                                  flexDirection="row"
                                  alignItems="center"
                                  key={fields.value[index]?.id}
                                >
                                  <Box flexGrow={1}>
                                    <Box flexGrow={1}>
                                      <Autocomplete
                                        label=""
                                        name="signals"
                                        required
                                        clearOnBlur
                                        disableClearable
                                        defaultValue={fields.value[index]}
                                        query={GetSignalsDocument}
                                        onChange={(e, value) => {
                                          if (value) {
                                            update('signals', index, {
                                              id: value.id,
                                              name: value.name,
                                              isPerpetual:
                                                fields.value[index]
                                                  ?.isPerpetual ?? false,
                                              firstToggleActionValue:
                                                fields.value[index]
                                                  ?.firstToggleActionValue ??
                                                undefined,
                                            });
                                            const deleteId =
                                              fields.value[index]?.deleteId;
                                            if (type === 'update' && deleteId) {
                                              push('deleteSignals', deleteId);
                                            }
                                          }
                                        }}
                                        getOptionValue={(option: any) => ({
                                          id: option.id,
                                          name: option.name,
                                        })}
                                        getOptionLabel={(option: any) =>
                                          option?.name
                                        }
                                        getOptionSelected={(
                                          option: any,
                                          value: any,
                                        ) => option?.id === value?.id}
                                        textFieldProps={{
                                          variant: 'outlined',
                                          size: 'small',
                                        }}
                                      />
                                    </Box>
                                    <br />
                                    <Box>
                                      <Field
                                        name={`signals.${index}.isPerpetual`}
                                        type="checkbox"
                                      >
                                        {(fieldProps) => (
                                          <FormControlLabel
                                            label={
                                              <LightTooltip
                                                arrow
                                                title="Signal state doesn't reset on signal group trigger"
                                              >
                                                <Typography>
                                                  Perpetual signal&nbsp;
                                                  <InfoIcon
                                                    style={{
                                                      fontSize: '1rem',
                                                      verticalAlign: 'baseline',
                                                    }}
                                                  />
                                                </Typography>
                                              </LightTooltip>
                                            }
                                            control={
                                              <BrandCheckbox
                                                checked={
                                                  fieldProps.input.checked
                                                }
                                                value={fieldProps.input.value}
                                                name={fieldProps.input.name}
                                                onChange={
                                                  fieldProps.input.onChange
                                                }
                                              />
                                            }
                                          />
                                        )}
                                      </Field>
                                    </Box>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="flex-start"
                                    >
                                      <Field
                                        name={`signals.${index}.cooldownAfterReceivedSettings`}
                                        type="checkbox"
                                      >
                                        {(fieldProps) => (
                                          <FormControlLabel
                                            label={
                                              <LightTooltip
                                                arrow
                                                title="Specify amount of time that must pass before this signal can be triggered again."
                                              >
                                                <Typography>
                                                  Start cooldown timer&nbsp;
                                                  <InfoIcon
                                                    style={{
                                                      fontSize: '1rem',
                                                      verticalAlign: 'baseline',
                                                    }}
                                                  />
                                                </Typography>
                                              </LightTooltip>
                                            }
                                            style={{
                                              marginTop: '0.7rem',
                                              minWidth: '14rem',
                                            }}
                                            control={
                                              <BrandCheckbox
                                                checked={
                                                  fieldProps.input.checked
                                                }
                                                value={fieldProps.input.value}
                                                name={fieldProps.input.name}
                                                onChange={
                                                  fieldProps.input.onChange
                                                }
                                              />
                                            }
                                          />
                                        )}
                                      </Field>
                                      <TextField
                                        required
                                        disabled={
                                          !props.values.signals?.[index]
                                            ?.cooldownAfterReceivedSettings
                                        }
                                        variant="outlined"
                                        label="Number"
                                        name={`signals.${index}.cooldownAfterReceivedSignalAmount`}
                                        size="small"
                                        parseType="integer"
                                        style={{
                                          marginRight: '1rem',
                                          marginTop: '0.9rem',
                                          minWidth: '10rem',
                                          maxWidth: '10rem',
                                        }}
                                      />
                                      <SelectField
                                        disabled={
                                          !props.values.signals?.[index]
                                            ?.cooldownAfterReceivedSettings
                                        }
                                        items={
                                          cooldownAfterReceivedSignalIntervalOptions
                                        }
                                        name={`signals.${index}.cooldownAfterReceivedSignalInterval`}
                                        variant="outlined"
                                        style={{
                                          position: 'relative',
                                          top: '-0.1rem',
                                          height: '2.5rem',
                                          minWidth: '10rem',
                                          maxWidth: '10rem',
                                        }}
                                      />
                                      <WhenFieldChanges
                                        field={`signals.${index}.cooldownAfterReceivedSettings`}
                                        becomes={(value) => !value}
                                        set={`signals.${index}.cooldownAfterReceivedSignalAmount`}
                                        to={undefined}
                                      />
                                      <WhenFieldChanges
                                        field={`signals.${index}.cooldownAfterReceivedSettings`}
                                        becomes={(value) => !value}
                                        set={`signals.${index}.cooldownAfterReceivedSignalInterval`}
                                        to={undefined}
                                      />
                                      <WhenFieldChanges
                                        field={`signals.${index}.cooldownAfterReceivedSettings`}
                                        becomes={(value) => !value}
                                        set={`signals.${index}.resetCooldownAfterGroupTrigger`}
                                        to={undefined}
                                      />
                                      <WhenFieldChanges
                                        field={`signals.${index}.cooldownAfterReceivedSettings`}
                                        becomes={(value) => !!value}
                                        set={`signals.${index}.resetCooldownAfterGroupTrigger`}
                                        to
                                      />
                                    </Box>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      style={{ marginLeft: '2rem' }}
                                    >
                                      <Field
                                        name={`signals.${index}.resetCooldownAfterGroupTrigger`}
                                        type="checkbox"
                                      >
                                        {(fieldProps) => (
                                          <FormControlLabel
                                            label="Reset timer after group triggers"
                                            control={
                                              <BrandCheckbox
                                                required
                                                disabled={
                                                  !props.values.signals?.[index]
                                                    ?.cooldownAfterReceivedSettings
                                                }
                                                checked={
                                                  fieldProps.input.checked
                                                }
                                                value={fieldProps.input.value}
                                                name={fieldProps.input.name}
                                                onChange={
                                                  fieldProps.input.onChange
                                                }
                                              />
                                            }
                                          />
                                        )}
                                      </Field>
                                    </Box>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <Box
                                        flex="1"
                                        style={{ minWidth: '25rem' }}
                                      >
                                        <Typography variant="body1">
                                          If using signal trigger toggle, set
                                          first trigger state to:
                                        </Typography>
                                      </Box>
                                      <SelectField
                                        items={firstToggleActionOptions}
                                        name={`signals.${index}.firstToggleActionValue`}
                                        variant="outlined"
                                        style={{
                                          position: 'relative',
                                          top: '-0.19rem',
                                          width: '8rem',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  style={{
                                    marginLeft: '1rem',
                                    marginTop: '0.5rem',
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      if (type === 'update') {
                                        const deleteId =
                                          fields.value[index]?.deleteId;
                                        if (type === 'update' && deleteId) {
                                          push('deleteSignals', deleteId);
                                        }
                                      }
                                      fields.remove(index);
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            ))
                          }
                        </FieldArray>
                      </Box>
                      <CardContent>
                        <br />
                        <Button
                          startIcon={<AddIcon />}
                          size="large"
                          text="Add Signal"
                          onClick={() => push('signals', undefined)}
                        />
                        <br />
                        <Typography variant="caption">
                          All grouped signals must receive a positive trigger to
                          activate signal group.
                        </Typography>
                        <br />
                        <br />
                        <Typography variant="caption">
                          Once a signal group is triggered, it resets all
                          non-perpetual signals for that group. Signals can
                          immediately flip positive and execute signal group
                          (unless additional signal group options are selected).
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} style={{ minWidth: '42rem' }}>
                    <Card>
                      <CardHeader
                        title="Signal Triggers"
                        subheader="Actions will execute in the order they are added."
                      />
                      <CardHorizontalLine />
                      <Box>
                        <FieldArray name="actions">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <Box
                                display="flex"
                                flexDirection="column"
                                key={fields.value[index]?.id}
                                style={{
                                  background: 'rgba(247, 250, 252, 0.5)',
                                  borderColor: '#e0e0e0',
                                  borderStyle: 'solid',
                                  borderWidth: '0 0 1px 0',
                                  padding: '0.3rem 1rem 0.3rem 1rem',
                                  marginBottom: '-1px',
                                }}
                              >
                                <Box
                                  style={{
                                    padding: '0.3rem 0rem 0rem 0.3rem',
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    key={name}
                                  >
                                    <Box flexGrow="1">
                                      <FormControl
                                        disabled
                                        variant="outlined"
                                        style={{
                                          marginTop: '0',
                                          marginBottom: '0',
                                        }}
                                      >
                                        <MUISelect value="3Commas">
                                          <MenuItem value="3Commas">
                                            <em>3Commas</em>
                                          </MenuItem>
                                        </MUISelect>
                                      </FormControl>
                                    </Box>
                                    <Box>
                                      <IconButton
                                        onClick={() => {
                                          if (type === 'update') {
                                            push(
                                              'deleteActions',
                                              fields.value[index]?.id,
                                            );
                                          }
                                          fields.remove(index);
                                        }}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  style={{
                                    padding: '0rem 0.4rem 0.3rem 0.4rem',
                                  }}
                                >
                                  <TextField
                                    required
                                    variant="outlined"
                                    label="Action/Message"
                                    name={`${name}.payload`}
                                    multiline
                                    rows={4}
                                    rowsMax={4}
                                    size="small"
                                  />
                                </Box>
                              </Box>
                            ))
                          }
                        </FieldArray>
                      </Box>
                      <CardContent>
                        <br />
                        <Button
                          startIcon={<AddIcon />}
                          text="Add trigger"
                          size="large"
                          onClick={() => push('actions', undefined)}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} style={{ minWidth: '42rem' }}>
                    <Card>
                      <CardHeader
                        title="Post Trigger Options"
                        subheader="Settings after signal group is triggered."
                      />
                      <CardHorizontalLine />
                      <CardContent>
                        <Field name="afterTrigger" type="radio">
                          {(fieldProps) => (
                            <RadioGroup
                              name={fieldProps.input.name}
                              value={fieldProps.input.value}
                              onChange={fieldProps.input.onChange}
                            >
                              <FormControlLabel
                                value="nothing"
                                label="Start signal group again"
                                control={
                                  <Field
                                    name={fieldProps.input.name}
                                    type="radio"
                                    render={({
                                      input: {
                                        name,
                                        value,
                                        onChange,
                                        checked,
                                        ...restInput
                                      },
                                    }) => (
                                      <BrandRadio
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        checked={checked}
                                        // disabled={item.disabled}
                                        required
                                        inputProps={{ ...restInput }}
                                      />
                                    )}
                                  />
                                }
                              />
                              <FormControlLabel
                                value="stop"
                                label="Stop this signal group after it triggers once"
                                control={
                                  <Field
                                    name={fieldProps.input.name}
                                    type="radio"
                                    render={({
                                      input: {
                                        name,
                                        value,
                                        onChange,
                                        checked,
                                        ...restInput
                                      },
                                    }) => (
                                      <BrandRadio
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        checked={checked}
                                        // disabled={item.disabled}
                                        required
                                        inputProps={{ ...restInput }}
                                      />
                                    )}
                                  />
                                }
                              />
                              <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="flex-start"
                              >
                                <FormControlLabel
                                  value="cooldown"
                                  style={{
                                    minWidth: '14rem',
                                  }}
                                  label={
                                    <LightTooltip
                                      arrow
                                      title="Specify amount of time that must pass before this signal group can be triggered again."
                                    >
                                      <Typography>
                                        Start cooldown timer after signal group
                                        triggers&nbsp;
                                        <InfoIcon
                                          style={{
                                            fontSize: '1rem',
                                            verticalAlign: 'baseline',
                                          }}
                                        />
                                      </Typography>
                                    </LightTooltip>
                                  }
                                  control={
                                    <Field
                                      name={fieldProps.input.name}
                                      type="radio"
                                      render={({
                                        input: {
                                          name,
                                          value,
                                          onChange,
                                          checked,
                                          ...restInput
                                        },
                                      }) => (
                                        <BrandRadio
                                          name={name}
                                          value={value}
                                          onChange={onChange}
                                          checked={checked}
                                          required
                                          inputProps={{ ...restInput }}
                                        />
                                      )}
                                      style={{
                                        marginRight: '1rem',
                                        marginTop: '0.9rem',
                                        minWidth: '10rem',
                                        maxWidth: '10rem',
                                      }}
                                    />
                                  }
                                />
                                <Box display="flex" flexDirection="row">
                                  <TextField
                                    required
                                    disabled={
                                      props.values.afterTrigger !== 'cooldown'
                                    }
                                    variant="outlined"
                                    label="Number"
                                    name="cooldownAfterTriggerAmount"
                                    size="small"
                                    parseType="integer"
                                    style={{
                                      marginRight: '1rem',
                                      marginTop: '0.2rem',
                                      minWidth: '10rem',
                                      maxWidth: '10rem',
                                    }}
                                  />
                                  <SelectField
                                    disabled={
                                      props.values.afterTrigger !== 'cooldown'
                                    }
                                    items={cooldownAfterTriggerOptions}
                                    name="cooldownAfterTriggerInterval"
                                    variant="outlined"
                                    style={{
                                      position: 'relative',
                                      top: '-0.8rem',
                                      height: '2.5rem',
                                      minWidth: '10rem',
                                      maxWidth: '10rem',
                                    }}
                                  />
                                  <WhenFieldChanges
                                    field="afterTrigger"
                                    becomes={(value) => value !== 'cooldown'}
                                    set="cooldownAfterTriggerInterval"
                                    to={undefined}
                                  />
                                  <WhenFieldChanges
                                    field="afterTrigger"
                                    becomes={(value) => value !== 'cooldown'}
                                    set="cooldownAfterTriggerAmount"
                                    to={undefined}
                                  />
                                </Box>
                              </Box>
                            </RadioGroup>
                          )}
                        </Field>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      text={type === 'create' ? 'Create' : 'Save'}
                      disabled={
                        props.submitting ||
                        props.pristine ||
                        props.hasValidationErrors ||
                        (props.hasSubmitErrors &&
                          !props.modifiedSinceLastSubmit)
                      }
                      onClick={props.handleSubmit}
                      type="submit"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </FormWrapper>
  );
};

export default SignalGroupForm;
