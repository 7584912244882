import Skeleton, { SkeletonProps } from '@material-ui/lab/Skeleton';
import React, { FunctionComponent } from 'react';

type Props = {
  loading: boolean;
} & SkeletonProps;
const SkeletonWrapper: FunctionComponent<Props> = ({
  loading,
  children,
  ...props
}) => (loading ? <Skeleton {...props} /> : <>{children}</>);

export default SkeletonWrapper;
