import {
  CreateSignalInput,
  StartSignalByIdInput,
  StopSignalByIdInput,
  UpdateSignalByIdInput,
  useCreateSignalMutation,
  useDeleteSignalByIdMutation,
  useStartSignalByIdMutation,
  useStopSignalByIdMutation,
  useUpdateSignalByIdMutation,
} from 'graphql/graphql-types';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { logError } from 'utils/logging';

export const useCreateSignal = () => {
  const history = useHistory();
  const [commit] = useCreateSignalMutation();

  const createSignal = useCallback((data: CreateSignalInput) => {
    const execute = async () => {
      try {
        const result = await commit({
          variables: {
            input: data,
          },
        });
        history.push(`/signals/${result.data?.createSignal?.signal?.id}`);
      } catch (e) {
        logError(e);
      }
    };
    execute();
  }, []);

  return { createSignal };
};

export const useUpdateSignal = () => {
  const history = useHistory();
  const [commit] = useUpdateSignalByIdMutation();

  const updateSignal = useCallback(async (data: UpdateSignalByIdInput) => {
    try {
      await commit({
        variables: {
          input: data,
        },
      });
      history.push(`/signals/${data.id}`);
    } catch (e) {
      logError(e);
    }
  }, []);

  return { updateSignal };
};

export const useDeleteSignal = (signalId: string) => {
  const history = useHistory();
  const [commit] = useDeleteSignalByIdMutation();

  const deleteSignal = useCallback(() => {
    const execute = async () => {
      try {
        await commit({
          variables: {
            input: {
              id: signalId,
            },
          },
        });
        history.push('/signals');
      } catch (e) {
        logError(e);
      }
    };
    if (signalId) {
      execute();
    }
  }, [signalId]);

  return { deleteSignal };
};

export const useStartSignal = () => {
  const [commit] = useStartSignalByIdMutation();

  const startSignal = useCallback((data: StartSignalByIdInput) => {
    const execute = async () => {
      try {
        await commit({
          variables: {
            input: data,
          },
        });
      } catch (e) {
        logError(e);
      }
    };
    execute();
  }, []);

  return { startSignal };
};

export const useStopSignal = () => {
  const [commit] = useStopSignalByIdMutation();

  const stopSignal = useCallback((data: StopSignalByIdInput) => {
    const execute = async () => {
      try {
        await commit({
          variables: {
            input: data,
          },
        });
      } catch (e) {
        logError(e);
      }
    };
    execute();
  }, []);

  return { stopSignal };
};
