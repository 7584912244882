import React, { FunctionComponent, ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type Props = {
  isOpen: boolean;
  onAgree: () => void;
  onDiscard: () => void;
  title: string;
  description: string;
  content?: ReactNode;
  agreeText?: string;
  disagreeText?: string;
};

const ConfirmAlert: FunctionComponent<Props> = ({
  title,
  description,
  isOpen,
  onAgree,
  onDiscard,
  content,
  agreeText = 'Agree',
  disagreeText = 'Disagree',
}) => (
  <Dialog
    open={isOpen}
    onClose={onDiscard}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      {description && (
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      )}
      {content}
    </DialogContent>
    <DialogActions>
      <Button onClick={onDiscard} color="default">
        {disagreeText}
      </Button>
      <Button onClick={onAgree} color="primary" autoFocus>
        {agreeText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmAlert;
