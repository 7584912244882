import AddIcon from '@material-ui/icons/Add';
import Button from 'components/Button';
import ListScreen from 'components/ListScreen';
import SignalGroupStatusPill from 'components/SignalGroupStatusPill';
import {
  AllSignalGroupsDocument,
  AllSignalGroupsEntryFragment,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDateTime } from 'utils/time';

type Row = {
  row: AllSignalGroupsEntryFragment;
};

const TableActions = ({ createOnClick }: { createOnClick: () => void }) => (
  <Button
    text="New Signal Group"
    startIcon={<AddIcon />}
    onClick={createOnClick}
  />
);

const SignalGroupListScreen: FunctionComponent = () => {
  const history = useHistory();

  return (
    <ListScreen
      title="Signal Groups"
      query={AllSignalGroupsDocument}
      headerExtra={
        <TableActions
          createOnClick={() => history.push('/signalGroups/create')}
        />
      }
      onRowClick={(row: Row['row']) => history.push(`/signalGroups/${row.id}`)}
      columns={[
        {
          label: 'Name',
          name: 'name',
          disableFilter: true,
        },
        {
          label: 'Signals',
          width: 88,
          type: 'integer',
          name: 'signalGroupItemsBySignalGroupId.totalCount',
          disableFilter: true,
        },
        {
          label: 'Trigger Count',
          width: 152,
          name: 'signalGroupTriggerHistoriesBySignalGroupId.totalCount',
          disableFilter: true,
        },
        {
          label: 'Status',
          name: 'displayStatus',
          width: 150,
          render: ({ value }: any) => <SignalGroupStatusPill status={value} />,
          disableFilter: true,
        },
        {
          label: 'Last Triggered',
          width: 250,
          name: 'lastTrigger.endAt',
          disableFilter: true,
          render: ({ value }: any) => formatDateTime(value),
        },
        {
          label: 'Created',
          width: 250,
          name: 'createdAt',
          disableFilter: true,
          render: ({ value }: any) => formatDateTime(value),
        },
      ]}
    />
  );
};

export default SignalGroupListScreen;
